export enum FEATURES_TYPES {
  bubble = 'bubble',
  map = 'map',
  filter = 'filter',
}

export const FEATURES = {
  [FEATURES_TYPES.bubble]: 'Scaling of the “Bubble Chart”',
  [FEATURES_TYPES.map]: '“Map” chart control panel',
  [FEATURES_TYPES.filter]: 'Custom input management in filter settings',
} as const;

export const TEXT_BY_FEATURES = {
  [FEATURES_TYPES.bubble]:
    'You can now select the scale of the chart on the X and Y axes. An option has also been added for automatic resizing of elements (bubbles) based on the selected scale, making your charts more informative and visually appealing',
  [FEATURES_TYPES.map]:
    'The system now automatically adjusts the scale of the map to ensure that labels from all layers fit within the visible area. An auto-scaling icon has been added to the scale control panel: simply click on it, and the chart will return to the optimal scale if you have manually changed it',
  [FEATURES_TYPES.filter]:
    'The ability to enable/disable the checkbox “Allow custom input” has been added in the filter settings for the “Value” type. When this checkbox is activated, users can enter custom values in the filter field instead of only selecting from the suggested list',
} as const;

export const NEXT_VERSION_RELEASE = ['5.1.0', '5.0.0', '5.2.0'];
