import React, { FC, memo } from 'react';
import { Carousel as CarouselAntd } from 'antd';
import './styles.less';

export const Carousel: FC = memo(props => {
  const { children } = props;

  return (
    <CarouselAntd className="carousel-dots" autoplay dotPosition="right">
      {children}
    </CarouselAntd>
  );
});
