// TODO: Заменить импорт i18next, при смене локали не работает
import { t } from 'i18next';
import { TableColumnsType } from 'antd';
import { ITableColumn } from './types';
import { alphabeticalSort } from '../../../../components/Table/sorters';
import { DatasetObject } from '../types';

export const SELECT_MESSAGE = t(
  'Datasets can be created from database tables or SQL queries. Select a database table to the left or ',
);
export const CREATE_MESSAGE = t('create dataset from SQL query');
export const VIEW_DATASET_MESSAGE = t(
  ' to open SQL Lab. From there you can save the query as a dataset.',
);

export const SELECT_TABLE_TITLE = t('Select dataset source');
export const NO_COLUMNS_TITLE = t('No table columns');
export const NO_COLUMNS_DESCRIPTION = t(
  'This database table does not contain any data. Please select a different table.',
);
export const ERROR_TITLE = t('An Error Occurred');
export const ERROR_DESCRIPTION = t(
  'Unable to load columns for the selected table. Please select a different table.',
);

/** Опции пагинации для таблицы датасетов */
export const pageSizeOptions = ['5', '10', '15', '25'];

/** Столбцы для таблицы датасетов */
export const tableColumnDefinition: TableColumnsType<ITableColumn> = [
  {
    title: 'Column Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: ITableColumn, b: ITableColumn) =>
      alphabeticalSort('name', a, b),
  },
  {
    title: 'Datatype',
    dataIndex: 'type',
    key: 'type',
    width: '100px',
    sorter: (a: ITableColumn, b: ITableColumn) =>
      alphabeticalSort('type', a, b),
  },
];

/** Тестовые колонки */
export const exampleColumns: ITableColumn[] = [
  {
    name: 'name',
    type: 'STRING',
  },
  {
    name: 'height_in_inches',
    type: 'NUMBER',
  },
  {
    name: 'birth_date',
    type: 'DATE',
  },
];

/** Список тестовых датасетов */
export const exampleDataset: DatasetObject[] = [
  {
    db: {
      id: 1,
      database_name: 'test_database',
      owners: [1],
      backend: 'test_backend',
    },
    schema: 'test_schema',
    dataset_name: 'example_dataset',
    table_name: 'example_table',
  },
];
