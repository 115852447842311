import { Engines } from '../types';

export const DEFAULT_EXTRA = JSON.stringify({
  allows_virtual_table_explore: true,
});

export const engineSpecificAlertMapping = {
  [Engines.GSheet]: {
    message: 'Why do I need to create a database?',
    description:
      'To begin using your Google Sheets, you need to create a database first. ' +
      'Databases are used as a way to identify ' +
      'your data so that it can be queried and visualized. This ' +
      'database will hold all of your individual Google Sheets ' +
      'you choose to connect here.',
  },
};

export const DEFAULT_TAB_KEY = '1';
