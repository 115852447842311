import React, { FC, memo } from 'react';
import cn from 'classnames';
import { Input as AntdInput } from 'antd';
import { TextAreaProps } from './interfaces';
import './styles.less';

/** Компонент ввода */
export const TextArea: FC<TextAreaProps> = memo(props => {
  const {
    className,
    onChange,
    placeholder,
    value,
    prefix,
    size,
    status,
    validationMethods,
    name,
    dataTestId,
    ...rest
  } = props;

  return (
    <AntdInput.TextArea
      {...rest}
      {...validationMethods}
      className={cn('bi-textarea', className)}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      prefix={prefix}
      size={size}
      status={status}
      name={name}
      data-test={dataTestId}
    />
  );
});
