import { StepsProps as AntdStepsProps } from 'antd';
import { ReactNode } from 'react';

type Item = {
  description: string;
  title: ReactNode;
  status?: Status;
};

export enum Status {
  process = 'process',
  wait = 'wait',
}

export interface StepsProps extends AntdStepsProps {
  items: Item[];
}
