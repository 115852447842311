import React, { useEffect, SetStateAction, Dispatch, useCallback } from 'react';
import './styles.less';
import { TableSelector } from 'src/components/TableSelector';
import { TableOption } from 'src/components/TableSelector/components/TableOption';
import { DatabaseObject } from 'src/components/DatabaseSelector/interfaces';
import { emptyStateComponent } from 'src/components/EmptyState';
import { useToasts } from 'src/components/MessageToasts/withToasts';
import { LocalStorageKeys, getItem } from 'src/utils/localStorageHelpers';
import {
  DatasetActionType,
  DatasetObject,
} from 'src/features/datasets/AddDataset/types';
import { Table } from 'src/hooks/apiResources';
import { useTranslation } from 'react-i18next';

interface CreateDatasetPanelProps {
  setDataset: Dispatch<SetStateAction<object>>;
  dataset?: Partial<DatasetObject> | null;
  datasetNames?: (string | null | undefined)[] | undefined;
}

export default function CreateDatasetPanel({
  setDataset,
  dataset,
  datasetNames,
}: CreateDatasetPanelProps) {
  const { t } = useTranslation();
  const { addDangerToast } = useToasts();

  const setDatabase = useCallback(
    (db: Partial<DatabaseObject>) => {
      setDataset({ type: DatasetActionType.SelectDatabase, payload: { db } });
    },
    [setDataset],
  );
  const setSchema = (schema: string) => {
    if (schema) {
      setDataset({
        type: DatasetActionType.SelectSchema,
        payload: { name: 'schema', value: schema },
      });
    }
  };
  const setTable = (tableName: string) => {
    setDataset({
      type: DatasetActionType.SelectTable,
      payload: { name: 'table_name', value: tableName },
    });
  };
  useEffect(() => {
    const currentUserSelectedDb = getItem(
      LocalStorageKeys.Database,
      null,
    ) as DatabaseObject;
    if (currentUserSelectedDb) {
      setDatabase(currentUserSelectedDb);
    }
  }, [setDatabase]);

  const customTableOptionLabelRenderer = useCallback(
    (table: Table) => (
      <TableOption
        table={
          datasetNames?.includes(table.value)
            ? {
                ...table,
                extra: {
                  warning_markdown: t('This table already has a dataset'),
                },
              }
            : table
        }
      />
    ),
    [datasetNames, t],
  );

  return (
    <div className="create-dataset-wrapper">
      <TableSelector
        database={dataset?.db}
        handleError={addDangerToast}
        emptyState={emptyStateComponent(false)}
        onDbChange={setDatabase}
        onSchemaChange={setSchema}
        onTableSelectChange={setTable}
        isSqlLabMode={false}
        customTableOptionLabelRenderer={customTableOptionLabelRenderer}
        {...(dataset?.schema && { schema: dataset.schema })}
      />
    </div>
  );
}
