import { useTranslation } from 'react-i18next';
import { RadioChangeEvent } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { LOCAL_STORAGE, LOCALE } from '@superset-ui/core';

type UseLanguageResult = {
  /** Язык приложения */
  language: LOCALE;
  /** Обновление языка */
  updateLanguage: (e: RadioChangeEvent) => void;
};

/** Хук для работы по смене языка */
export const useLanguage = (): UseLanguageResult => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<LOCALE>(() => {
    const languageInStorage = localStorage.getItem(
      LOCAL_STORAGE.language,
    ) as LOCALE;
    return languageInStorage || LOCALE.ru;
  });

  const updateLanguage = useCallback(
    (e: RadioChangeEvent) => {
      const { value } = e.target;
      setLanguage(value);
      i18n.changeLanguage(value);
      localStorage.setItem(LOCAL_STORAGE.language, value);
    },
    [i18n],
  );

  useEffect(() => {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE.language);
    const defaultValue = userLanguage || LOCALE.ru;
    setLanguage(defaultValue as LOCALE);
    i18n.changeLanguage(defaultValue);
    localStorage.setItem(LOCAL_STORAGE.language, defaultValue);
  }, [i18n]);

  return {
    language,
    updateLanguage,
  };
};
