import React, { FC, memo, Reducer, useReducer, useState } from 'react';
import './styles.less';
import { useTranslation } from 'react-i18next';
import { Curtain } from '../../../components/Curtain';
import { BI_PORTAL_ID } from '../../../constants';
import CreateDatasetPanel from '../AddDataset/CreateDatasetPanel';
import { DatasetObject, DSReducerActionType } from '../AddDataset/types';
import useDatasetsList from '../hooks/useDatasetLists';
import Footer from '../AddDataset/Footer';
import DatasetPanel from '../AddDataset/DatasetPanel';
import { CreateDatasetCurtainProps } from './interfaces';
import { datasetReducer } from './utils/datasetReducer';

export const CreateDatasetCurtain: FC<CreateDatasetCurtainProps> = memo(
  props => {
    const { isOpen, handleCloseDatasetCreate } = props;
    const { t } = useTranslation();

    const [hasColumns, setHasColumns] = useState(false);
    const [dataset, setDataset] = useReducer<
      Reducer<Partial<DatasetObject> | null, DSReducerActionType>
    >(datasetReducer, null);

    const { datasets, datasetNames } = useDatasetsList(
      dataset?.db,
      dataset?.schema,
    );

    return (
      <Curtain
        portalId={BI_PORTAL_ID}
        isOpen={isOpen}
        onClose={handleCloseDatasetCreate}
        title={t('Create a dataset')}
        containerClassName="create-dataset-curtain"
        footer={
          <Footer
            datasetObject={dataset}
            hasColumns={hasColumns}
            datasets={datasetNames}
            handleCloseDatasetCreate={handleCloseDatasetCreate}
          />
        }
      >
        <CreateDatasetPanel
          setDataset={setDataset}
          dataset={dataset}
          datasetNames={datasetNames}
        />
        <DatasetPanel
          tableName={dataset?.table_name}
          dbId={dataset?.db?.id}
          schema={dataset?.schema}
          setHasColumns={setHasColumns}
          datasets={datasets}
        />
      </Curtain>
    );
  },
);
