import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPropTypes } from 'src/features/databases/types';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';

export const QueryField = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  db,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  return (
    <LabeledErrorBoundInput
      id="query_input"
      name="query_input"
      isRequired={isRequired}
      value={db?.query_input}
      validationMethods={{ onBlur: getValidation }}
      errorMessage={validationErrors?.query}
      placeholder={t('e.g. param1=value1&param2=value2')}
      label={t('Additional Parameters')}
      onChange={changeMethods.onQueryChange}
      helpText={t('Add additional custom parameters')}
    />
  );
};
