import React from 'react';
import UserIcon from 'src/assets/images/icons/userIcon.svg';
import { Status } from '../../../../components/Steps/interfaces';
import { UserRoles } from '../../../../types/bootstrapTypes';
import { isViewer } from '../../../../types/Role';

export const scriptConfigs = (t: (key: string) => string, roles: UserRoles) => [
  {
    title: t('Databases'),
    description: t('Connect your data source'),
    status: isViewer(roles) ? Status.wait : Status.process,
    link: '/databaseview/list/',
  },
  {
    title: t('Datasets'),
    description: t(
      'Create datasets/samples, add calculated fields and metrics to them',
    ),
    status: isViewer(roles) ? Status.wait : Status.process,
    link: '/tablemodelview/list/',
  },
  {
    title: t('Charts'),
    description: t(
      'Create a variety of interactive visualizations based on your datasets',
    ),
    status: isViewer(roles) ? Status.wait : Status.process,
    link: '/chart/list/',
  },
  {
    title: t('Dashboards'),
    description: t(
      'Create dashboards/interactive analytics reports using your own charts and design elements',
    ),
    status: Status.process,
    link: '/dashboard/list/',
  },
  {
    title: t('Reports'),
    description: t(
      'Share your analytics with colleagues by sending regular reports or set up an alert on a metric',
    ),
    icon: <UserIcon />,
    status: Status.process,
    link: '/report/list/',
  },
];
