import React, { FC, memo } from 'react';
import { Radio } from 'antd';
import { LOCALE } from '@superset-ui/core';
import { LanguagesProps } from './interfaces';
import { StyledLanguagesRadioGroup } from '../../styled';

/** Блок смены языка */
export const Languages: FC<LanguagesProps> = memo(props => {
  const { language, updateLanguage } = props;

  return (
    <StyledLanguagesRadioGroup
      onChange={updateLanguage}
      value={language}
      size="small"
      buttonStyle="solid"
      className="languages"
    >
      <Radio.Button value={LOCALE.ru}>RU</Radio.Button>
      <Radio.Button value={LOCALE.en}>EN</Radio.Button>
    </StyledLanguagesRadioGroup>
  );
});
