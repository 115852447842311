import React, { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/newComponents/Button';
import { Input } from 'src/newComponents/Input';
import { Form } from 'src/components/Form/components/Form';
import { FormItem } from 'src/components/Form/components/FormItem';
import { SqlAlchemyTabProps } from './interfaces';
import { fallbackDisplayText, fallbackDocsUrl } from './constants';
import './styles.less';

export const SqlAlchemyTab: FC<SqlAlchemyTabProps> = props => {
  const {
    db,
    onInputChange,
    isTestInProgress,
    testConnection,
    conf,
    children,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      database_name: db?.database_name || '',
      sqlalchemy_uri: db?.sqlalchemy_uri || '',
    });
  }, [db, form]);

  const onChange = (name: string, event: ChangeEvent<HTMLInputElement>) => {
    onInputChange({ name, value: event.target.value });
  };
  return (
    <Form layout="vertical" className="sqla-tab" form={form}>
      <FormItem
        name="database_name"
        label={t('Display Name')}
        help={t('Pick a name to help you identify this database.')}
        required
      >
        <Input
          dataTestId="database-name-input"
          value={db?.database_name || ''}
          placeholder={t('Name your database')}
          onChange={event => onChange('database_name', event)}
        />
      </FormItem>
      <FormItem
        name="sqlalchemy_uri"
        label={t('SQLAlchemy URI')}
        required
        help={
          <span>
            {t('Refer to the')}{' '}
            <a
              href={fallbackDocsUrl || conf?.SQLALCHEMY_DOCS_URL || ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              {fallbackDisplayText || conf?.SQLALCHEMY_DISPLAY_TEXT || ''}
            </a>{' '}
            {t('for more information on how to structure your URI.')}
          </span>
        }
      >
        <Input
          dataTestId="sqlalchemy-uri-input"
          value={db?.sqlalchemy_uri || ''}
          autoComplete="off"
          placeholder={
            db?.sqlalchemy_uri_placeholder ||
            t('dialect+driver://username:password@host:port/database')
          }
          onChange={event => onChange('sqlalchemy_uri', event)}
        />
      </FormItem>
      {children}
      <Button
        onClick={testConnection}
        isLoading={isTestInProgress}
        type="primary"
        ghost
        className="sqla-tab__button"
      >
        {t('Test connection')}
      </Button>
    </Form>
  );
};
