import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSingleViewResource } from 'src/views/CRUD/hooks';
import { logEvent } from 'src/logger/actions';
import withToasts from 'src/components/MessageToasts/withToasts';
import {
  LOG_ACTIONS_DATASET_CREATION_EMPTY_CANCELLATION,
  LOG_ACTIONS_DATASET_CREATION_SUCCESS,
} from 'src/logger/LogUtils';
import { useTranslation } from 'react-i18next';
import { DatasetObject } from '../types';
import { withChartCreationCurtain } from '../../../charts/components/withChartCreationCurtain';
import { parseUrlParams } from '../../../../utils/parseUrlParams';
import { FooterComponentProps, FooterProps } from './interfaces';
import { Button } from '../../../../newComponents/Button';
import { INPUT_FIELDS, LOG_ACTIONS } from './contants';

function Footer(props: FooterComponentProps) {
  const {
    datasetObject,
    addDangerToast,
    hasColumns = false,
    datasets,
    setIsOpenChartCreationCurtain,
    handleCloseDatasetCreate,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { createResource } = useSingleViewResource<Partial<DatasetObject>>(
    'dataset',
    t('dataset'),
    addDangerToast,
  );

  const createLogAction = (dataset: Partial<DatasetObject>) => {
    let totalCount = 0;
    const value = Object.keys(dataset).reduce((total, key) => {
      if (INPUT_FIELDS.includes(key) && dataset[key]) {
        totalCount += 1;
      }
      return totalCount;
    }, 0);

    return LOG_ACTIONS[value];
  };

  const cancelButtonOnClick = () => {
    if (!datasetObject) {
      logEvent(LOG_ACTIONS_DATASET_CREATION_EMPTY_CANCELLATION, {});
    } else {
      const logAction = createLogAction(datasetObject);
      logEvent(logAction, datasetObject);
    }
    handleCloseDatasetCreate();
  };

  const tooltipText = t('Select a database table.');

  const onSave = () => {
    if (datasetObject) {
      const data = {
        database: datasetObject.db?.id,
        schema: datasetObject.schema,
        table_name: datasetObject.table_name,
      };
      createResource(data).then(response => {
        if (!response) {
          return;
        }
        if (typeof response === 'number' && datasetObject.table_name) {
          logEvent(LOG_ACTIONS_DATASET_CREATION_SUCCESS, datasetObject);
          // When a dataset is created the response we get is its ID number

          const searchParams = parseUrlParams(history.location.search);
          searchParams.datasource = datasetObject.table_name;

          history.push({
            pathname: history.location.pathname,
            search: `?${new URLSearchParams(searchParams).toString()}`,
          });

          setIsOpenChartCreationCurtain(true);
        }
      });
    }
  };

  const disabledCheck =
    !datasetObject?.table_name ||
    !hasColumns ||
    datasets?.includes(datasetObject?.table_name);

  return (
    <>
      <Button
        dataTestId="curtain-dataset-close"
        className="mr-8"
        type="default"
        onClick={cancelButtonOnClick}
      >
        {t('Cancel')}
      </Button>
      <Button
        dataTestId="curtain-dataset-create"
        type="primary"
        isDisabled={disabledCheck}
        tooltip={{
          title: !datasetObject?.table_name ? tooltipText : undefined,
        }}
        onClick={onSave}
      >
        {t('Create dataset and chart')}
      </Button>
    </>
  );
}

export default withChartCreationCurtain<FooterProps>(withToasts(Footer));
