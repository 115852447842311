import React, { ComponentType, useCallback, useState } from 'react';
import { WithDatabaseCreationCurtainProps } from './interfaces';
import DatabaseCurtain from '../DatabaseCurtain';

export function withDatabaseCreationCurtain<T>(
  BaseComponent: ComponentType<T & WithDatabaseCreationCurtainProps>,
) {
  return function ComponentWithChartCreationCurtain(props: T) {
    const [isCreateDatabaseCurtainOpen, setIsCreateDatabaseCurtainOpen] =
      useState(false);

    const handleCloseCreateDatabaseCurtain = useCallback(() => {
      setIsCreateDatabaseCurtainOpen(false);
    }, []);

    return (
      <>
        {isCreateDatabaseCurtainOpen && (
          <DatabaseCurtain
            isOpen={isCreateDatabaseCurtainOpen}
            onClose={handleCloseCreateDatabaseCurtain}
          />
        )}
        <BaseComponent
          {...props}
          setIsOpenDatabaseCreationCurtain={setIsCreateDatabaseCurtainOpen}
        />
      </>
    );
  };
}
