import React, { FC, memo } from 'react';
import { Tooltip } from 'antd';
import cx from 'classnames';
import './styles.less';
import { FolderPathProps } from './interfaces';
import { preparePath } from './utils/preparePath';
import { DEFAULT_FOLDER_PATH_ID } from '../../../../constants';

export const FolderPath: FC<FolderPathProps> = memo(props => {
  const { path, folderNames, folderIds, setNewFolderPath } = props;

  const redirectToFolder = (name: string, id: string) => () => {
    if (id === DEFAULT_FOLDER_PATH_ID) {
      setNewFolderPath('', '');
      return;
    }
    const indexName = folderNames?.indexOf(name);
    const indexId = folderIds?.indexOf(id);

    if (typeof indexName === 'number' && typeof indexId === 'number') {
      const newFolderNames =
        folderNames?.substring(0, indexName + name.length) || '';
      const newFolderIds = folderIds?.substring(0, indexId + id.length) || '';
      setNewFolderPath(newFolderNames, newFolderIds);
    }
  };

  const calculatedPath = preparePath(path);

  const getHiddenTitle = () => {
    if (calculatedPath.hidden.length) {
      return (
        <div>
          <div className="folder-path__hidden">
            {calculatedPath.hidden.map(({ name, id }) => (
              <React.Fragment key={id}>
                <span
                  role="button"
                  tabIndex={0}
                  className="folder-path__hidden--hover"
                  onClick={redirectToFolder(name, id)}
                >
                  {name}
                </span>
                <span>/</span>
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
    return '';
  };

  const isLastPath = (index: number) =>
    calculatedPath.displayed.length - 1 === index;

  return (
    <div className="folder-path">
      {calculatedPath.displayed.map(
        ({ name, id, isHidden, fullName }, index) => (
          <React.Fragment key={id}>
            {isHidden ? (
              <Tooltip
                title={getHiddenTitle()}
                placement="topLeft"
                overlayClassName="folder-path__tooltip"
              >
                <span className="folder-path__path folder-path__path--previous">
                  {name}
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={fullName} placement="top">
                <span
                  role="button"
                  tabIndex={0}
                  className={cx('folder-path__path ', {
                    'folder-path__path--current': isLastPath(index),
                    'folder-path__path--previous': !isLastPath(index),
                  })}
                  onClick={redirectToFolder(name, id as string)}
                >
                  {name}
                </span>
              </Tooltip>
            )}

            {isLastPath(index) ? null : (
              <span className="folder-path__divider">/</span>
            )}
          </React.Fragment>
        ),
      )}
    </div>
  );
});
