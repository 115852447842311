import { UserRoles } from './bootstrapTypes';

export type Role = {
  id: number;
  name: string;
};

export const isAdmin = (roles: UserRoles): boolean => 'Admin' in roles;

export const isCreator = (roles: UserRoles): boolean =>
  'FUNC_CREATOR (Alpha modified)' in roles;

export const isViewer = (roles: UserRoles): boolean =>
  'FUNC_VIEWER (Public Modified)' in roles;
