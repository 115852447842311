import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SupersetTheme } from '@superset-ui/core';
import { getDatabaseDocumentationLinks } from 'src/views/CRUD/hooks';
import Alert from 'src/components/Alert';
import { antdWarningAlertStyles } from './styles';

const supersetTextDocs = getDatabaseDocumentationLinks();
export const DOCUMENTATION_LINK = supersetTextDocs
  ? supersetTextDocs.support
  : 'https://superset.apache.org/docs/databases/installing-database-drivers';

export interface IProps {
  errorMessage: string;
  showDbInstallInstructions: boolean;
}

const ErrorAlert: FunctionComponent<IProps> = ({
  errorMessage,
  showDbInstallInstructions,
}) => {
  const { t } = useTranslation();
  return (
    <Alert
      closable={false}
      css={(theme: SupersetTheme) => antdWarningAlertStyles(theme)}
      type="error"
      showIcon
      message={errorMessage}
      description={
        showDbInstallInstructions ? (
          <>
            <br />
            {t(
              'Database driver for importing maybe not installed. Visit the Superset documentation page for installation instructions: ',
            )}
            <a
              href={DOCUMENTATION_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="additional-fields-alert-description"
            >
              {t('here')}
            </a>
            .
          </>
        ) : (
          ''
        )
      }
    />
  );
};

export default ErrorAlert;
