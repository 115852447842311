import React, { useEffect, useState, useRef } from 'react';
import { SupersetClient, logging, t } from '@superset-ui/core';
import { addDangerToast } from 'src/components/MessageToasts/actions';
import DatasetPanel from './DatasetPanel';
import {
  ITableColumn,
  IDatabaseTable,
  IDatasetPanelWrapperProps,
  IColumnProps,
  DatabaseTableType,
} from './types';
import { isIDatabaseTable } from './utils/isIDatabaseTable';

const DatasetPanelWrapper = ({
  tableName,
  dbId,
  schema,
  setHasColumns,
  datasets,
}: IDatasetPanelWrapperProps) => {
  const [columnList, setColumnList] = useState<ITableColumn[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const tableNameRef = useRef(tableName);

  const getTableMetadata = async (props: IColumnProps) => {
    const { dbId, tableName, schema } = props;
    setLoading(true);
    setHasColumns?.(false);
    const path = `/api/v1/database/${dbId}/table/${tableName}/${schema}/`;
    try {
      const response = await SupersetClient.get({
        endpoint: path,
      });

      if (isIDatabaseTable(response?.json as DatabaseTableType)) {
        const table: IDatabaseTable = response.json as IDatabaseTable;
        /**
         *  The user is able to click other table columns while the http call for last selected table column is made
         *  This check ensures we process the response that matches the last selected table name and ignore the others
         */
        if (table.name === tableNameRef.current) {
          setColumnList(table.columns);
          setHasColumns?.(table.columns.length > 0);
          setHasError(false);
        }
      } else {
        setColumnList([]);
        setHasColumns?.(false);
        setHasError(true);
        addDangerToast(
          t(
            'The API response from %s does not match the IDatabaseTable interface.',
            path,
          ),
        );
        logging.error(
          t(
            'The API response from %s does not match the IDatabaseTable interface.',
            path,
          ),
        );
      }
    } catch (error) {
      setColumnList([]);
      setHasColumns?.(false);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    tableNameRef.current = tableName;
    if (tableName && schema && dbId) {
      getTableMetadata({ tableName, dbId, schema });
    }
    // getTableMetadata is a const and should not be in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName, dbId, schema]);

  return (
    <DatasetPanel
      columnList={columnList}
      hasError={hasError}
      isLoading={loading}
      tableName={tableName}
      datasets={datasets}
    />
  );
};

export default DatasetPanelWrapper;
