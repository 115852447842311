import React, { FC } from 'react';
import cn from 'classnames';
import { Card } from 'antd';
import Icons from 'src/components/Icons';
import { IconCardProps } from './interfaces';
import './styles.less';

export const IconCard: FC<IconCardProps> = props => {
  const { cardText, className, ...rest } = props;

  return (
    <Card hoverable className={cn('card', className)} {...rest}>
      <div className="card__container">
        <Icons.DatabaseOutlined
          className="card__icon"
          aria-label="default-icon"
        />
        <p className="card__text">{cardText}</p>
      </div>
    </Card>
  );
};
