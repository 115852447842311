import React, { FC } from 'react';
import { Modal as AntdModal } from 'antd';
import type { ModalFuncProps } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { ModalProps } from './interfaces';
import './styles.less';

const MainModal: FC<ModalProps> = props => {
  const { t } = useTranslation();
  const {
    children,
    className,
    isOpen,
    onCancel,
    title,
    cancelButtonText = t('Cancel'),
    submitButtonText = t('Submit'),
    cancelButtonProps,
    cancelDataTestId,
    submitButtonProps,
    isSubmitLoading,
    isSubmitDisabled,
    submitDataTestId,
    submitTooltip,
    onSubmit,
    destroyOnClose,
    zIndex,
    centered = true,
    footer,
    submitButtonType = 'primary',
    cancelButtonType = 'default',
    maxHeight,
    maxWidth,
    minHeight,
    height,
    bodyStyle,
  } = props;

  const modalFooter =
    footer || footer === null
      ? footer
      : [
          <Button
            key="cancel"
            onClick={onCancel}
            dataTestId={cancelDataTestId}
            type={cancelButtonType}
            {...cancelButtonProps}
          >
            {cancelButtonText}
          </Button>,
          <Button
            key="submit"
            onClick={onSubmit}
            tooltip={submitTooltip}
            dataTestId={submitDataTestId}
            isDisabled={isSubmitDisabled}
            isLoading={isSubmitLoading}
            type={submitButtonType}
            {...submitButtonProps}
          >
            {submitButtonText}
          </Button>,
        ];

  return (
    <AntdModal
      width={maxWidth}
      bodyStyle={{ ...bodyStyle, maxHeight, minHeight, height }}
      footer={modalFooter}
      title={title}
      className={cn('bi-modal', className)}
      open={isOpen}
      zIndex={zIndex}
      centered={centered}
      destroyOnClose={destroyOnClose}
      onCancel={onCancel}
    >
      {children}
    </AntdModal>
  );
};

export const Modal = Object.assign(MainModal, {
  error: (config: ModalFuncProps) => AntdModal.error({ ...config }),
  warning: (config: ModalFuncProps) => AntdModal.warning({ ...config }),
  confirm: (config: ModalFuncProps) => AntdModal.confirm({ ...config }),
  useModal: AntdModal.useModal,
});
