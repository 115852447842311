import { Button, Radio, Avatar as AvatarAntd } from 'antd';
import { styled } from '@superset-ui/core';
import { UserOutlined } from '@ant-design/icons';
import { GenericLink } from '../../components/GenericLink/GenericLink';
import LogoSVG from '../../assets/images/mtsLogo.svg';

export const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 40px;
  height: 58px;
  box-shadow: ${({ theme }) => `0 4px 16px 0 ${theme.colors.boxShadow.header}`};
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.grayscale.light5};
`;

export const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLogo = styled(GenericLink)`
  height: 32px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
`;

export const StyledLogoSvg = styled(LogoSVG)`
  height: 32px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
`;

export const StyledLogoTitle = styled.span`
  margin-left: 8px;
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.secondary.dark4};
  font-family: 'MTSWideMedium', sans-serif;
`;

export const StyledDocsButton = styled(Button)<{
  isNeedCheckDocumentationRelease?: boolean;
}>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 28px;

  &:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: ${({ theme }) => theme.colors.error.base};
    border-radius: 50%;
    top: -5px;
    right: -5px;
    display: ${({ isNeedCheckDocumentationRelease }) =>
      isNeedCheckDocumentationRelease ? 'block' : 'none'};
  }
`;

export const StyledCreateButton = styled(Button)`
  margin-right: 16px;
  border-radius: 6px;
`;

export const StyledLanguagesRadioGroup = styled(Radio.Group)`
  margin-right: 16px;

  .ant-radio-button-wrapper {
    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:nth-child(2) {
      border-radius: 0 6px 6px 0;
    }
  }
`;

export const StyledAvatar = styled(AvatarAntd)`
  cursor: pointer;
`;

// eslint-disable-next-line theme-colors/no-literal-colors
export const StyledDropdownMenu = styled.div`
  width: 230px;
  padding: 16px 16px 12px 16px;
  background-color: ${({ theme }) => theme.colors.grayscale.light5};
  box-shadow:
    0 9px 28px 8px rgba(0, 0, 0, 0.05),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  .ant-divider-horizontal {
    margin: 16px 0 12px 0;
  }

  .ant-btn-text {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;

    svg {
      margin-right: 16px;
    }
  }
`;

export const StyledDropdownMenuContainer = styled.div`
  display: flex;
`;

export const StyledDropdownMenuMeta = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  max-width: 133px;
`;

export const StyledName = styled.span`
  font-family: 'MTSCompactMedium', sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  word-break: break-word;
`;

export const StyledDropdownMenuText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.dark5};
  text-decoration: none !important;
  font-family: 'MTSCompactRegular', sans-serif;
  word-break: break-word;
`;

export const StyledDropdownMenuTextLink = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.dark5};
  text-decoration: none !important;
  font-family: 'MTSCompactRegular', sans-serif;
  word-break: break-word;
`;

export const StyledProfileIcon = styled(UserOutlined)`
  > svg {
    margin-right: 0 !important;
  }
`;

export const StyledDropdownMenuLogoutButton = styled(Button)`
  margin-top: 12px;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme.colors.secondary.neutral5}`};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.secondary.neutral3};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.neutral5};
  }
`;

export const StyledDropdownMenuLogoutLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.dark5};
  text-decoration: none !important;
  font-family: 'MTSCompactMedium', sans-serif;
  word-break: break-word;
`;

export const StyledDropdownMenuEmail = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.dark6};
  font-family: 'MTSCompactRegular', sans-serif;
  word-break: break-word;
`;
