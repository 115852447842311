import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Button } from '../Button';
import { Upload } from '../../components';
import { UploadButtonProps } from './interfaces';

export const UploadButton: FC<UploadButtonProps> = memo(props => {
  const { fileList, setFileList, accept, onChange: onAntdChange } = props;
  const { t } = useTranslation();

  const changeFile = (info: UploadChangeParam) => {
    setFileList([
      {
        ...info.file,
        status: 'done',
      },
    ]);
  };

  const removeFile = (removedFile: UploadFile) => {
    setFileList(prevState =>
      prevState.filter(file => file.uid !== removedFile.uid),
    );
    onAntdChange?.();
    return false;
  };

  const onChange = (info: UploadChangeParam) => {
    changeFile(info);
    onAntdChange?.(info);
  };

  return (
    <Upload
      id="file"
      data-test="upload-file"
      accept={accept}
      fileList={fileList}
      onChange={onChange}
      onRemove={removeFile}
    >
      <Button type="default">{t('Select file')}</Button>
    </Upload>
  );
});
