import React from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';
import { FieldPropTypes } from 'src/features/databases/types';
import { getFieldTextMap } from './utils/getFieldTextMap';

export const ValidatedInputField = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  db,
  field,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  const FIELD_TEXT_MAP = getFieldTextMap(t);
  return (
    <LabeledErrorBoundInput
      id={field}
      name={field}
      isRequired={isRequired}
      value={db?.parameters?.[field]}
      validationMethods={{ onBlur: getValidation }}
      errorMessage={validationErrors?.[field]}
      placeholder={FIELD_TEXT_MAP[field].placeholder}
      helpText={FIELD_TEXT_MAP[field].helpText}
      label={field}
      onChange={changeMethods.onParametersChange}
      className={FIELD_TEXT_MAP[field].className || field}
    />
  );
};
