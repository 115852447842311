import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { SupersetClient, t } from '@superset-ui/core';
import { FormInstance } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useHistory } from 'react-router-dom';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { Database } from 'src/pages/Databases/interfaces';
import { RcFile } from 'antd/lib/upload';
import { useNotification } from '../../../../../hooks/useNotification';
import { FormValuesExcelUpload } from '../../UploadDataExcelCurtain/interfaces';
import {
  FormValuesCsvUpload,
  DELIMITER,
} from '../../UploadDataCsvCurtain/interfaces';
import { FormValuesGeoJsonUpload } from '../../UploadGeoJsonCurtain/interfaces';

type UseUploadToDatabasesProps<T> = {
  form: FormInstance<T>;
  onHide: () => void;
};

type UseUploadToDatabasesResult = {
  getDatabases: () => void;
  databases: Array<{ label: string; value: number }>;
  isLoading: boolean;
  uploadExcel: (values: FormValuesExcelUpload) => void;
  uploadCsv: (delimiterOther?: string) => (values: FormValuesCsvUpload) => void;
  uploadGeoJson: (values: FormValuesGeoJsonUpload) => void;
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
};

export const useUploadToDatabases = <T,>({
  form,
  onHide,
}: UseUploadToDatabasesProps<T>): UseUploadToDatabasesResult => {
  const history = useHistory();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { openNotification, openErrorNotification } = useNotification();
  const [databases, setDatabases] = useState<
    Array<{ label: Database['database_name']; value: Database['id'] }>
  >([]);
  const [_, setQuery] = useQueryParams({
    needRefreshDatabases: BooleanParam,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleErrorRequest = useCallback(
    body => {
      if (body) {
        const { message } = body;
        if (message) {
          if (typeof message === 'string') {
            return openNotification({
              type: 'error',
              title: t(message),
            });
          }

          const messages = [];
          if (Array.isArray(message)) {
            messages.push(...message);
          } else {
            const values = Object.values(message);
            messages.push(...(values as Array<string>));
          }
          const errorText = (
            <>
              {messages.map(message => (
                <div key={message}>{t(message)}</div>
              ))}
            </>
          );
          return openNotification({
            type: 'error',
            title: errorText,
          });
        }
      }
      return openErrorNotification();
    },
    [openNotification],
  );

  const updateDatabases = () => {
    setQuery({ needRefreshDatabases: true });
  };

  function checkIsEmptyFileOrDatabase<T>(
    file?: RcFile | T,
    database?: string,
  ): file is T {
    return !(file instanceof File) || !database;
  }

  const generateFormData = (
    file: RcFile,
    values: FormValuesExcelUpload | FormValuesGeoJsonUpload,
    delimiterOther?: string,
  ) => {
    const formData = new FormData();
    formData.append('file', file);

    const keys = Object.keys(values);

    keys.forEach(key => {
      if (
        typeof values[key] !== 'undefined' &&
        key !== 'file' &&
        key !== 'database' &&
        values[key]
      ) {
        if (key === 'delimiter' && values[key] === DELIMITER.OTHER) {
          if (delimiterOther) {
            formData.append(key, delimiterOther);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
    });

    return formData;
  };

  const uploadFile = (
    database: string,
    endpoint: string,
    formData: FormData,
  ) => {
    setIsLoading(true);

    SupersetClient.post({
      endpoint: `/api/v1/database/${database}/${endpoint}/`,
      body: formData,
      headers: { Accept: 'application/json' },
    })
      .then(() => {
        updateDatabases();
        onHide();
        history.push('/tablemodelview/list/');
      })
      .catch((error: Response) => {
        error.json().then(body => {
          handleErrorRequest(body);
        });
      })
      .finally(() => setIsLoading(false));
  };

  const getDatabases = useCallback(() => {
    setIsLoading(true);
    SupersetClient.get({
      endpoint:
        '/api/v1/database/?q=(filters:!((col:allow_file_upload,opr:upload_is_enabled,value:!t)),page_size:-1)',
    })
      .then(response => {
        if (response.json?.result?.length) {
          const databasesInfoSelect = (response.json?.result as Database[]).map(
            ({ database_name, id }) => ({
              label: database_name,
              value: id,
            }),
          );
          setDatabases(databasesInfoSelect);
          form.setFieldValue('database', databasesInfoSelect[0].value);
        }
      })
      .catch((error: Response) => {
        error.json().then(body => {
          handleErrorRequest(body);
        });
      })
      .finally(() => setIsLoading(false));
  }, [handleErrorRequest]);

  const uploadExcel = (values: FormValuesExcelUpload) => {
    const { database } = values;
    const file = fileList[0].originFileObj;
    const isEmptyFileOrDatabase = checkIsEmptyFileOrDatabase(file, database);

    if (isEmptyFileOrDatabase) {
      return;
    }

    const formData = generateFormData(file, values);
    uploadFile(database, 'excel_upload', formData);
  };

  const uploadCsv =
    (delimiterOther?: string) => (values: FormValuesExcelUpload) => {
      const { database } = values;
      const file = fileList[0].originFileObj;
      const isEmptyFileOrDatabase = checkIsEmptyFileOrDatabase(file, database);

      if (isEmptyFileOrDatabase) {
        return;
      }

      const formData = generateFormData(file, values, delimiterOther);
      uploadFile(database, 'csv_upload', formData);
    };

  const uploadGeoJson = (values: FormValuesGeoJsonUpload) => {
    const { database } = values;
    const file = fileList[0].originFileObj;
    const isEmptyFileOrDatabase = checkIsEmptyFileOrDatabase(file, database);

    if (isEmptyFileOrDatabase) {
      return;
    }

    const formData = generateFormData(file, values);

    uploadFile(database, 'geo_json_upload', formData);
  };

  const fileListMemo = useMemo(() => fileList, [fileList]);

  return {
    getDatabases,
    databases,
    isLoading,
    uploadExcel,
    fileList: fileListMemo,
    setFileList,
    uploadCsv,
    uploadGeoJson,
  };
};
