import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPropTypes } from 'src/features/databases/types';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';

export const HttpPath = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  db,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  const extraJson = JSON.parse(db?.extra || '{}');
  return (
    <LabeledErrorBoundInput
      id="http_path"
      name="http_path"
      isRequired={isRequired}
      value={extraJson.engine_params?.connect_args?.http_path}
      validationMethods={{ onBlur: getValidation }}
      errorMessage={validationErrors?.http_path}
      placeholder={t('e.g. sql/protocolv1/o/12345')}
      label="HTTP Path"
      onChange={changeMethods.onExtraInputChange}
      helpText={t('Copy the name of the HTTP Path of your cluster.')}
    />
  );
};
