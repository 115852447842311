import { irregularDocumentationLinks } from '../../constants';

export const getDocumentationLink = (engine?: string) => {
  if (!engine) return null;

  if (!irregularDocumentationLinks[engine]) {
    return `https://superset.apache.org/docs/databases/${engine}`;
  }
  return irregularDocumentationLinks[engine];
};
