import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { FC, memo, useCallback, useState } from 'react';

import { AddToFolderModal } from 'src/features/AddToFolderModal';
import { CreationType } from 'src/features/AddToFolderModal/constants';

import { StyledCreateButton } from '../../styled';
import { CreateComponentProps, CreateProps } from './interfaces';
import { useCreate } from './hooks/useCreate';
import { filterMenu } from './utils/filteredMenu';
import { UploadDataExcelCurtain } from '../../../Curtains/UploadCurtains/UploadDataExcelCurtain';
import { UploadDataCsvCurtain } from '../../../Curtains/UploadCurtains/UploadDataCsvCurtain';
import { UploadGeoJsonCurtain } from '../../../Curtains/UploadCurtains/UploadGeoJsonCurtain';
import { withDatasetCreationCurtain } from '../../../datasets/CreateDatasetCurtain/hoc/withCreateDatasetCurtain';
import { withChartCreationCurtain } from '../../../charts/components/withChartCreationCurtain';
import { withDatabaseCreationCurtain } from '../../../databases/hoc';

/** Кнопка создания элементов в хэдере */
const CreateComponent: FC<CreateComponentProps> = memo(props => {
  const {
    roles,
    setIsOpenChartCreationCurtain,
    setIsOpenDatasetCreationCurtain,
    setIsOpenDatabaseCreationCurtain,
  } = props;
  const { t } = useTranslation();
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const {
    items,
    isOpenUploadDataExcelCurtain,
    closeUploadDataExcelCurtain,
    isOpenUploadDataCsvCurtain,
    closeUploadDataCsvCurtain,
    isOpenUploadDataGeoJsonCuration,
    closeUploadDataGeoJsonCuration,
  } = useCreate({
    roles,
    setIsFolderModalOpen,
    setIsOpenChartCreationCurtain,
    setIsOpenDatasetCreationCurtain,
    setIsOpenDatabaseCreationCurtain,
  });

  const filteredMenu = filterMenu(items, roles);

  const handleCloseFolderModal = useCallback(() => {
    setIsFolderModalOpen(false);
  }, []);

  return (
    <>
      <Dropdown menu={{ items: filteredMenu }}>
        <StyledCreateButton type="primary" size="small" className="create">
          <Space>
            {t('Create')}
            <DownOutlined />
          </Space>
        </StyledCreateButton>
      </Dropdown>
      {isFolderModalOpen && (
        <AddToFolderModal
          isShow={isFolderModalOpen}
          onHide={handleCloseFolderModal}
          creationType={CreationType.Folder}
          title={t('Create folder')}
        />
      )}
      {isOpenUploadDataExcelCurtain && (
        <UploadDataExcelCurtain
          isOpen={isOpenUploadDataExcelCurtain}
          onHide={closeUploadDataExcelCurtain}
        />
      )}
      {isOpenUploadDataCsvCurtain && (
        <UploadDataCsvCurtain
          isOpen={isOpenUploadDataCsvCurtain}
          onHide={closeUploadDataCsvCurtain}
        />
      )}
      {isOpenUploadDataGeoJsonCuration && (
        <UploadGeoJsonCurtain
          isOpen={isOpenUploadDataGeoJsonCuration}
          onHide={closeUploadDataGeoJsonCuration}
        />
      )}
    </>
  );
});

export const Create = withDatabaseCreationCurtain<CreateProps>(
  withChartCreationCurtain<CreateProps>(
    withDatasetCreationCurtain<CreateProps>(CreateComponent),
  ),
);
