import React, { FC, useEffect } from 'react';
import { AntdForm, Select } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input } from 'antd';
import { FormItem } from 'src/components/Form';
import { Curtain } from 'src/components/Curtain';
import { getTableExistOptions } from './utils/getTableExistOptions';
import '../styles.less';
import { defaultValues } from './constants';
import { FormValuesExcelUpload, UploadDataExcelProps } from './interfaces';
import { useUploadToDatabases } from '../hooks/useUploadToDatabases';
import { BI_PORTAL_ID } from '../../../../constants';
import { Button } from '../../../../newComponents/Button';
import { UploadButton } from '../../../../newComponents/UploadButton';

const { TextArea } = Input;

/** Шторка импорта эксельки в БД */
export const UploadDataExcelCurtain: FC<UploadDataExcelProps> = props => {
  const { isOpen, onHide } = props;
  const { t } = useTranslation();
  const [form] = AntdForm.useForm<FormValuesExcelUpload>();
  const {
    getDatabases,
    databases,
    uploadExcel,
    isLoading,
    fileList,
    setFileList,
  } = useUploadToDatabases({
    form,
    onHide,
  });

  useEffect(() => {
    getDatabases();
  }, [getDatabases]);

  return (
    <Curtain
      portalId={BI_PORTAL_ID}
      isOpen={isOpen}
      onClose={onHide}
      title={t('Upload Excel file to database')}
      containerClassName="upload-data-file"
      childrenClassName="upload-data-file__content"
      footer={
        <>
          <Button
            onClick={onHide}
            dataTestId="properties-modal-cancel-button"
            className="curtain--mr-8"
            type="default"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={form.submit}
            dataTestId="properties-modal-submit-button"
            type="primary"
            isLoading={isLoading}
          >
            {t('Save')}
          </Button>
        </>
      }
    >
      <AntdForm
        form={form}
        onFinish={uploadExcel}
        layout="vertical"
        initialValues={defaultValues}
      >
        <FormItem
          label={t('Table Name')}
          name="table_name"
          rules={[{ required: true, message: t('Table Name is required') }]}
          className="upload-data-file__title upload-data-file__block--with-description "
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Table name"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Name of table to be created from excel data.')}
        </p>
        <FormItem
          label={t('Excel File')}
          name="file"
          rules={[{ required: true, message: t('Excel File is required') }]}
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <UploadButton
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            fileList={fileList}
            setFileList={setFileList}
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Select a Excel file to be uploaded to a database.')}
        </p>
        <FormItem
          label={t('Sheet Name')}
          name="sheet_name"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Sheet name"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Strings used for sheet names (default is the first sheet).')}
        </p>
        <FormItem
          label={t('Database')}
          name="database"
          className="upload-data-file__title upload-data-file__block"
          rules={[{ required: true, message: t('Database is required') }]}
        >
          <Select
            data-test="dashboard-title-input"
            placeholder="Database"
            options={databases}
            onChange={value => form.setFieldValue('database', value)}
          />
        </FormItem>
        <FormItem
          label={t('Schema')}
          name="schema"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Schema"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Specify a schema (if database flavor supports this).')}
        </p>
        <FormItem
          label={t('Table Exists')}
          name="already_exists"
          rules={[{ required: true, message: t('Table Exists is required') }]}
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Select
            data-test="dashboard-title-input"
            placeholder="Table Exists"
            options={getTableExistOptions(t)}
            onChange={value => form.setFieldValue('already_exists', value)}
          />
          <p className="upload-data-file__description">
            {t(
              'If table exists do one of the following: Fail (do nothing), Replace (drop and recreate table) or Append (insert data).',
            )}
          </p>
        </FormItem>
        <FormItem
          label={t('Header Row')}
          name="header_row"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Header Row"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t(
            'Row containing the headers to use as column names (0 is first line of data). Leave empty if there is no header row.',
          )}
        </p>
        <FormItem
          label={t('Index Column')}
          name="index_column"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Index Column"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t(
            'Column to use as the row labels of the dataframe. Leave empty if no index column.',
          )}
        </p>
        <FormItem
          label={t('Skip Rows')}
          name="skip_rows"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Skip Rows"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Number of rows to skip at start of file.')}
        </p>
        <FormItem
          label={t('Rows to Read')}
          name="rows_to_read"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Rows to Read"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Number of rows of file to read.')}
        </p>
        <FormItem
          label={t('Parse Dates')}
          name="column_dates"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Parse Dates"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t(
            'A comma separated list of columns that should be parsed as dates.',
          )}
        </p>
        <FormItem
          label={t('Decimal Character')}
          name="decimal_character"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Decimal Character"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Character to interpret as decimal point.')}
        </p>
        <FormItem
          label={t('Dataframe Index')}
          name="dataframe_index"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Checkbox
            data-test="dashboard-title-input"
            onChange={event =>
              form.setFieldValue('dataframe_index', event.target.checked)
            }
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Write dataframe index as a column.')}
        </p>
        <FormItem
          label={t('Column Label(s)')}
          name="index_label"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="dashboard-title-input"
            type="text"
            placeholder="Column Label(s)"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t(
            'Column label for index column(s). If None is given and Dataframe Index is True, Index Names are used.',
          )}
        </p>
        <FormItem
          label={t('Null Values')}
          name="null_values"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <TextArea
            data-test="dashboard-title-input"
            placeholder="Null Values"
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t(
            'Json list of the values that should be treated as null. Examples: [""], ["None", "N/A"], ["nan", "null"]. Warning: Hive database supports only single value. Use [""] for empty string.',
          )}
        </p>
      </AntdForm>
    </Curtain>
  );
};
