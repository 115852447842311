import React, { FC, useEffect } from 'react';
import { AntdForm, Select } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { FormItem } from 'src/components/Form';
import { Curtain } from 'src/components/Curtain';
import '../styles.less';
import { FormValuesGeoJsonUpload, UploadDataGeoJsonProps } from './interfaces';
import { useUploadToDatabases } from '../hooks/useUploadToDatabases';
import { getTableExistOptions } from '../UploadDataExcelCurtain/utils/getTableExistOptions';
import { BI_PORTAL_ID } from '../../../../constants';
import { Button } from '../../../../newComponents/Button';
import { UploadButton } from '../../../../newComponents/UploadButton';

/** Шторка импорта GeoJson в БД */
export const UploadGeoJsonCurtain: FC<UploadDataGeoJsonProps> = props => {
  const { isOpen, onHide } = props;
  const { t } = useTranslation();
  const [form] = AntdForm.useForm<FormValuesGeoJsonUpload>();
  const {
    getDatabases,
    databases,
    uploadGeoJson,
    isLoading,
    fileList,
    setFileList,
  } = useUploadToDatabases({
    form,
    onHide,
  });

  useEffect(() => {
    getDatabases();
  }, [getDatabases]);

  return (
    <Curtain
      portalId={BI_PORTAL_ID}
      isOpen={isOpen}
      onClose={onHide}
      title={t('Upload GeoJSON to the database')}
      containerClassName="upload-data-file"
      childrenClassName="upload-data-file__content"
      footer={
        <>
          <Button
            onClick={onHide}
            dataTestId="properties-curation-cancel-button"
            className="curtain--mr-8"
            type="default"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={form.submit}
            dataTestId="properties-curation-submit-button"
            type="primary"
            isLoading={isLoading}
          >
            {t('Save')}
          </Button>
        </>
      }
    >
      <AntdForm form={form} onFinish={uploadGeoJson} layout="vertical">
        <FormItem
          label={t('Table name')}
          help={t('Name of table to be created')}
          name="table_name"
          rules={[{ required: true, message: t('Table Name is required') }]}
        >
          <Input
            data-test="upload-csv-table-name"
            type="text"
            placeholder={t('Table Name')}
          />
        </FormItem>

        <FormItem
          label={t('GeoJSON upload')}
          help={t('Select a file to uploaded to the database')}
          name="file"
          rules={[{ required: true, message: t('GeoJson File is required') }]}
        >
          <UploadButton
            accept="application/geo+json"
            fileList={fileList}
            setFileList={setFileList}
          />
        </FormItem>

        <FormItem
          label={t('Database')}
          help={t('Select a database to upload the file')}
          name="database"
          rules={[
            { required: true, message: t('Database Exists is required') },
          ]}
        >
          <Select placeholder="Database" options={databases} />
        </FormItem>

        <FormItem
          label={t('Schema')}
          help={t('Specify the schema if it is supported by the database')}
          name="schema"
          rules={[{ required: false, message: t('Schema name is required') }]}
        >
          <Input
            data-test="upload-geojson-table-name"
            type="text"
            placeholder={t('Schema')}
          />
        </FormItem>

        <FormItem
          label={t('Table exists')}
          help={t('What should happen if the table already exists')}
          name="already_exists"
          rules={[{ required: true, message: t('Table exists is required') }]}
        >
          <Select options={getTableExistOptions(t)} />
        </FormItem>
      </AntdForm>
    </Curtain>
  );
};
