import React from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { FieldPropTypes } from 'src/features/databases/types';
import { FormItem } from 'src/components/Form/components/FormItem';
import { FormLabel } from 'src/components/Form/components/FormLabel';
import './styles.less';

export const ForceSSLField = ({
  isEditMode,
  changeMethods,
  db,
  isSslForced,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  return (
    <FormItem
      label={
        <FormLabel
          htmlFor="ssl-switch"
          tooltip={{ title: t('SSL Mode "require" will be used.') }}
          withoutMargins
        >
          SSL
        </FormLabel>
      }
      labelAlign="left"
      className="ssl-switch"
    >
      <Switch
        id="ssl-switch"
        data-test="ssl-switch"
        disabled={isSslForced && !isEditMode}
        checked={db?.parameters?.encryption || isSslForced}
        onChange={changed => {
          changeMethods.onParametersChange({
            target: {
              type: 'toggle',
              name: 'encryption',
              checked: true,
              value: changed,
            },
          });
        }}
      />
    </FormItem>
  );
};
