import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Steps } from '../../../../components/Steps';
import { UserWithRoles } from '../../interfaces';
import { scriptConfigs } from './constants';
import { Status } from '../../../../components/Steps/interfaces';
import './styles.less';

/** Блок со сценариями */
export const ScriptBlock: FC<Pick<UserWithRoles, 'roles'>> = memo(
  ({ roles }) => {
    const { t } = useTranslation();

    const stepsItems = useMemo(
      () =>
        scriptConfigs(t, roles).map(({ link, ...step }) =>
          link && step.status !== Status.wait
            ? {
                ...step,
                title: (
                  <Link className="script-block__link" to={link}>
                    {step.title}
                  </Link>
                ),
              }
            : step,
        ),
      [roles, t],
    );

    return (
      <div className="script-block">
        <h1 className="script-block__title">
          {t('Typical user workflow scenario')}
        </h1>
        <Steps items={stepsItems} />
      </div>
    );
  },
);
