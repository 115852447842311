import React from 'react';
import './styles.less';
import { IDatasetPanelProps, ITableColumn } from './types';
import { ChooseDatasetStub } from './components/ChooseDatasetStub';
import { MemoTable } from '../../../../newComponents/Table';
import { ExistingDatasetAlert } from './components/ExistingDatasetAlert';
import { pageSizeOptions, tableColumnDefinition } from './constants';

const DatasetPanel = ({
  tableName,
  columnList,
  isLoading,
  hasError,
  datasets,
}: IDatasetPanelProps) => {
  const hasColumns = columnList?.length > 0 ?? false;
  const datasetNames = datasets?.map(dataset => dataset.table_name);
  const tableWithDataset = datasets?.find(
    dataset => dataset.table_name === tableName,
  );
  const scrollYHeight = tableWithDataset
    ? 'calc(100vh - 590px)'
    : 'calc(100vh - 450px)';

  return (
    <>
      {tableName && (
        <>{datasetNames?.includes(tableName) && <ExistingDatasetAlert />}</>
      )}
      {tableName && hasColumns && !hasError ? (
        <div className="dataset-table-wrapper">
          <MemoTable<ITableColumn>
            data={columnList}
            columns={tableColumnDefinition}
            rowKey="name"
            isLoading={isLoading}
            total={columnList.length}
            pagination={{ pageSizeOptions }}
            scrollYHeight={scrollYHeight}
          />
        </div>
      ) : (
        <ChooseDatasetStub />
      )}
    </>
  );
};

export default DatasetPanel;
