import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import DocsSVG from '../../../../assets/images/docs.svg';
import { StyledDocsButton } from '../../styled';
import { RootState } from '../../../../dashboard/types';
import { DOCUMENTATION_LAST_RELEASE_ROUTE } from '../../../../views/routes';

export const Docs = memo(() => {
  const goToReleaseNotes = () =>
    window.open(DOCUMENTATION_LAST_RELEASE_ROUTE, '_blank');

  const { t } = useTranslation();
  const { isNeedCheckDocumentationRelease } = useSelector(
    (store: RootState) => store.release,
  );

  const title = isNeedCheckDocumentationRelease
    ? "Documentation (you haven't checked out the latest release)"
    : 'Documentation';

  return (
    <Tooltip title={t(title)} placement="bottom">
      <StyledDocsButton
        type="text"
        shape="circle"
        icon={<DocsSVG />}
        onClick={goToReleaseNotes}
        size="small"
        className="docs"
        isNeedCheckDocumentationRelease={isNeedCheckDocumentationRelease}
      />
    </Tooltip>
  );
});
