import { UploadChangeParam } from 'antd/lib/upload/interface';
import { TABLE_EXIST } from '../UploadDataExcelCurtain/constants';

export enum DELIMITER {
  DOT = '.',
  COMMA = ',',
  OTHER = 'Other',
}

export type UploadDataCsvCurtainProps = {
  isOpen: boolean;
  onHide: () => void;
};

export type FormValuesCsvUpload = {
  table_name: string;
  file: UploadChangeParam;
  database: string;
  delimiter: DELIMITER;
  already_exists: TABLE_EXIST;
  schema?: string;
  skip_initial_space?: boolean;
  skip_blank_lines?: boolean;
  column_dates?: string;
  day_first?: boolean;
  decimal_character?: string;
  null_values?: string;
  index_column?: string;
  dataframe_index?: boolean;
  index_label?: string;
  columns_read?: string;
  column_data_types?: string;
  header_row?: string;
  rows_to_read?: string;
  skip_rows?: string;
};
