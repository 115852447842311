import React, { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'src/newComponents/Checkbox';
import { Input } from 'src/newComponents/Input';
import Collapse from 'src/components/Collapse';
import { JsonEditor } from 'src/components/AsyncAceEditor';
import { FormItem } from 'src/components/Form/components/FormItem';
import { InputNumber } from 'src/newComponents/InputNumber';
import { TextArea } from 'src/newComponents/TextArea';
import { CACHE_TIMEOUT, ExtraJson } from '../../../types';
import { ExtraOptionsProps } from './interfaces';
import './styles.less';

export const ExtraOptions: FC<ExtraOptionsProps> = props => {
  const {
    db,
    onInputChange,
    onInputNumberChange,
    onCheckboxChange,
    onExtraCheckboxChange,
    onTextChange,
    onEditorChange,
    onExtraInputChange,
    onExtraEditorChange,
    extraExtension,
  } = props;
  const { t } = useTranslation();
  const isExpandable = !!db?.expose_in_sqllab;
  const createAsOpen = !!(db?.allow_ctas || db?.allow_cvas);
  const isFileUploadSupportedByEngine =
    db?.engine_information?.supports_file_upload;

  // JSON.parse will deep parse engine_params
  // if it's an object, and we want to keep it a string
  const extraJson: ExtraJson = JSON.parse(db?.extra || '{}', (key, value) => {
    if (key === 'engine_params' && typeof value === 'object') {
      // keep this as a string
      return JSON.stringify(value);
    }
    return value;
  });

  const ExtraExtensionComponent = extraExtension?.component;
  const ExtraExtensionLogo = extraExtension?.logo;
  const ExtensionDescription = extraExtension?.description;

  return (
    <Collapse
      expandIconPosition="right"
      accordion
      bordered={false}
      className="extra-options-collapse"
    >
      <Collapse.Panel
        header={
          <>
            <h4 className="extra-options-collapse__title">{t('SQL Lab')}</h4>
            <p className="extra-options-collapse__subtitle">
              {t('Adjust how this database will interact with SQL Lab.')}
            </p>
          </>
        }
        key="1"
      >
        <>
          <Checkbox
            containerClassName={cx({
              'extra-options-collapse__checkbox': isExpandable,
            })}
            name="expose_in_sqllab"
            id="expose_in_sqllab"
            indeterminate={false}
            checked={!!db?.expose_in_sqllab}
            onChange={onCheckboxChange}
            label={t('Expose database in SQL Lab')}
            tooltip={{
              title: t('Allow this database to be queried in SQL Lab'),
            }}
          />
          <div
            className={cx('extra-options-collapse__expandable', {
              'extra-options-collapse--open': isExpandable,
            })}
          >
            <Checkbox
              containerClassName="extra-options-collapse__checkbox"
              name="allow_ctas"
              id="allow_ctas"
              indeterminate={false}
              checked={!!db?.allow_ctas}
              onChange={onCheckboxChange}
              label={t('Allow CREATE TABLE AS')}
              tooltip={{
                title: t('Allow creation of new tables based on queries'),
              }}
            />
            <Checkbox
              containerClassName="extra-options-collapse__checkbox"
              name="allow_cvas"
              id="allow_cvas"
              indeterminate={false}
              checked={!!db?.allow_cvas}
              onChange={onCheckboxChange}
              label={t('Allow CREATE VIEW AS')}
              tooltip={{
                title: t('Allow creation of new views based on queries'),
              }}
            />
            <div
              className={cx('extra-options-collapse__expandable', {
                'extra-options-collapse--open': createAsOpen,
              })}
            >
              <FormItem
                htmlFor="force_ctas_schema"
                label={t('CTAS & CVAS SCHEMA')}
                help={t(
                  'Force all tables and views to be created in this schema when clicking CTAS or CVAS in SQL Lab.',
                )}
              >
                <Input
                  id="force_ctas_schema"
                  name="force_ctas_schema"
                  placeholder={t('Create or select schema...')}
                  onChange={onInputChange}
                  value={db?.force_ctas_schema || ''}
                />
              </FormItem>
            </div>
            <Checkbox
              containerClassName="extra-options-collapse__checkbox"
              name="allow_dml"
              id="allow_dml"
              indeterminate={false}
              checked={!!db?.allow_dml}
              onChange={onCheckboxChange}
              label={t('Allow DML')}
              tooltip={{
                title: t(
                  'Allow manipulation of the database using non-SELECT statements such as UPDATE, DELETE, CREATE, etc.',
                ),
              }}
            />
            <Checkbox
              containerClassName="extra-options-collapse__checkbox"
              name="cost_estimate_enabled"
              id="cost_estimate_enabled"
              indeterminate={false}
              checked={!!extraJson?.cost_estimate_enabled}
              onChange={onExtraCheckboxChange}
              label={t('Enable query cost estimation')}
              tooltip={{
                title: t(
                  'For Bigquery, Presto and Postgres, shows a button to compute cost before running a query.',
                ),
              }}
            />
            <Checkbox
              containerClassName="extra-options-collapse__checkbox"
              name="allows_virtual_table_explore"
              id="allows_virtual_table_explore"
              indeterminate={false}
              checked={!!extraJson?.allows_virtual_table_explore}
              onChange={onExtraCheckboxChange}
              label={t('Allow this database to be explored')}
              tooltip={{
                title: t(
                  'When enabled, users are able to visualize SQL Lab results in Explore.',
                ),
              }}
            />
            <Checkbox
              containerClassName="extra-options-collapse__checkbox"
              name="disable_data_preview"
              id="disable_data_preview"
              indeterminate={false}
              checked={!!extraJson?.disable_data_preview}
              onChange={onExtraCheckboxChange}
              label={t('Disable SQL Lab data preview queries')}
              tooltip={{
                title: t(
                  'Disable data preview when fetching table metadata in SQL Lab. ' +
                    ' Useful to avoid browser performance issues when using ' +
                    ' databases with very wide tables.',
                ),
              }}
            />
            <Checkbox
              name="expand_rows"
              id="expand_rows"
              indeterminate={false}
              checked={!!extraJson?.schema_options?.expand_rows}
              onChange={onExtraCheckboxChange}
              label={t('Enable row expansion in schemas')}
              tooltip={{
                title: t(
                  'For Trino, describe full schemas of nested ROW types, expanding them with dotted paths',
                ),
              }}
            />
          </div>
        </>
      </Collapse.Panel>
      <Collapse.Panel
        header={
          <>
            <h4 className="extra-options-collapse__title">
              {t('Performance')}
            </h4>
            <p className="extra-options-collapse__subtitle">
              {t('Adjust performance settings of this database')}
            </p>
          </>
        }
        key="2"
      >
        <FormItem
          htmlFor={CACHE_TIMEOUT}
          label={t('Chart cache timeout')}
          help={t(
            'Duration (in seconds) of the caching timeout for charts of this database. A timeout of 0 indicates that the cache never expires. Note this defaults to the global timeout if undefined',
          )}
        >
          <InputNumber
            id={CACHE_TIMEOUT}
            name={CACHE_TIMEOUT}
            placeholder={t('Enter duration in seconds')}
            onChange={value => onInputNumberChange(value, CACHE_TIMEOUT)}
            value={db?.cache_timeout || null}
          />
        </FormItem>
        <FormItem
          htmlFor="schema_cache_timeout"
          label={t('Schema cache timeout')}
          help={t(
            'Duration (in seconds) of the metadata caching timeout for schemas of ' +
              'this database. If left unset, the cache never expires.',
          )}
        >
          <InputNumber
            id="schema_cache_timeout"
            name="schema_cache_timeout"
            onChange={value =>
              onInputNumberChange(value, 'schema_cache_timeout')
            }
            value={
              extraJson?.metadata_cache_timeout?.schema_cache_timeout || null
            }
            placeholder={t('Enter duration in seconds')}
            dataTestId="schema-cache-timeout-test"
          />
        </FormItem>
        <FormItem
          htmlFor="table_cache_timeout"
          label={t('Table cache timeout')}
          help={t(
            'Duration (in seconds) of the metadata caching timeout for tables of ' +
              'this database. If left unset, the cache never expires.',
          )}
        >
          <InputNumber
            id="table_cache_timeout"
            name="table_cache_timeout"
            onChange={value =>
              onInputNumberChange(value, 'table_cache_timeout')
            }
            value={
              extraJson?.metadata_cache_timeout?.table_cache_timeout || null
            }
            placeholder={t('Enter duration in seconds')}
            dataTestId="table-cache-timeout-test"
          />
        </FormItem>
        <Checkbox
          containerClassName="extra-options-collapse__checkbox"
          name="allow_run_async"
          id="allow_run_async"
          indeterminate={false}
          checked={!!db?.allow_run_async}
          onChange={onCheckboxChange}
          label={t('Asynchronous query execution')}
          tooltip={{
            title: t(
              'Operate the database in asynchronous mode, meaning that the queries ' +
                'are executed on remote workers as opposed to on the web server itself. ' +
                'This assumes that you have a Celery worker setup as well as a results ' +
                'backend. Refer to the installation docs for more information.',
            ),
          }}
        />
        <Checkbox
          name="cancel_query_on_windows_unload"
          id="cancel_query_on_windows_unload"
          indeterminate={false}
          checked={!!extraJson?.cancel_query_on_windows_unload}
          onChange={onExtraCheckboxChange}
          label={t('Cancel query on window unload event')}
          tooltip={{
            title: t(
              'Terminate running queries when browser window closed or navigated ' +
                'to another page. Available for Presto, Hive, MySQL, Postgres and ' +
                'Snowflake databases.',
            ),
          }}
        />
      </Collapse.Panel>
      <Collapse.Panel
        header={
          <>
            <h4 className="extra-options-collapse__title">{t('Security')}</h4>
            <p className="extra-options-collapse__subtitle">
              {t('Add extra connection information.')}
            </p>
          </>
        }
        key="3"
      >
        <FormItem
          label={t('Secure extra')}
          help={t(
            'JSON string containing additional connection configuration. ' +
              'This is used to provide connection information for systems ' +
              'like Hive, Presto and BigQuery which do not conform to the ' +
              'username:password syntax normally used by SQLAlchemy.',
          )}
        >
          <JsonEditor
            name="masked_encrypted_extra"
            value={db?.masked_encrypted_extra || ''}
            placeholder={t('Secure extra')}
            onChange={(json: string) =>
              onEditorChange({ json, name: 'masked_encrypted_extra' })
            }
            width="100%"
            height="160px"
          />
        </FormItem>
        <FormItem
          htmlFor="server_cert"
          label={t('Root certificate')}
          help={t(
            'Optional CA_BUNDLE contents to validate HTTPS requests. Only ' +
              'available on certain database engines.',
          )}
        >
          <TextArea
            name="server_cert"
            id="server_cert"
            value={db?.server_cert || ''}
            placeholder={t('Enter CA_BUNDLE')}
            onChange={onTextChange}
          />
        </FormItem>
        <Checkbox
          containerClassName="extra-options-collapse__checkbox extra-options-collapse--large-checkbox"
          name="impersonate_user"
          id="impersonate_user"
          indeterminate={false}
          checked={!!db?.impersonate_user}
          onChange={onCheckboxChange}
          label={t(
            'Impersonate logged in user (Presto, Trino, Drill, Hive, and GSheets)',
          )}
          tooltip={{
            title: t(
              'If Presto or Trino, all the queries in SQL Lab are going to be executed as the ' +
                'currently logged on user who must have permission to run them. If Hive ' +
                'and hive.server2.enable.doAs is enabled, will run the queries as ' +
                'service account, but impersonate the currently logged on user via ' +
                'hive.server2.proxy.user property.',
            ),
          }}
        />
        {isFileUploadSupportedByEngine && (
          <Checkbox
            name="allow_file_upload"
            id="allow_file_upload"
            indeterminate={false}
            checked={!!db?.allow_file_upload}
            onChange={onCheckboxChange}
            label={t('Allow file uploads to database')}
          />
        )}
        {isFileUploadSupportedByEngine && !!db?.allow_file_upload && (
          <FormItem
            htmlFor="schemas_allowed_for_file_upload"
            label={t('Schemas allowed for File upload')}
            help={t(
              'A comma-separated list of schemas that files are allowed to upload to.',
            )}
          >
            <Input
              id="schemas_allowed_for_file_upload"
              name="schemas_allowed_for_file_upload"
              placeholder="schema1,schema2"
              onChange={onExtraInputChange}
              value={(extraJson?.schemas_allowed_for_file_upload || []).join(
                ',',
              )}
            />
          </FormItem>
        )}
      </Collapse.Panel>
      {extraExtension && ExtraExtensionComponent && ExtensionDescription && (
        <Collapse.Panel
          header={
            <>
              {ExtraExtensionLogo && <ExtraExtensionLogo />}
              <h4 className="extra-options-collapse__title">
                {extraExtension?.title}
              </h4>
              <p className="extra-options-collapse__subtitle">
                <ExtensionDescription />
              </p>
            </>
          }
          key={extraExtension?.title}
          // @ts-ignore, 'icon' is valid in >=4.9.0 but missing from `CollapsibleType`
          collapsible={extraExtension.enabled?.() ? 'icon' : 'disabled'}
        >
          <ExtraExtensionComponent db={db} onEdit={extraExtension.onEdit} />
        </Collapse.Panel>
      )}
      <Collapse.Panel
        header={
          <>
            <h4 className="extra-options-collapse__title">{t('Other')}</h4>
            <p className="extra-options-collapse__subtitle">
              {t('Additional settings.')}
            </p>
          </>
        }
        key="4"
      >
        <FormItem
          label={t('Metadata Parameters')}
          help={t(
            'The metadata_params object gets unpacked into the sqlalchemy.MetaData call.',
          )}
        >
          <JsonEditor
            name="metadata_params"
            placeholder={t('Metadata Parameters')}
            onChange={(json: string) =>
              onExtraEditorChange({ json, name: 'metadata_params' })
            }
            width="100%"
            height="160px"
            value={
              !Object.keys(extraJson?.metadata_params || {}).length
                ? ''
                : extraJson?.metadata_params
            }
          />
        </FormItem>
        <FormItem
          label={t('Engine Parameters')}
          help={t(
            'The engine_params object gets unpacked into the sqlalchemy.create_engine call.',
          )}
        >
          <JsonEditor
            name="engine_params"
            placeholder={t('Engine Parameters')}
            onChange={(json: string) =>
              onExtraEditorChange({ json, name: 'engine_params' })
            }
            width="100%"
            height="160px"
            value={
              !Object.keys(extraJson?.engine_params || {}).length
                ? ''
                : extraJson?.engine_params
            }
          />
        </FormItem>
        <FormItem
          htmlFor="version"
          label={t('Version')}
          help={t(
            'Specify the database version. This is used with Presto for query cost ' +
              'estimation, and Dremio for syntax changes, among others.',
          )}
        >
          <Input
            id="version"
            name="version"
            placeholder={t('Version number')}
            onChange={onExtraInputChange}
            value={extraJson?.version || ''}
          />
        </FormItem>
      </Collapse.Panel>
    </Collapse>
  );
};
