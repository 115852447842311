import { UserOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React, { FC, memo } from 'react';
import { DropdownMenu } from './components/DropdownMenu';
import { AvatarProps } from './interfaces';
import { StyledAvatar } from '../../styled';

/** Аватар в хэдере */
export const Avatar: FC<AvatarProps> = memo(props => {
  const { user } = props;
  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      dropdownRender={() => (user ? <DropdownMenu user={user} /> : <></>)}
    >
      <StyledAvatar icon={<UserOutlined />} className="avatar" />
    </Dropdown>
  );
});
