import { ItemWithChildren } from '../../interfaces';

export const mappedMenu = ({
  label,
  onClick,
  key,
  disable,
  children,
}: ItemWithChildren) => ({
  label,
  onClick,
  key,
  disable,
  children,
});
