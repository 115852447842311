import React, { FC, memo } from 'react';
import Label from 'src/components/Label';
import { SelectLabelProps } from './interfaces';
import './styles.less';

export const SelectLabel: FC<SelectLabelProps> = memo(props => {
  const { databaseName, databaseLabel } = props;
  return (
    <div className="select-label">
      {databaseName && (
        <Label className="select-label__tag">{databaseName}</Label>
      )}
      <span className="select-label_title" title={databaseLabel}>
        {databaseLabel}
      </span>
    </div>
  );
});
