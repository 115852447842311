import React, { ComponentType, useCallback, useState } from 'react';
import { CreateDatasetCurtain } from '../index';
import { WithCreateDatasetCurtainProps } from '../interfaces';

export function withDatasetCreationCurtain<T>(
  BaseComponent: ComponentType<T & WithCreateDatasetCurtainProps>,
) {
  return function ComponentWithChartCreationCurtain(props: T) {
    const [isCreateDatasetCurtainOpen, setIsCreateDatasetCurtainOpen] =
      useState(false);

    const handleCloseCreateDatasetCurtain = useCallback(() => {
      setIsCreateDatasetCurtainOpen(false);
    }, []);

    return (
      <>
        {isCreateDatasetCurtainOpen && (
          <CreateDatasetCurtain
            isOpen={isCreateDatasetCurtainOpen}
            handleCloseDatasetCreate={handleCloseCreateDatasetCurtain}
          />
        )}
        <BaseComponent
          {...props}
          setIsOpenDatasetCreationCurtain={setIsCreateDatasetCurtainOpen}
        />
      </>
    );
  };
}
