import { SupersetClient } from '@superset-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReleaseUpdate } from '../../redux/actions/releaseActions';
import { RootState } from '../../../../dashboard/types';
import { getVersionNumber } from '../../../../utils/getVersionNumber';
import { NEXT_VERSION_RELEASE } from '../../constants';
import { ReleaseModalProps } from '../../interfaces';

type UseReleaseInformationProps = ReleaseModalProps['userId'];

type UpdatePreReleaseInformationProps = {
  /** Просматривал ли пользователь модальное окно с пре-релизом */
  isCheckedModal?: boolean;
  /** Просматривал ли пользователь документацию релиза */
  isCheckedDocumentation?: boolean;
};

export type UseReleaseInformationResult = {
  /** Закрытие модального окна пре-релиза */
  closeModal: () => void;
  /** Показывать ли модальное окно пре-релиза */
  isShowModal: boolean;
  /** Обновление состояния ознакомления пользователя с релизом:
   * Модальное окно пре-релиза
   * Документация релиза
   */
  updatePreReleaseInformation: ({
    isCheckedModal,
    isCheckedDocumentation,
  }: UpdatePreReleaseInformationProps) => void;
  getPreReleaseInformation: () => void;
};

/** Хук обработки информирования пользователя о релизе */
export const useReleaseInformation = (
  userId: UseReleaseInformationProps,
): UseReleaseInformationResult => {
  const [isShowModal, setIsShowModal] = useState(false);
  const dispatch = useDispatch();
  const appVersion = useSelector((state: RootState) => state.appVersion);
  const numberVersion = getVersionNumber(appVersion);

  const getPreReleaseInformation = useCallback(() => {
    if (numberVersion && userId) {
      SupersetClient.get({
        endpoint: `/dataopsbidocs/release_preview/${numberVersion}/user/${userId}`,
      })
        .then(({ json }) => {
          if (!json.preview_viewed) {
            // TODO: проверка на дурака, чтобы не отображать модалку с инфой пре-прелиза, в случае, если забыли сменить текстовки!
            if (NEXT_VERSION_RELEASE.includes(numberVersion)) {
              setIsShowModal(true);
            }
          }
          if (!json.docs_viewed) {
            dispatch(setReleaseUpdate(!json.docs_viewed));
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [numberVersion, dispatch, userId]);

  useEffect(() => {
    getPreReleaseInformation();
  }, [appVersion, getPreReleaseInformation, userId]);

  const updatePreReleaseInformation = useCallback(
    ({
      isCheckedModal,
      isCheckedDocumentation,
    }: UpdatePreReleaseInformationProps) => {
      if (numberVersion && userId) {
        const body: { preview_viewed?: boolean; docs_viewed?: boolean } = {};
        if (isCheckedModal) {
          body.preview_viewed = true;
        }
        if (isCheckedDocumentation) {
          body.docs_viewed = true;
        }
        SupersetClient.put({
          endpoint: `/dataopsbidocs/release_preview/${numberVersion}/user/${userId}`,
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' },
        })
          .then(() => {
            if (isCheckedDocumentation) {
              dispatch(setReleaseUpdate(false));
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    [numberVersion, dispatch, userId],
  );

  const closeModal = () => {
    setIsShowModal(false);
    updatePreReleaseInformation({ isCheckedModal: true });
  };

  return {
    updatePreReleaseInformation,
    isShowModal,
    closeModal,
    getPreReleaseInformation,
  };
};
