import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/newComponents/Button';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';
import Icons from 'src/components/Icons';
import { CatalogObject, FieldPropTypes } from 'src/features/databases/types';
import { FormLabel } from 'src/components/Form/components/FormLabel';

export const TableCatalog = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  db,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  const tableCatalog = db?.catalog || [];
  const catalogError = validationErrors || {};
  return (
    <div>
      <h4 className="gsheet-title">
        {t('Connect Google Sheets as tables to this database')}
      </h4>
      <div>
        {tableCatalog?.map((sheet: CatalogObject, idx: number) => (
          <>
            <FormLabel className="catalog-label" isRequired>
              {t('Google Sheet Name and URL')}
            </FormLabel>
            <div className="catalog-name">
              <LabeledErrorBoundInput
                className="catalog-name-input"
                isRequired={isRequired}
                validationMethods={{ onBlur: getValidation }}
                errorMessage={catalogError[idx]?.name}
                placeholder={t('Enter a name for this sheet')}
                onChange={(e: { target: { value: any } }) => {
                  changeMethods.onParametersChange({
                    target: {
                      type: `catalog-${idx}`,
                      name: 'name',
                      value: e.target.value,
                    },
                  });
                }}
                value={sheet.name}
              />
              {tableCatalog?.length > 1 && (
                <Icons.CloseOutlined
                  iconSize="m"
                  onClick={() => changeMethods.onRemoveTableCatalog(idx)}
                />
              )}
            </div>
            <LabeledErrorBoundInput
              isRequired={isRequired}
              validationMethods={{ onBlur: getValidation }}
              errorMessage={catalogError[idx]?.url}
              placeholder={t('Paste the shareable Google Sheet URL here')}
              onChange={(e: { target: { value: any } }) =>
                changeMethods.onParametersChange({
                  target: {
                    type: `catalog-${idx}`,
                    name: 'value',
                    value: e.target.value,
                  },
                })
              }
              value={sheet.value}
            />
          </>
        ))}
        <Button
          onClick={() => {
            changeMethods.onAddTableCatalog();
          }}
        >
          + {t('Add sheet')}
        </Button>
      </div>
    </div>
  );
};
