import React, { FC, memo, useEffect } from 'react';
import { Modal } from 'src/newComponents/Modal';
import { useSelector } from 'react-redux';
import { useReleaseInformation } from './hooks/useReleaseInformation';
import { Title } from './components/Title';
import { ReleaseContent } from './components/ReleaseContent';
import { ReleaseModalProps } from './interfaces';
import { RootState } from '../../dashboard/types';

/** Модальное окно пре-релиза */
export const ReleaseModal: FC<ReleaseModalProps> = memo(props => {
  const { userId } = props;
  const { isShowModal, closeModal, getPreReleaseInformation } =
    useReleaseInformation(userId);
  const appVersion = useSelector((state: RootState) => state.appVersion);

  useEffect(() => {
    getPreReleaseInformation();
  }, [appVersion, getPreReleaseInformation, userId]);

  return (
    <Modal
      title={<Title />}
      isOpen={isShowModal}
      onCancel={closeModal}
      footer={null}
    >
      <ReleaseContent closeModal={closeModal} />
    </Modal>
  );
});
