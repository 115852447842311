import React, { FC, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { styled } from '@superset-ui/core';
import rison from 'rison';
import { Curtain } from '../../../components/Curtain';
import { BI_PORTAL_ID } from '../../../constants';
import { AntdForm } from '../../../components';
import { FormItem } from '../../../components/Form';
import { Input, TextArea } from '../../../components/Input';
import AsyncSelect from '../../../components/Select/AsyncSelect';
import { getUserListOptions } from '../../../explore/utils/getUserListOptions';
import { FormValues } from '../../../components/FolderModal/interfaces';
import { getFolderParentIdFromFullIds } from '../../../components/FolderModal/utils/getFolderParentIdFromFullIds';
import { formattingForSelect } from '../../../components/FolderModal/utils/formattingForSelect';
import Owner from '../../../types/Owner';
import { useFolder } from '../../../dashboard/hooks/useFolder';
import { FolderCurtainProps } from './interfaces';
import Loading from '../../../components/Loading';
import './styles.less';

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0;

  .ant-form-item-label > label {
    text-transform: unset;
  }
`;

/** Шторка создания / редактирования папки */
export const FolderCurtain: FC<FolderCurtainProps> = memo(props => {
  const { isOpenCurtain, hideCurtain, folder, folderIds } = props;
  const [form] = AntdForm.useForm<FormValues>();
  const { t } = useTranslation();
  const { createNewFolder, updateCurrentFolder, isLoadingFolders } =
    useFolder();

  useEffect(() => {
    form.resetFields();
  }, [isOpenCurtain]);

  const title = folder ? t('Edit folder') : t('Create folder');

  const loadAccessOptions = useCallback(
    (accessType = 'owners', input = '', page: number, pageSize: number) => {
      const query = rison.encode({
        filter: input,
        page,
        page_size: pageSize,
      });

      return getUserListOptions({ accessType, query });
    },
    [],
  );

  useEffect(() => {
    if (folder) {
      const keys = Object.keys(folder);
      const data = keys.map(key => {
        let value = folder?.[key];
        if (key === 'owners' || key === 'has_access') {
          value = formattingForSelect(folder?.[key] as Array<Owner>);
        }
        return { name: key, value };
      });
      form.setFields(data);
    }
  }, [form, folder]);

  const createFolder = (values: FormValues) => {
    const newFolder = { ...values };
    if (folderIds) {
      newFolder.parent_id = getFolderParentIdFromFullIds(folderIds);
    }
    createNewFolder(newFolder, hideCurtain);
  };

  const handleFinish = (values: FormValues) =>
    folder
      ? updateCurrentFolder({ ...values, id: folder.id }, hideCurtain)
      : createFolder(values);

  return (
    <Curtain
      portalId={BI_PORTAL_ID}
      isOpen={isOpenCurtain}
      onClose={hideCurtain}
      containerClassName="folder-curtain"
      childrenClassName="curtain--flex-1"
      title={title}
      footer={
        <>
          <Button
            onClick={hideCurtain}
            data-test="properties-modal-cancel-button"
            className="curtain--mr-8 curtain--border-rad-6"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={form.submit}
            type="primary"
            data-test="properties-modal-apply-button"
            className="curtain--border-rad-6"
            disabled={isLoadingFolders}
          >
            {isLoadingFolders ? <Loading position="inline" /> : t('Save')}
          </Button>
        </>
      }
    >
      <AntdForm
        form={form}
        onFinish={handleFinish}
        data-test="folder-curtain-form"
        layout="vertical"
        className="curtain"
      >
        <div>
          <p className="curtain__block-title">{t('Basic information')}</p>

          <FormItem
            label={t('Title')}
            name="name"
            className="curtain__form-item"
            rules={[{ required: true, message: t('Title is required') }]}
          >
            <Input
              data-test="folder-title-input"
              type="text"
              maxLength={30}
              placeholder={t('Name the folder')}
            />
          </FormItem>
          <StyledFormItem label={t('Description')} name="description">
            <TextArea maxLength={300} placeholder={t('Describe the folder')} />
          </StyledFormItem>
        </div>
        <div>
          <p className="curtain__block-title curtain--mt-20">{t('Access')}</p>

          <FormItem
            label={t('Owners')}
            name="owners"
            className="curtain__form-item"
          >
            <AsyncSelect
              allowClear
              data-test="folder-owners-input"
              ariaLabel={t('Owners')}
              mode="multiple"
              placeholder={t('Select owner')}
              options={(input, page, pageSize) =>
                loadAccessOptions('owners', input, page, pageSize)
              }
            />
          </FormItem>
          <StyledFormItem label={t('Available for')} name="has_access">
            <AsyncSelect
              allowClear
              ariaLabel={t('Available for')}
              mode="multiple"
              placeholder={t('Select approved by')}
              options={(input, page, pageSize) =>
                loadAccessOptions('owners', input, page, pageSize)
              }
            />
          </StyledFormItem>
        </div>
      </AntdForm>
    </Curtain>
  );
});
