import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'src/components/Form/components/FormLabel';
import { Button as AntdButton, Select as AntdSelect } from 'antd';
import Icons from 'src/components/Icons';
import { FieldPropTypes } from '../../../../../types';
import { CredentialInfoOptions } from './interfaces';
import { encryptedCredentialsMap } from './constants';
import { InfoTooltip } from '../../../../../../../newComponents/InfoTooltip';

export const EncryptedField = ({
  changeMethods,
  isEditMode,
  db,
  isEditNewDb,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  const [uploadOption, setUploadOption] = useState<number>(
    CredentialInfoOptions.JsonUpload.valueOf(),
  );
  const [fileToUpload, setFileToUpload] = useState<string | null | undefined>(
    null,
  );
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const showCredentialsInfo =
    db?.engine === 'gsheets' ? !isEditMode && !isPublic : !isEditMode;
  const isEncrypted = isEditMode && db?.masked_encrypted_extra !== '{}';
  const encryptedField = db?.engine && encryptedCredentialsMap[db.engine];
  const encryptedValue =
    typeof db?.parameters?.[encryptedField] === 'object'
      ? JSON.stringify(db?.parameters?.[encryptedField])
      : db?.parameters?.[encryptedField];

  return (
    <div>
      {db?.engine === 'gsheets' && (
        <div className="catalog-type-select">
          <FormLabel isRequired>{t('Type of Google Sheets allowed')}</FormLabel>
          <AntdSelect
            style={{ width: '100%' }}
            defaultValue={isEncrypted ? 'false' : 'true'}
            onChange={(value: string) => setIsPublic(value === 'true')}
          >
            <AntdSelect.Option value="true" key={1}>
              {t('Publicly shared sheets only')}
            </AntdSelect.Option>
            <AntdSelect.Option value="false" key={2}>
              {t('Public and privately shared sheets')}
            </AntdSelect.Option>
          </AntdSelect>
        </div>
      )}
      {showCredentialsInfo && (
        <>
          <FormLabel isRequired>
            {t('How do you want to enter service account credentials?')}
          </FormLabel>
          <AntdSelect
            defaultValue={uploadOption}
            style={{ width: '100%' }}
            onChange={option => setUploadOption(option)}
          >
            <AntdSelect.Option value={CredentialInfoOptions.JsonUpload}>
              {t('Upload JSON file')}
            </AntdSelect.Option>

            <AntdSelect.Option value={CredentialInfoOptions.CopyPaste}>
              {t('Copy and Paste JSON credentials')}
            </AntdSelect.Option>
          </AntdSelect>
        </>
      )}
      {uploadOption === CredentialInfoOptions.CopyPaste ||
      isEditMode ||
      isEditNewDb ? (
        <div className="input-container">
          <FormLabel isRequired>{t('Service Account')}</FormLabel>
          <textarea
            className="input-form"
            name={encryptedField}
            value={encryptedValue}
            onChange={changeMethods.onParametersChange}
            placeholder={t(
              'Paste content of service credentials JSON file here',
            )}
          />
          <span className="label-paste">
            {t('Copy and paste the entire service account .json file here')}
          </span>
        </div>
      ) : (
        showCredentialsInfo && (
          <div className="input-container">
            <div>
              <FormLabel isRequired>{t('Upload Credentials')}</FormLabel>
              <InfoTooltip
                title={t(
                  'Use the JSON file you automatically downloaded when creating your service account.',
                )}
              />
            </div>

            {!fileToUpload && (
              <AntdButton
                className="input-upload-btn"
                onClick={() =>
                  document?.getElementById('selectedFile')?.click()
                }
              >
                {t('Choose File')}
              </AntdButton>
            )}
            {fileToUpload && (
              <div className="input-upload-current">
                {fileToUpload}
                <Icons.DeleteFilled
                  iconSize="m"
                  onClick={() => {
                    setFileToUpload(null);
                    changeMethods.onParametersChange({
                      target: {
                        name: encryptedField,
                        value: '',
                      },
                    });
                  }}
                />
              </div>
            )}

            <input
              id="selectedFile"
              accept=".json"
              className="input-upload"
              type="file"
              onChange={async event => {
                let file;
                if (event.target.files) {
                  file = event.target.files[0];
                }
                setFileToUpload(file?.name);
                changeMethods.onParametersChange({
                  target: {
                    type: null,
                    name: encryptedField,
                    value: await file?.text(),
                    checked: false,
                  },
                });
                (
                  document.getElementById('selectedFile') as HTMLInputElement
                ).value = null as any;
              }}
            />
          </div>
        )
      )}
    </div>
  );
};
