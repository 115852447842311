import React, { FC, memo } from 'react';
import { Tooltip } from '../../../../components/Tooltip';
import { LogoProps } from './interfaces';
import { StyledLogo, StyledLogoSvg, StyledLogoTitle } from '../../styled';

/** Логотип в хэдере */
export const Logo: FC<LogoProps> = memo(props => {
  const { brand, needOldRedirect } = props;

  // TODO: delete https://jira.mts.ru/browse/DOP-21727
  const redirect = () => {
    if (needOldRedirect) {
      window.open(brand.path, '_self');
    }
  };

  return (
    <Tooltip
      id="brand-tooltip"
      placement="bottomLeft"
      title={brand.tooltip}
      arrowPointAtCenter
    >
      <StyledLogo to={brand.path} className="logo" onClick={redirect}>
        <StyledLogoSvg className="logo" />
        <StyledLogoTitle className="logo__title">DATAOPS BI</StyledLogoTitle>
      </StyledLogo>
    </Tooltip>
  );
});
