import React, { FC, memo } from 'react';
import Slider from 'react-slick';
import { settings } from './constants';
import './styles.less';

export const SwipeSlider: FC = memo(props => {
  const { children } = props;

  return <Slider {...settings}>{children}</Slider>;
});
