import { ConfigurationMethod, DatabaseObject } from '../types';
import { WithCreateDatasetCurtainProps } from '../../datasets/CreateDatasetCurtain/interfaces';
import { FetchDataConfig } from '../../../types/interfaces';

export interface DatabaseCurtainProps {
  isOpen: boolean;
  onClose: () => void;
  onDatabaseAdd?: (config?: FetchDataConfig | null) => void;
  databaseId?: number; // If included, will go into edit mode
  dbEngine?: string; // if included goto step 2 with engine already set
}

export type DatabaseCurtainComponentProps = DatabaseCurtainProps &
  WithCreateDatasetCurtainProps & {
    addDangerToast: (message: string) => void;
    addSuccessToast: (message: string) => void;
  };

export enum ActionType {
  AddTableCatalogSheet,
  ConfigMethodChange,
  DbSelected,
  EditorChange,
  ExtraEditorChange,
  ExtraInputChange,
  Fetched,
  InputChange,
  ParametersChange,
  QueryChange,
  RemoveTableCatalogSheet,
  Reset,
  TextChange,
  ParametersSSHTunnelChange,
  SetSSHTunnelLoginMethod,
  RemoveSSHTunnelConfig,
}

export enum AuthType {
  Password,
  PrivateKey,
}

export interface DBReducerPayloadType {
  name: string;
  target?: string;
  json?: string;
  type?: string;
  checked?: boolean;
  value?: string;
}

export type DBReducerActionType =
  | {
      type:
        | ActionType.ExtraEditorChange
        | ActionType.ExtraInputChange
        | ActionType.TextChange
        | ActionType.QueryChange
        | ActionType.InputChange
        | ActionType.EditorChange
        | ActionType.ParametersChange
        | ActionType.ParametersSSHTunnelChange;
      payload: DBReducerPayloadType;
    }
  | {
      type: ActionType.Fetched;
      payload: Partial<DatabaseObject>;
    }
  | {
      type: ActionType.DbSelected;
      payload: {
        configuration_method: ConfigurationMethod;
        database_name?: string;
        engine?: string;
        engine_information?: {};
        driver?: string;
        sqlalchemy_uri_placeholder?: string;
      };
    }
  | {
      type:
        | ActionType.Reset
        | ActionType.RemoveSSHTunnelConfig
        | ActionType.AddTableCatalogSheet;
    }
  | {
      type: ActionType.RemoveTableCatalogSheet;
      payload: {
        indexToDelete: number;
      };
    }
  | {
      type: ActionType.ConfigMethodChange;
      payload: {
        configuration_method: ConfigurationMethod;
        database_name?: string;
        engine?: string;
      };
    }
  | {
      type: ActionType.SetSSHTunnelLoginMethod;
      payload: {
        login_method: AuthType;
      };
    };
