import React, { FC, memo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { DashboardListForModalProps } from './interfaces';

/** Лист дэшбордов для модального окна добавления папки / дэшборда */
export const DashboardListForModal: FC<DashboardListForModalProps> = memo(
  props => {
    const { t } = useTranslation();
    const { dashboards, selectedDashbordsIds, handleSelectDashboard } = props;
    const getIsSelectedDashboard = (id: number) => {
      if (selectedDashbordsIds?.length) {
        return selectedDashbordsIds.includes(id);
      }
      return false;
    };

    return (
      <>
        {dashboards.map(({ dashboard_title, id }) => (
          <div
            key={id}
            className={cn('dashboards-and-folders-list__list', {
              'dashboards-and-folders-list__list--selected':
                getIsSelectedDashboard(id),
            })}
            onClick={handleSelectDashboard?.(id)}
            role="button"
            tabIndex={0}
          >
            <span className="add-to-folder-modal__table-name">
              {dashboard_title}
            </span>
            <span className="add-to-folder-modal__table-type">
              {t('Dashboard')}
            </span>
          </div>
        ))}
      </>
    );
  },
);
