import React, { FC, useEffect, useState } from 'react';
import { Switch } from 'antd';
import { isFeatureEnabled, FeatureFlag } from '@superset-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { FormLabel } from 'src/components/Form/components/FormLabel';
import { FormItem } from 'src/components/Form/components/FormItem';
import { SSHTunnelSwitchProps } from './interfaces';
import './styles.less';

export const SSHTunnelSwitch: FC<SSHTunnelSwitchProps> = props => {
  const { db, dbModel, clearValidationErrors, changeMethods } = props;
  const { t } = useTranslation();
  const [isChecked, setChecked] = useState(false);
  const sshTunnelEnabled = isFeatureEnabled(FeatureFlag.SshTunneling);
  const disableSSHTunnelingForEngine =
    dbModel?.engine_information?.disable_ssh_tunneling || false;
  const isSSHTunnelEnabled = sshTunnelEnabled && !disableSSHTunnelingForEngine;

  const handleOnChange = (changed: boolean) => {
    setChecked(changed);
    changeMethods.onParametersChange({
      target: {
        type: 'toggle',
        name: 'ssh',
        checked: true,
        value: changed,
      },
    });
    clearValidationErrors();
  };

  useEffect(() => {
    if (isSSHTunnelEnabled && db?.parameters?.ssh !== undefined) {
      setChecked(db.parameters.ssh);
    }
  }, [db?.parameters?.ssh, isSSHTunnelEnabled]);

  useEffect(() => {
    if (
      isSSHTunnelEnabled &&
      db?.parameters?.ssh === undefined &&
      !isEmpty(db?.ssh_tunnel)
    ) {
      // reflecting the state of the ssh tunnel on first load
      changeMethods.onParametersChange({
        target: {
          type: 'toggle',
          name: 'ssh',
          checked: true,
          value: true,
        },
      });
    }
  }, [changeMethods, db?.parameters?.ssh, db?.ssh_tunnel, isSSHTunnelEnabled]);

  return isSSHTunnelEnabled ? (
    <FormItem
      label={
        <FormLabel
          htmlFor="ssh-tunnel"
          tooltip={{ title: t('SSH Tunnel configuration parameters') }}
          withoutMargins
        >
          SSH Tunnel
        </FormLabel>
      }
      labelAlign="left"
      className="ssh-tunnel-switch"
    >
      <Switch
        id="ssh-tunnel"
        checked={isChecked}
        onChange={handleOnChange}
        data-test="ssh-tunnel-switch"
      />
    </FormItem>
  ) : null;
};
