import React, { FC, memo } from 'react';
import { Logo } from './components/Logo';
import { Docs } from './components/Docs';
import { Languages } from './components/Languages';
import { Create } from './components/Create';
import { Avatar } from './components/Avatar';
import { HeaderProps } from './interfaces';
import { StyledHeader, StyledRightContainer } from './styled';

/** Хэдер */
export const Header: FC<HeaderProps> = memo(props => {
  const { brand, user, language, updateLanguage, roles, needOldRedirect } =
    props;

  return (
    <StyledHeader>
      <Logo brand={brand} needOldRedirect={needOldRedirect} />
      <StyledRightContainer>
        <Docs />
        {user && roles && <Create roles={roles} />}
        <Languages language={language} updateLanguage={updateLanguage} />
        {user && <Avatar user={user} />}
      </StyledRightContainer>
    </StyledHeader>
  );
});
