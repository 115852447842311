import React, {
  ReactNode,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  FC,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SupersetClient } from '@superset-ui/core';
import rison from 'rison';
import { AsyncSelect, Select } from 'src/components';
import { FormLabel } from 'src/components/Form';
import { RefreshLabel } from 'src/components/RefreshLabel';
import { useToasts } from 'src/components/MessageToasts/withToasts';
import { useSchemas, SchemaOption } from 'src/hooks/apiResources';
import {
  DatabaseSelectorProps,
  DatabaseValue,
  DatabaseObject,
} from './interfaces';
import './styles.less';
import { SelectLabel } from './components/SelectLabel';

export const DatabaseSelector: FC<DatabaseSelectorProps> = memo(props => {
  const {
    db,
    isFormMode = false,
    emptyState,
    getDbList,
    handleError,
    isDatabaseSelectEnabled = true,
    onDbChange,
    onEmptyResults,
    onSchemaChange,
    isReadOnly = false,
    schema,
    isSqlLabMode = false,
  } = props;
  const { t } = useTranslation();
  const [currentDb, setCurrentDb] = useState<DatabaseValue | undefined>();
  const [currentSchema, setCurrentSchema] = useState<SchemaOption | undefined>(
    schema ? { label: schema, value: schema, title: schema } : undefined,
  );
  const schemaRef = useRef(schema);
  schemaRef.current = schema;
  const { addSuccessToast } = useToasts();

  const loadDatabases = useMemo(
    () =>
      async (
        search: string,
        page: number,
        /** Расширить фильтры запроса для получения дефолт базы данных
         * со списком запрашивающихся баз данных с учетом pageSize
         * https://jira.mts.ru/browse/DOP-16574 */
        // pageSize: number,
      ): Promise<{
        data: DatabaseValue[];
        totalCount: number;
      }> => {
        const queryParams = rison.encode({
          order_columns: 'database_name',
          order_direction: 'asc',
          page,
          page_size: -1,
          ...(isFormMode || !isSqlLabMode
            ? { filters: [{ col: 'database_name', opr: 'ct', value: search }] }
            : {
                filters: [
                  { col: 'database_name', opr: 'ct', value: search },
                  {
                    col: 'expose_in_sqllab',
                    opr: 'eq',
                    value: true,
                  },
                ],
              }),
        });
        const endpoint = `/api/v1/database/?q=${queryParams}`;
        return SupersetClient.get({ endpoint }).then(({ json }) => {
          const { result, count } = json;
          if (getDbList) {
            getDbList(result);
          }
          if (result.length === 0) {
            if (onEmptyResults) onEmptyResults(search);
          }
          const options = result.map((row: DatabaseObject) => ({
            label: (
              <SelectLabel
                databaseName={row.backend}
                databaseLabel={row.database_name}
              />
            ),
            value: row.id,
            id: row.id,
            database_name: row.database_name,
            backend: row.backend,
          }));

          return {
            data: options,
            totalCount: count ?? options.length,
          };
        });
      },
    [isFormMode, getDbList, isSqlLabMode],
  );

  useEffect(() => {
    setCurrentDb(current =>
      current?.id !== db?.id
        ? db
          ? {
              label: (
                <SelectLabel
                  databaseName={db.backend}
                  databaseLabel={db.database_name}
                />
              ),
              value: db.id,
              ...db,
            }
          : undefined
        : current,
    );
  }, [db]);

  function changeSchema(schema: SchemaOption | undefined) {
    setCurrentSchema(schema);
    if (onSchemaChange && schema?.value !== schemaRef.current) {
      onSchemaChange(schema?.value);
    }
  }

  const {
    data,
    isFetching: loadingSchemas,
    refetch,
  } = useSchemas({
    dbId: currentDb?.value,
    onSuccess: (schemas, isFetched) => {
      if (schemas.length === 1) {
        changeSchema(schemas[0]);
      } else if (
        !schemas.find(schemaOption => schemaRef.current === schemaOption.value)
      ) {
        changeSchema(undefined);
      }

      if (isFetched) {
        addSuccessToast('List refreshed');
      }
    },
    onError: () => handleError(t('There was an error loading the schemas')),
  });

  const schemaOptions = data || [];

  function changeDataBase(
    value: { label: string; value: number },
    database: DatabaseValue,
  ) {
    setCurrentDb(database);
    setCurrentSchema(undefined);
    if (onDbChange) {
      onDbChange(database);
    }
    if (onSchemaChange) {
      onSchemaChange(undefined);
    }
  }

  function renderSelectRow(select: ReactNode, refreshBtn: ReactNode) {
    return (
      <div className="database-selector__section">
        <div className="table-selector__select-container">{select}</div>
        {refreshBtn}
      </div>
    );
  }

  function renderDatabaseSelect() {
    return renderSelectRow(
      <AsyncSelect
        ariaLabel={t('Select database or type to search databases')}
        optionFilterProps={['database_name', 'value']}
        data-test="select-database"
        header={<FormLabel>{t('Database')}</FormLabel>}
        lazyLoading={false}
        notFoundContent={emptyState}
        onChange={changeDataBase}
        value={currentDb}
        placeholder={t('Select database or type to search databases')}
        disabled={!isDatabaseSelectEnabled || isReadOnly}
        options={loadDatabases}
      />,
      null,
    );
  }

  const refetchDataset = useCallback(() => refetch(), [refetch]);

  function renderSchemaSelect() {
    const refreshIcon = !isReadOnly && (
      <RefreshLabel
        size="s"
        onClick={refetchDataset}
        tooltipContent={t('Force refresh schema list')}
        disabled={!currentDb || loadingSchemas}
        className="database-selector__refresh-button"
      />
    );
    return renderSelectRow(
      <Select
        dataTestId="select-schema"
        ariaLabel={t('Select schema or type to search schemas')}
        disabled={!currentDb || isReadOnly}
        header={<FormLabel>{t('Schema')}</FormLabel>}
        labelInValue
        loading={loadingSchemas}
        name="select-schema"
        notFoundContent={t('No compatible schema found')}
        placeholder={t('Select schema or type to search schemas')}
        onChange={item => changeSchema(item as SchemaOption)}
        options={schemaOptions}
        showSearch
        value={currentSchema}
      />,
      refreshIcon,
    );
  }

  return (
    <div className="database-selector">
      {renderDatabaseSelect()}
      {renderSchemaSelect()}
    </div>
  );
});
