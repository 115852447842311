import { getExtensionsRegistry } from '@superset-ui/core';
import { SSHTunnelSwitch } from '../SSHTunnelSwitch';
import { HostField } from './components/HostField';
import { PortField } from './components/PortField';
import { ValidatedInputField } from './components/ValidatedInputField';
import { ForceSSLField } from './components/ForceSSLField';
import { HttpPath } from './components/HttpPath';
import { QueryField } from './components/QueryField';
import { DisplayField } from './components/DisplayField';
import { EncryptedField } from './components/EncryptedField';
import { DatabaseField } from './components/DatabaseField';
import { UsernameField } from './components/UsernameField';
import { TableCatalog } from './components/TableCatalog';
import { AccessTokenField } from './components/AccessTokenField';
import { PasswordField } from './components/PasswordField';

const extensionsRegistry = getExtensionsRegistry();

export const SSHTunnelSwitchComponent =
  extensionsRegistry.get('ssh_tunnel.form.switch') ?? SSHTunnelSwitch;

export const FormFieldOrder = [
  'host',
  'port',
  'database',
  'username',
  'password',
  'access_token',
  'http_path',
  'database_name',
  'credentials_info',
  'service_account_info',
  'catalog',
  'query',
  'encryption',
  'account',
  'warehouse',
  'role',
  'ssh',
];

export const FORM_FIELD_MAP = {
  host: HostField,
  http_path: HttpPath,
  port: PortField,
  database: DatabaseField,
  username: UsernameField,
  password: PasswordField,
  access_token: AccessTokenField,
  database_name: DisplayField,
  query: QueryField,
  encryption: ForceSSLField,
  credentials_info: EncryptedField,
  service_account_info: EncryptedField,
  catalog: TableCatalog,
  warehouse: ValidatedInputField,
  role: ValidatedInputField,
  account: ValidatedInputField,
  ssh: SSHTunnelSwitchComponent,
};
