import React, { FC, memo } from 'react';
import withToasts from 'src/components/MessageToasts/withToasts';
import { CarouselBlock } from './components/CarouselBlock';
import { ScriptBlock } from './components/ScriptBlock';
import { SliderBlock } from './components/SliderBlock';
import { HomePageProps } from './interfaces';
import './styles.less';

/** Главная страница */
const Home: FC<HomePageProps> = props => {
  const { user, addDangerToast } = props;
  return (
    <>
      <CarouselBlock />
      <ScriptBlock roles={user.roles} />
      <SliderBlock user={user} addDangerToast={addDangerToast} />
      <span className="copyright">© 2024 ПАО «МТС».</span>
    </>
  );
};

export default withToasts(memo(Home));
