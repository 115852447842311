import { Avatar, Button, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ExitSvg from '../../../../../../assets/images/exit.svg';
import { DropdownMenuProps } from './interfaces';
import {
  StyledDropdownMenu,
  StyledDropdownMenuContainer,
  StyledDropdownMenuEmail,
  StyledDropdownMenuLogoutButton,
  StyledDropdownMenuLogoutLink,
  StyledDropdownMenuMeta,
  StyledDropdownMenuText,
  StyledDropdownMenuTextLink,
  StyledName,
  StyledProfileIcon,
} from '../../../../styled';

/** Дропдаун меня для аватарки */
export const DropdownMenu: FC<DropdownMenuProps> = memo(props => {
  const { user } = props;
  const { email, firstName, lastName, username } = user || {};
  const { t } = useTranslation();

  const name = `${lastName} ${firstName}`;

  return (
    <StyledDropdownMenu>
      <StyledDropdownMenuContainer>
        <Avatar size={40} icon={<UserOutlined />} />
        <StyledDropdownMenuMeta>
          <StyledName>{name}</StyledName>
          <StyledDropdownMenuText>{username}</StyledDropdownMenuText>
          <StyledDropdownMenuEmail>{email}</StyledDropdownMenuEmail>
        </StyledDropdownMenuMeta>
      </StyledDropdownMenuContainer>
      <Divider />

      <StyledDropdownMenuTextLink href="/profile/">
        <Button icon={<StyledProfileIcon size={22} />} type="text">
          {t('My profile')}
        </Button>
      </StyledDropdownMenuTextLink>
      <StyledDropdownMenuLogoutLink href="/logout/">
        <StyledDropdownMenuLogoutButton icon={<ExitSvg />} type="text">
          {t('Logout')}
        </StyledDropdownMenuLogoutButton>
      </StyledDropdownMenuLogoutLink>
    </StyledDropdownMenu>
  );
});
