import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPropTypes } from 'src/features/databases/types';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';

export const PasswordField = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  db,
  isEditMode,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  return (
    <LabeledErrorBoundInput
      id="password"
      name="password"
      isRequired={isRequired}
      isVisibilityToggle={!isEditMode}
      value={db?.parameters?.password}
      validationMethods={{ onBlur: getValidation }}
      errorMessage={validationErrors?.password}
      placeholder={t('e.g. ********')}
      label={t('Password')}
      onChange={changeMethods.onParametersChange}
    />
  );
};
