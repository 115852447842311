import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FEATURES, FEATURES_TYPES, TEXT_BY_FEATURES } from '../../constants';
import './styles.less';
import { DOCUMENTATION_LAST_RELEASE_ROUTE } from '../../../../views/routes';
import { ReleaseContentProps } from './interfaces';
import { getVersionNumber } from '../../../../utils/getVersionNumber';
import { RootState } from '../../../../dashboard/types';

/** Контент в модальном окне пре-релиза */
export const ReleaseContent: FC<ReleaseContentProps> = props => {
  const { closeModal } = props;
  const featuresKeys = Object.keys(FEATURES_TYPES);
  const { t } = useTranslation();
  const appVersion = useSelector((state: RootState) => state.appVersion);
  const numberVersion = getVersionNumber(appVersion);
  return (
    <>
      <span className="release-content__text">
        <Trans
          i18nKey="Version <0>{{version}}</0> introduces new features for working with charts and managing filtering:"
          values={{ version: numberVersion }}
          components={[<b />]}
          t={t}
        />
      </span>

      <div className="release-content__features">
        {featuresKeys.map(featuresKey => (
          <div key={featuresKey}>
            <span className="release-content__feature-name">
              - {t(FEATURES[featuresKey])}:
            </span>
            &nbsp;
            <span className="release-content__text">
              {t(TEXT_BY_FEATURES[featuresKey])}
            </span>
          </div>
        ))}
      </div>

      <span className="release-content__text">
        {t('For more information')}&nbsp;
        <Link to={DOCUMENTATION_LAST_RELEASE_ROUTE} onClick={closeModal}>
          {t('please refer to the documentation')}
        </Link>
        &nbsp;
        {t('or come back to this later.')}
      </span>
    </>
  );
};
