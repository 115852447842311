import { SupersetClient, t } from '@superset-ui/core';
import { useHistory } from 'react-router-dom';
import { URL_PARAMS } from 'src/constants';
import { createErrorHandler } from '../../../../../../views/CRUD/utils';
import { Toast } from '../../../../../../types/ToastTypes';

interface UseCreateHandlersResult {
  /** Создание дашборда */
  handleCreateDashboard: () => void;
  /** Создание чарта */
  handleCreateChart: () => void;
  /** Создание датасета */
  handleCreateDataset: () => void;
  /** Создание базы данных */
  handleCreateDatabases: () => void;
}

/** Хук с обработчиками кнопок */
export const useCreateHandlers = (
  userId: string | number,
  addDangerToast: Toast,
  setIsOpenChartCreationCurtain: (isOpen: boolean) => void,
  setIsOpenDatasetCreationCurtain: (isOpen: boolean) => void,
): UseCreateHandlersResult => {
  const history = useHistory();

  const handleCreateDashboard = () => {
    SupersetClient.post({
      endpoint: `/api/v1/dashboard/`,
      jsonPayload: {
        dashboard_title: '[ untitled dashboard ]',
        owners: [userId],
      },
    }).then(
      ({ json }) => {
        history.push(`/superset/dashboard/${json.id}/?edit=true`);
      },
      createErrorHandler(errMessage => addDangerToast(t(errMessage))),
    );
  };
  const handleCreateChart = () => setIsOpenChartCreationCurtain(true);
  const handleCreateDataset = () => setIsOpenDatasetCreationCurtain(true);
  const handleCreateDatabases = () => {
    history.push(
      `/databaseview/list/?${URL_PARAMS.showDatabaseModal.name}=true`,
    );
  };

  return {
    handleCreateDashboard,
    handleCreateChart,
    handleCreateDataset,
    handleCreateDatabases,
  };
};
