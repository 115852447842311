import { MouseEventHandler } from 'react';

export enum Size {
  S = 's',
  M = 'm',
  L = 'l',
}

export interface RefreshLabelProps {
  /** Функция нажатия на кнопку */
  onClick: MouseEventHandler<HTMLSpanElement>;
  /** Текст в тултипе */
  tooltipContent: string;
  /** Размер */
  size?: 's' | 'm' | 'l';
  /** Свойство блокирования кнопки */
  disabled?: boolean;
  className?: string;
}
