import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import cn from 'classnames';
import { SliderCardProps } from './interfaces';
import './styles.less';

/** Карточка слайдера */
export const SliderCard: FC<SliderCardProps> = memo(props => {
  const { t } = useTranslation();
  const {
    image,
    title,
    description,
    onClick,
    canCreate,
    buttonText = t('Create'),
  } = props;

  return (
    <div className="slider-card">
      <div className="slider-card__preview">{image}</div>
      <h3 className="slider-card__title">{title}</h3>
      <p
        className={cn(
          'slider-card__description',
          !canCreate && 'slider-card--no-margin',
        )}
      >
        {description}
      </p>
      {canCreate && (
        <Button
          data-test="action-button"
          className="slider-card__button"
          type="primary"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
});
