import React, { FC } from 'react';
import { styled } from '@superset-ui/core';
import { useTranslation } from 'react-i18next';
import Alert from '../../../../../../components/Alert';

const StyledAlert = styled(Alert)`
  ${({ theme }) => `
  border: 1px solid ${theme.colors.info.base};
  padding: ${theme.gridUnit * 4}px;
  margin: ${theme.gridUnit * 6}px ${theme.gridUnit * 6}px
    ${theme.gridUnit * 8}px;
  .view-dataset-button {
    position: absolute;
    top: ${theme.gridUnit * 4}px;
    right: ${theme.gridUnit * 4}px;
    font-weight: ${theme.typography.weights.normal};

    &:hover {
      color: ${theme.colors.secondary.dark3};
      text-decoration: underline;
    }
  }
  `}
`;

export const ExistingDatasetAlert: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAlert
      closable={false}
      type="info"
      showIcon
      message={t('This table already has a dataset')}
      description={
        <>
          {t(
            'This table already has a dataset associated with it. You can only associate one dataset with a table.',
          )}
        </>
      }
    />
  );
};
