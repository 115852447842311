import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SwipeSlider } from '../../../../components/SwipeSlider';
import { useGetConfig } from './hooks/useGetConfig';
import { SliderCard } from '../SliderCard';
import './styles.less';
import { SliderBlockComponentProps, SliderBlockProps } from './interfaces';
import { withChartCreationCurtain } from '../../../../features/charts/components/withChartCreationCurtain';
import { withDatasetCreationCurtain } from '../../../../features/datasets/CreateDatasetCurtain/hoc/withCreateDatasetCurtain';

/** Блок со слайдером */
const SliderBlockComponent: FC<SliderBlockComponentProps> = props => {
  const {
    user,
    addDangerToast,
    setIsOpenDatasetCreationCurtain,
    setIsOpenChartCreationCurtain,
  } = props;
  const { t } = useTranslation();

  const sliderConfig = useGetConfig({
    userId: user.userId,
    roles: user.roles,
    addDangerToast,
    setIsOpenDatasetCreationCurtain,
    setIsOpenChartCreationCurtain,
  });

  return (
    <div className="slider-block">
      <h3 className="slider-block__title">{t('Product features')}</h3>
      <p className="slider-block__description">
        {t(
          'You can create and enrich data sets. Form all kinds of visualizations based on them with the ability to customize both the calculation of indicators and the appearance of graphs. Present your data in the form of intuitive and holistic analytics on dashboards',
        )}
      </p>
      <SwipeSlider>
        {sliderConfig.map(
          (
            { title, description, image, onClick, canCreate, buttonText },
            index,
          ) => (
            <SliderCard
              key={index}
              image={image}
              title={title}
              description={description}
              onClick={onClick}
              canCreate={canCreate}
              buttonText={buttonText}
            />
          ),
        )}
      </SwipeSlider>
    </div>
  );
};

export const SliderBlock = withChartCreationCurtain<SliderBlockProps>(
  withDatasetCreationCurtain<SliderBlockProps>(SliderBlockComponent),
);
