import React, { FC, memo } from 'react';
import './styles.less';
import { DashboardsAndFoldersListProps } from './interfaces';
import { FolderListForModal } from './components/FolderListForModal';
import { DashboardListForModal } from './components/DashboardListForModal';

/** Лист дашбордов и папок */
export const DashboardsAndFoldersList: FC<DashboardsAndFoldersListProps> = memo(
  props => {
    const {
      folders,
      dashboards,
      setPath,
      handleSelectDashboard,
      selectedDashbordsIds,
    } = props;

    return (
      <div className="dashboards-and-folders-list">
        <FolderListForModal folders={folders} setPath={setPath} />
        <DashboardListForModal
          selectedDashbordsIds={selectedDashbordsIds}
          handleSelectDashboard={handleSelectDashboard}
          dashboards={dashboards}
        />
      </div>
    );
  },
);
