import { preparePathLength } from '../preparePathLength';
import {
  FolderPathProps,
  PreparedPath,
  PreparedPathHidden,
} from '../../interfaces';

export const preparePath = (path: FolderPathProps['path']) =>
  path.reduce(
    (acc, { name, id }, index, arrayPath) => {
      const arrayLength = arrayPath.length;
      if (arrayLength > 3) {
        if (id !== '/' && arrayLength - index > 2) {
          if (acc.hasHidden) {
            acc.hidden.push({ name, id });
            return acc;
          }
          acc.hasHidden = true;
          acc.displayed.push({ name: '...', isHidden: true });
          acc.hidden.push({ name, id });
          return acc;
        }
        acc.displayed.push(preparePathLength(name, id));
        return acc;
      }
      acc.displayed.push(preparePathLength(name, id));
      return acc;
    },
    {
      displayed: [] as Array<PreparedPath>,
      hidden: [] as Array<PreparedPathHidden>,
      hasHidden: false,
    },
  );
