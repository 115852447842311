import { Button, Tooltip } from 'antd';
import { Modal } from 'src/newComponents/Modal';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FolderPath } from '../../pages/Folders/components/FolderPath';
import FolderSvg from '../../assets/images/folder.svg';
import { useFolder } from '../../dashboard/hooks/useFolder';
import { getFolderParentIdFromFullIds } from '../../components/FolderModal/utils/getFolderParentIdFromFullIds';
import { DashboardsAndFoldersList } from './components/DashboardsAndFoldersList';
import { Loader } from '../../newComponents/Loader';
import './styles.less';
import { UPDATE_TYPES } from '../../components/ListView/CardFolderCollection/interfaces';
import Icons from '../../components/Icons';
import { FolderCurtain } from '../Curtains/FolderCurtain';
import { AddToFolderModalProps } from './interfaces';
import { CreationType } from './constants';
import { DEFAULT_FOLDER_PATH } from '../../constants';

/** Добавление в папку */
export const AddToFolderModal: FC<AddToFolderModalProps> = memo(props => {
  const { title, isShow, onHide, dashboardIdToFolder, creationType } = props;
  const { t } = useTranslation();
  const [isOpenFolderCurtain, setIsOpenFolderCurtain] = useState(false);

  const [path, setPath] = useState({ names: '', ids: '' });
  const {
    getFolders,
    updateCurrentFolder,
    folders,
    isLoadingFolders,
    dashboards,
  } = useFolder();

  useEffect(() => {
    const parentId = getFolderParentIdFromFullIds(path.ids);
    getFolders(-1, undefined, parentId);
  }, [getFolders, path.ids]);

  const handleBack = () => {
    if (path.names && path.ids) {
      const lastIndexName = path.names.lastIndexOf('~');
      const lastIndexId = path.ids.lastIndexOf('~');

      const newNames = path.names.substring(0, lastIndexName);
      const newIds = path.ids.substring(0, lastIndexId);

      setPath({ names: newNames, ids: newIds });
    }
  };

  const folderPath = useMemo(() => {
    if (path.names && path!.ids) {
      const folderNames = path.names.split('~');
      const folderIds = path.ids.split('~');
      return folderNames.reduce(
        (acc, folderName, index) => {
          acc.push({ name: folderName, id: folderIds[index] });
          return acc;
        },
        [DEFAULT_FOLDER_PATH(t)],
      );
    }

    return [DEFAULT_FOLDER_PATH(t)];
  }, [path]);

  const setNewFolderPath = useCallback((newNames, newIds) => {
    setPath({ names: newNames, ids: newIds });
  }, []);

  const closeFolderCurtain = useCallback(() => {
    setIsOpenFolderCurtain(false);
  }, []);

  const Content = (
    <>
      {path.names && path.ids && (
        <Button
          onClick={handleBack}
          type="link"
          icon={<Icons.ArrowLeftOutlined iconSize="m" />}
          className="add-to-folder-modal__back"
        >
          {t('Back')}
        </Button>
      )}
      <div className="add-to-folder-modal__path">
        <FolderSvg />
        <FolderPath
          path={folderPath}
          folderNames={path.names}
          folderIds={path.ids}
          setNewFolderPath={setNewFolderPath}
        />
      </div>
      <div className="add-to-folder-modal__list">
        {isLoadingFolders && <Loader size="l" position="float" />}
        {!isLoadingFolders && (
          <>
            <div className="add-to-folder-modal__table-header">
              <span className="add-to-folder-modal__table-name add-to-folder-modal__table-name--divider">
                {t('Name')}
              </span>
              <span className="add-to-folder-modal__table-type">
                {t('Type')}
              </span>
            </div>
            <DashboardsAndFoldersList
              setPath={setPath}
              folders={folders}
              dashboards={dashboards}
            />
          </>
        )}
      </div>
    </>
  );

  const dashboardsIdsInFolder = dashboards.map(({ id }) => id);

  const isCurrentDashboardInFolder = dashboardsIdsInFolder.includes(
    dashboardIdToFolder as number,
  );

  const isDisabledAddToFolder = isCurrentDashboardInFolder || !path.ids;

  let tooltipTitle = '';

  if (isCurrentDashboardInFolder) {
    tooltipTitle = t('Dashboard already exists in the folder');
  }

  if (!path.ids) {
    tooltipTitle = t("You can't add a dashboard outside of a folder");
  }

  return (
    <>
      <Modal
        title={title}
        isOpen={isShow}
        onCancel={onHide}
        footer={
          <div
            className={cn('add-to-folder-modal__footer', {
              'add-to-folder-modal__right-buttons--reverse':
                creationType === CreationType.Folder,
            })}
          >
            <Button
              type={
                creationType === CreationType.Dashboard ? 'ghost' : 'primary'
              }
              className={cn({
                'add-to-folder-modal__create-folder-button':
                  creationType === CreationType.Dashboard,
              })}
              onClick={() => {
                setIsOpenFolderCurtain(true);
              }}
            >
              {t('Create folder')}
            </Button>

            <div className="add-to-folder-modal__right-buttons">
              <Button type="default" onClick={onHide}>
                {t('Cancel')}
              </Button>
              {creationType === CreationType.Dashboard && (
                <Tooltip
                  title={tooltipTitle}
                  placement="bottom"
                  className="add-to-folder-modal__add-btn"
                >
                  <Button
                    type="primary"
                    onClick={() =>
                      updateCurrentFolder(
                        {
                          id: getFolderParentIdFromFullIds(path.ids),
                          dashboards: [
                            ...(dashboardsIdsInFolder as Array<number>),
                            dashboardIdToFolder as number,
                          ],
                        },
                        undefined,
                        UPDATE_TYPES.DASHBOARD,
                      )
                    }
                    disabled={isDisabledAddToFolder}
                  >
                    {t('Add to current')}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        }
      >
        {Content}
      </Modal>
      <FolderCurtain
        isOpenCurtain={isOpenFolderCurtain}
        hideCurtain={closeFolderCurtain}
        folderIds={path.ids}
      />
    </>
  );
});
