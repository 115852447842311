import React from 'react';
import { useTranslation } from 'react-i18next';
import BIBanner from 'src/assets/images/biBanner.svg';
import DataCatalogBanner from 'src/assets/images/datacatalogBanner.svg';
import { CarouselContentProps } from '../../../CarouselContent/interfaces';

/** Хук получения конфига для карусели */
export const useGetConfig = (): CarouselContentProps[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('Welcome to DataOps BI'),
      description: t(
        'Visualize data, build dashboards, share analytics. All with the ability to flexibly customize the role model',
      ),
      buttons: [
        {
          type: 'primary',
          label: t('Documentation'),
          onClick: () => {
            window.open('/docs', '_blank');
          },
          dataTestId: 'docs-action',
        },
        {
          label: t('Corporate university'),
          onClick: () => {
            window.open(
              'https://university.hr.mts.ru/education/52bc03f4-e47d-409d-bde3-47f9bb79786f?entity_type=program',
            );
          },
          dataTestId: 'university-action',
        },
      ],
      image: <BIBanner />,
    },
    {
      title: t('Check the dashboard in DataCatalog'),
      description: t(
        'Explore already created dashboards, find out which ACCESS role is needed to access them in DataCatalog',
      ),
      buttons: [
        {
          type: 'primary',
          label: 'DataCatalog',
          onClick: () => {
            window.open(
              'https://datacatalog.mts.ru/search?filter__entityType%E2%90%9EtypeNames___false___EQUAL___0=DASHBOARD&page=1&query=%2A&unionType=0',
              '_blank',
            );
          },
          dataTestId: 'datacatalog-action',
        },
      ],
      image: <DataCatalogBanner />,
    },
  ];
};
