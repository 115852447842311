import { useHistory } from 'react-router-dom';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../dashboard/types';
import { UserWithPermissionsAndRoles } from '../../../../../types/bootstrapTypes';
import { ExtensionConfigs } from '../../../../home/types';
import { isUserAdmin } from '../../../../../dashboard/util/permissionUtils';
import { uploadUserPerms } from '../../../../../views/CRUD/utils';
import { findPermission } from '../../../../../utils/findPermission';
import { Items } from '../interfaces';
import { parseUrlParams } from '../../../../../utils/parseUrlParams';

type UseCreateProps = {
  /** Роли */
  roles: Record<string, [string, string][]>;
  setIsFolderModalOpen: Dispatch<SetStateAction<boolean>>;
  /** Функция открытия шторки создания чарта */
  setIsOpenChartCreationCurtain: Dispatch<SetStateAction<boolean>>;
  /** Функция открытия шторки создания датасета */
  setIsOpenDatasetCreationCurtain: Dispatch<SetStateAction<boolean>>;
  /** Функция открытия шторки создания базы данных */
  setIsOpenDatabaseCreationCurtain: Dispatch<SetStateAction<boolean>>;
};

/** Хук конфигурирующий кнопки */
export const useCreate = (params: UseCreateProps) => {
  const {
    roles,
    setIsFolderModalOpen,
    setIsOpenDatasetCreationCurtain,
    setIsOpenChartCreationCurtain,
    setIsOpenDatabaseCreationCurtain,
  } = params;

  const history = useHistory();
  const { t } = useTranslation();
  const [isOpenUploadDataExcelCurtain, setIsOpenUploadDataExcelCurtain] =
    useState(false);

  const [isOpenUploadDataCsvCurtain, setIsOpenUploadDataCsvCurtain] =
    useState(false);
  const [isOpenUploadDataGeoJsonCuration, setIsOpenUploadDataGeoJsonCuration] =
    useState(false);

  const dashboardId = useSelector<RootState, number | undefined>(
    state => state.dashboardInfo?.id,
  );
  const user = useSelector<any, UserWithPermissionsAndRoles>(
    state => state.user,
  );
  const {
    CSV_EXTENSIONS,
    COLUMNAR_EXTENSIONS,
    EXCEL_EXTENSIONS,
    GEOJSON_EXTENSIONS,
    ALLOWED_EXTENSIONS,
  } = useSelector<any, ExtensionConfigs>(state => state.common.conf);

  const isAdmin = isUserAdmin(user);
  const { canUploadCSV, canUploadExcel, canUploadGeoJson } = uploadUserPerms(
    roles,
    CSV_EXTENSIONS,
    COLUMNAR_EXTENSIONS,
    EXCEL_EXTENSIONS,
    GEOJSON_EXTENSIONS,
    ALLOWED_EXTENSIONS,
  );
  const canDatabase = findPermission('can_write', 'Database', roles);

  const goTo = (url: string) => {
    history.push(url);
  };

  const closeUploadDataExcelCurtain = useCallback(() => {
    setIsOpenUploadDataExcelCurtain(false);
  }, []);

  const handleCreateChart = () => {
    if (dashboardId && Number.isInteger(dashboardId)) {
      const searchParams = parseUrlParams(history.location.search);
      searchParams.dashboard_id = dashboardId.toString();

      history.push({
        pathname: history.location.pathname,
        search: new URLSearchParams(searchParams).toString(),
      });
    }

    setIsOpenChartCreationCurtain(true);
  };

  const closeUploadDataCsvCurtain = () => {
    setIsOpenUploadDataCsvCurtain(false);
  };
  const closeUploadDataGeoJsonCuration = () => {
    setIsOpenUploadDataGeoJsonCuration(false);
  };

  const items: Items = [
    {
      label: t('Dashboard'),
      key: 'Dashboard',
      onClick: () => window.location.assign('/dashboard/new'),
      perm: 'can_write',
      view: 'Dashboard',
    },
    {
      label: t('Chart'),
      key: 'Chart',
      onClick: handleCreateChart,
      perm: 'can_write',
      view: 'Chart',
    },
    {
      label: t('Dataset'),
      key: 'Dataset',
      onClick: () => setIsOpenDatasetCreationCurtain(true),
      perm: 'can_write',
      view: 'Dataset',
    },
    {
      label: t('Folder'),
      key: 'Folder',
      onClick: () => setIsFolderModalOpen(true),
      perm: 'can_write',
      view: 'Folder',
    },
    {
      label: t('DB Connection'),
      key: 'DB Connection',
      onClick: () => setIsOpenDatabaseCreationCurtain(true),
      perm: canDatabase,
    },
    {
      label: t('Upload'),
      key: 'Upload',
      perm: canDatabase,
      children: [
        {
          label: t('Upload CSV to database'),
          onClick: () => setIsOpenUploadDataCsvCurtain(true),
          perm: canUploadCSV,
          disable: isAdmin,
          key: 'UploadCsv',
        },
        {
          label: t('Upload Excel file to database'),
          onClick: () => setIsOpenUploadDataExcelCurtain(true),
          perm: canUploadExcel,
          disable: isAdmin,
          key: 'UploadExcel',
        },
        {
          label: t('Upload GeoJson to database'),
          onClick: () => setIsOpenUploadDataGeoJsonCuration(true),
          perm: canUploadGeoJson,
          disable: isAdmin,
          key: 'UploadGeoJson',
        },
      ],
    },
    {
      label: t('Alerts & reports'),
      key: 'Alerts & reports',
      onClick: () => goTo('/alert/list/'),
    },
  ];

  return {
    closeUploadDataExcelCurtain,
    closeUploadDataCsvCurtain,
    closeUploadDataGeoJsonCuration,
    items,
    isOpenUploadDataExcelCurtain,
    isOpenUploadDataCsvCurtain,
    isOpenUploadDataGeoJsonCuration,
  };
};
