import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPropTypes } from 'src/features/databases/types';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';

export const DatabaseField = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  placeholder,
  db,
}: FieldPropTypes) => {
  const { t } = useTranslation();

  return (
    <LabeledErrorBoundInput
      id="database"
      name="database"
      isRequired={isRequired}
      value={db?.parameters?.database}
      validationMethods={{ onBlur: getValidation }}
      errorMessage={validationErrors?.database}
      placeholder={placeholder ?? t('e.g. world_population')}
      label={t('Database name')}
      onChange={changeMethods.onParametersChange}
      helpText={t(
        'Copy the name of the database you are trying to connect to.',
      )}
    />
  );
};
