import { FeatureFlag, isFeatureEnabled } from '@superset-ui/core';
import React, { lazy } from 'react';

// not lazy loaded since this is the home page.
import Home from 'src/pages/Home';

const AnnotationLayerList = lazy(
  () =>
    import(
      /* webpackChunkName: "AnnotationLayerList" */ 'src/pages/AnnotationLayerList'
    ),
);

const AlertsReports = lazy(
  () =>
    import(/* webpackChunkName: "AlertsReports" */ 'src/pages/AlertsReports'),
);

const AnnotationList = lazy(
  () =>
    import(/* webpackChunkName: "AnnotationList" */ 'src/pages/AnnotationList'),
);

const Charts = lazy(
  () => import(/* webpackChunkName: "Charts" */ 'src/pages/Charts'),
);

const CssTemplateList = lazy(
  () =>
    import(
      /* webpackChunkName: "CssTemplateList" */ 'src/pages/CssTemplateList'
    ),
);

const Dashboards = lazy(
  () => import(/* webpackChunkName: "Dashboards" */ 'src/pages/Dashboards'),
);

const Folders = lazy(
  () => import(/* webpackChunkName: "Folders" */ 'src/pages/Folders'),
);

const Profile = lazy(
  () => import(/* webpackChunkName: "Profile" */ 'src/pages/Profile'),
);

const Dashboard = lazy(
  () => import(/* webpackChunkName: "Dashboard" */ 'src/pages/Dashboard'),
);

const Databases = lazy(
  () => import(/* webpackChunkName: "Databases" */ 'src/pages/Databases'),
);

const Datasets = lazy(
  () => import(/* webpackChunkName: "Datasets" */ 'src/pages/Datasets'),
);

const ExecutionLogList = lazy(
  () =>
    import(
      /* webpackChunkName: "ExecutionLogList" */ 'src/pages/ExecutionLogList'
    ),
);

const Chart = lazy(
  () => import(/* webpackChunkName: "Chart" */ 'src/pages/Chart'),
);

const QueryHistoryList = lazy(
  () =>
    import(
      /* webpackChunkName: "QueryHistoryList" */ 'src/pages/QueryHistoryList'
    ),
);

const SavedQueryList = lazy(
  () =>
    import(/* webpackChunkName: "SavedQueryList" */ 'src/pages/SavedQueryList'),
);

const SqlLab = lazy(
  () => import(/* webpackChunkName: "SqlLab" */ 'src/pages/SqlLab'),
);

const AllEntities = lazy(
  () => import(/* webpackChunkName: "AllEntities" */ 'src/pages/AllEntities'),
);

const Tags = lazy(
  () => import(/* webpackChunkName: "Tags" */ 'src/pages/Tags'),
);

const RowLevelSecurityList = lazy(
  () =>
    import(
      /* webpackChunkName: "RowLevelSecurityList" */ 'src/pages/RowLevelSecurityList'
    ),
);

const PresetManager = lazy(
  () =>
    import(/* webpackChunkName: "PresetManager" */ 'src/pages/PresetManager'),
);

const CreateLayerTemplatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateLayerTemplatePage" */ 'src/explore/components/CreateLayerTemplate'
    ),
);

const Docs = lazy(
  () => import(/* webpackChunkName: "Chart" */ 'src/pages/Docs'),
);

type Routes = {
  path: string;
  Component: React.ComponentType;
  Fallback?: React.ComponentType;
  props?: React.ComponentProps<any>;
}[];

export const DOCUMENTATION_LAST_RELEASE_ROUTE =
  '/docs/release_notes?isReleaseNotes=true';

export const routes: Routes = [
  {
    path: '/superset/welcome/',
    Component: Home,
  },
  {
    path: '/folders/',
    Component: Folders,
  },
  {
    path: '/profile/',
    Component: Profile,
  },
  {
    path: '/dashboard/list/',
    Component: Dashboards,
  },
  {
    path: '/superset/dashboard/:idOrSlug/',
    Component: Dashboard,
  },
  {
    path: '/chart/list/',
    Component: Charts,
  },
  {
    path: '/tablemodelview/list/',
    Component: Datasets,
  },
  {
    path: '/databaseview/list/',
    Component: Databases,
  },
  {
    path: '/savedqueryview/list/',
    Component: SavedQueryList,
  },
  {
    path: '/csstemplatemodelview/list/',
    Component: CssTemplateList,
  },
  {
    path: '/annotationlayer/list/',
    Component: AnnotationLayerList,
  },
  {
    path: '/annotationlayer/:annotationLayerId/annotation/',
    Component: AnnotationList,
  },
  {
    path: '/sqllab/history/',
    Component: QueryHistoryList,
  },
  {
    path: '/alert/list/',
    Component: AlertsReports,
  },
  {
    path: '/report/list/',
    Component: AlertsReports,
    props: {
      isReportEnabled: true,
    },
  },
  {
    path: '/alert/:alertId/log/',
    Component: ExecutionLogList,
  },
  {
    path: '/report/:alertId/log/',
    Component: ExecutionLogList,
    props: {
      isReportEnabled: true,
    },
  },
  {
    path: '/explore/',
    Component: Chart,
  },
  {
    path: '/superset/explore/p',
    Component: Chart,
  },
  {
    path: '/rowlevelsecurity/list',
    Component: RowLevelSecurityList,
  },
  {
    path: '/sqllab/',
    Component: SqlLab,
  },
  {
    path: '/presets',
    Component: PresetManager,
  },
  {
    path: '/create-layer/',
    Component: CreateLayerTemplatePage,
  },
  { path: '/docs', Component: Docs },
];

if (isFeatureEnabled(FeatureFlag.TaggingSystem)) {
  routes.push({
    path: '/superset/all_entities/',
    Component: AllEntities,
  });
  routes.push({
    path: '/superset/tags/',
    Component: Tags,
  });
}

const frontEndRoutes = routes
  .map(r => r.path)
  .reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {},
  );

export function isFrontendRoute(path?: string) {
  if (path) {
    const basePath = path.split(/[?#]/)[0]; // strip out query params and link bookmarks
    return !!frontEndRoutes[basePath];
  }
  return false;
}
