import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardPreview from 'src/assets/images/dashboardPreview.svg';
import ChartPreview from 'src/assets/images/chartPreview.svg';
import DatabasePreview from 'src/assets/images/databasePreview.svg';
import DatasetPreview from 'src/assets/images/datasetPreview.svg';
import { useCreateHandlers } from '../useCreateHandlers';
import { SliderCardProps } from '../../../SliderCard/interfaces';
import { findPermission } from '../../../../../../utils/findPermission';
import { SliderBlockUseGetConfigProps } from './interfaces';

/** Хук полчения конфига для слайдера */
export const useGetConfig = (
  props: SliderBlockUseGetConfigProps,
): SliderCardProps[] => {
  const {
    userId,
    setIsOpenChartCreationCurtain,
    setIsOpenDatasetCreationCurtain,
    roles,
    addDangerToast,
  } = props;

  const { t } = useTranslation();
  const {
    handleCreateChart,
    handleCreateDataset,
    handleCreateDatabases,
    handleCreateDashboard,
  } = useCreateHandlers(
    userId,
    addDangerToast,
    setIsOpenChartCreationCurtain,
    setIsOpenDatasetCreationCurtain,
  );

  const canDashboard = findPermission('can_write', 'Dashboard', roles);
  const canChart = findPermission('can_write', 'Chart', roles);
  const canDatabase = findPermission('can_write', 'Database', roles);
  const canDataset = findPermission('can_write', 'Dataset', roles);

  return [
    {
      title: t('Dashboards'),
      description: t(
        'Combine charts and visuals into dashboards, interactive reports, and make sure your data tells exactly what you intended',
      ),
      image: <DashboardPreview />,
      onClick: handleCreateDashboard,
      canCreate: canDashboard,
    },
    {
      title: t('Charts'),
      description: t(
        'Explore your data with a variety of visualization types, from tables and line charts to interactive maps',
      ),
      image: <ChartPreview />,
      onClick: handleCreateChart,
      canCreate: canChart,
    },
    {
      title: t('Datasets'),
      description: t(
        'Create the samples and datasets you need, add calculated fields and metrics to create the data set that suits your needs',
      ),
      image: <DatasetPreview />,
      onClick: handleCreateDataset,
      canCreate: canDataset,
    },
    {
      title: t('Databases'),
      description: t(
        'Leverage connectivity to a wide range of databases to extract, filter, and aggregate data for deeper analysis',
      ),
      image: <DatabasePreview />,
      onClick: handleCreateDatabases,
      canCreate: canDatabase,
      buttonText: t('Connect'),
    },
  ];
};
