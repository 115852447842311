import { MenuProps } from 'antd/lib/menu';
import { mappedMenu } from '../mappedMenu';
import { filterByPermission } from '../filterByPermission';
import { CreateProps, Items } from '../../interfaces';

export const filterMenu = (
  items: Items,
  roles: CreateProps['roles'],
): MenuProps['items'] =>
  items
    .filter(item => filterByPermission(item, roles))
    .map(item => {
      if (item.children?.length) {
        const filteredChildren = item.children
          .filter(child => filterByPermission(child, roles))
          .map(mappedMenu);
        return {
          ...item,
          children: [...filteredChildren],
        };
      }
      return item;
    })
    .map(mappedMenu);
