import React from 'react';
import { HeartTwoTone } from '@ant-design/icons';
import './styles.less';
import { useTranslation } from 'react-i18next';

/** Заголовок модального окна пре-релиза */
export const Title = () => {
  const { t } = useTranslation();
  return (
    <div className="release-modal-title">
      <span className="release-modal-title__text">
        {t('We have released an update')}
      </span>
      <HeartTwoTone />
    </div>
  );
};
