import { PreparedPath } from '../../interfaces';

export const preparePathLength = (name: string, id: string) => {
  const preparedPath: PreparedPath = { name, id };
  if (name.length > 15) {
    preparedPath.name = `${name.slice(0, 15)}...`;
    preparedPath.fullName = name;
  }
  return preparedPath;
};
