import React, { FC, memo } from 'react';
import { Button as AntdButton } from 'antd';
import cn from 'classnames';
import { ButtonProps } from './interfaces';
import { Tooltip } from '../../components/Tooltip';
import './styles.less';

export const Button: FC<ButtonProps> = memo(props => {
  const {
    children,
    type = 'primary',
    icon,
    size = 'middle',
    onClick,
    className,
    dataTestId,
    tooltip,
    isLoading,
    isDanger,
    isDisabled,
    isBordered,
    ...rest
  } = props;

  const button = (
    <AntdButton
      data-test={dataTestId}
      type={type}
      icon={icon}
      size={size}
      onClick={onClick}
      loading={isLoading}
      className={cn('bi-button', className, {
        'bi-button--bordered': isBordered,
      })}
      danger={isDanger}
      disabled={isDisabled}
      {...rest}
    >
      {children}
    </AntdButton>
  );

  if (tooltip) {
    return <Tooltip {...tooltip}>{button}</Tooltip>;
  }

  return button;
});
