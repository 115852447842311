import React, { memo } from 'react';
import { Table } from '../../../../hooks/apiResources';
import Icons from '../../../Icons';
import CertifiedBadge from '../../../CertifiedBadge';
import WarningIconWithTooltip from '../../../WarningIconWithTooltip';
import './styles.less';

export const TableOption = memo(({ table }: { table: Table }) => {
  const { value, type, extra } = table;
  return (
    <span className="table-option" title={value}>
      {type === 'view' ? (
        <Icons.Eye iconSize="m" />
      ) : (
        <Icons.Table iconSize="m" />
      )}
      {extra?.certification && (
        <CertifiedBadge
          certifiedBy={extra.certification.certified_by}
          details={extra.certification.details}
          size="l"
        />
      )}
      {extra?.warning_markdown && (
        <WarningIconWithTooltip
          warningMarkdown={extra.warning_markdown}
          size="l"
          marginRight={4}
        />
      )}
      {value}
    </span>
  );
});
