import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderListForModalProps } from './interfaces';

/** Лист папок для МО добавления папки / дэшборда */
export const FolderListForModal: FC<FolderListForModalProps> = memo(props => {
  const { folders, setPath } = props;
  const { t } = useTranslation();
  const updateFolderPath = (name: string, id: number) => () => {
    setPath?.(prev => {
      if (!prev.names) {
        return {
          names: name,
          ids: id.toString(),
        };
      }
      return {
        names: `${prev.names}~${name}`,
        ids: `${prev.ids}~${id}`,
      };
    });
  };
  return (
    <>
      {folders?.map(({ name, id }) => (
        <div
          key={id}
          role="none"
          onClick={updateFolderPath(name, id)}
          className="dashboards-and-folders-list__list dashboards-and-folders-list__list--clicable"
        >
          <span className="add-to-folder-modal__table-name">{name}</span>
          <span className="add-to-folder-modal__table-type">{t('Folder')}</span>
        </div>
      ))}
    </>
  );
});
