import React from 'react';
import DoubleRightArrow from '../../assets/images/doubleRightArrow.svg';
import DoubleLeftArrow from '../../assets/images/doubleLeftArrow.svg';

export const NextArrow = (props: { onClick?: () => void }) => {
  const { onClick } = props;
  return (
    <div
      data-test="next-arrow"
      className="next-arrow"
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <DoubleRightArrow />
    </div>
  );
};

export const PrevArrow = (props: { onClick?: () => void }) => {
  const { onClick } = props;
  return (
    <div
      data-test="prev-arrow"
      className="prev-arrow"
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <DoubleLeftArrow />
    </div>
  );
};
