import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPropTypes } from 'src/features/databases/types';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';

export const UsernameField = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  db,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  return (
    <LabeledErrorBoundInput
      id="username"
      name="username"
      isRequired={isRequired}
      value={db?.parameters?.username}
      validationMethods={{ onBlur: getValidation }}
      errorMessage={validationErrors?.username}
      placeholder={t('e.g. Analytics')}
      label={t('Username')}
      onChange={changeMethods.onParametersChange}
    />
  );
};
