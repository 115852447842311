import { findPermission } from '../../../../../../utils/findPermission';
import { CreateProps, Item } from '../../interfaces';

export const filterByPermission = (item: Item, roles: CreateProps['roles']) => {
  if (item.perm === undefined) {
    return true;
  }
  if (typeof item.perm === 'boolean') {
    return item.perm;
  }
  if (typeof item.perm === 'string') {
    return findPermission(item.perm as string, item.view as string, roles);
  }
  return true;
};
