import React, { FC } from 'react';
import { Form } from 'antd';
import { DatabaseConnectionFormProps } from './interfaces';
import { FORM_FIELD_MAP, FormFieldOrder } from './constants';

export const DatabaseConnectionForm: FC<
  DatabaseConnectionFormProps
> = props => {
  const {
    dbModel,
    db,
    getPlaceholder,
    getValidation,
    clearValidationErrors,
    validationErrors,
    isEditMode,
    isEditNewDb,
    onChange,
    onExtraInputChange,
    onParametersChange,
    onQueryChange,
    onParametersUploadFileChange,
    onAddTableCatalog,
    onRemoveTableCatalog,
    isSslForced,
  } = props;
  const parameters = dbModel?.parameters;

  return (
    <Form layout="vertical">
      <>
        {parameters &&
          FormFieldOrder.filter(
            (key: string) =>
              Object.keys(parameters.properties).includes(key) ||
              key === 'database_name',
          ).map(field =>
            FORM_FIELD_MAP[field]({
              isRequired: parameters.required?.includes(field),
              changeMethods: {
                onParametersChange,
                onChange,
                onQueryChange,
                onParametersUploadFileChange,
                onAddTableCatalog,
                onRemoveTableCatalog,
                onExtraInputChange,
              },
              validationErrors,
              getValidation,
              clearValidationErrors,
              db,
              key: field,
              field,
              isEditMode,
              isSslForced,
              isEditNewDb,
              placeholder: getPlaceholder ? getPlaceholder(field) : undefined,
            }),
          )}
      </>
    </Form>
  );
};
