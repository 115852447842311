import React, { FC, memo } from 'react';
import { CarouselContent } from '../CarouselContent';
import { Carousel } from '../../../../components/Carousel';
import { useGetConfig } from './hooks/useGetConfig';
import './styles.less';

/** Блок с каруселью */
export const CarouselBlock: FC = memo(() => {
  const carouselConfig = useGetConfig();
  return (
    <div className="carousel-block">
      <Carousel>
        {carouselConfig.map(({ title, description, buttons, image }, index) => (
          <CarouselContent
            key={`${title}${index}`}
            image={image}
            title={title}
            description={description}
            buttons={buttons}
          />
        ))}
      </Carousel>
    </div>
  );
});
