import React, { FC, memo } from 'react';
import { Button } from 'antd';
import { CarouselContentProps } from './interfaces';
import './styles.less';

/** Контент карусели */
export const CarouselContent: FC<CarouselContentProps> = memo(props => {
  const { image, buttons, title, description } = props;
  return (
    <div className="carousel-content">
      <div className="carousel-content__content">
        <h1 className="carousel-content__title">{title}</h1>
        <p className="carousel-content__description">{description}</p>
        <div className="carousel-content__buttons">
          {buttons.map(({ label, type, onClick, dataTestId }, index) => (
            <Button
              key={index}
              className="carousel-content__button"
              type={type}
              onClick={onClick}
              data-test={dataTestId}
            >
              {label}
            </Button>
          ))}
        </div>
      </div>
      <div className="carousel-content__image">{image}</div>
    </div>
  );
});
