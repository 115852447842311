import React, { memo } from 'react';
import cn from 'classnames';
import { SyncOutlined } from '@ant-design/icons';
import { Tooltip } from 'src/components/Tooltip';
import { RefreshLabelProps, Size } from './interfaces';
import './styles.less';

/** Компонент рефреша датасетов */
export const RefreshLabel = memo(
  ({
    onClick,
    tooltipContent,
    disabled,
    size = Size.M,
    className,
  }: RefreshLabelProps) => (
    <Tooltip title={tooltipContent}>
      <SyncOutlined
        disabled={disabled}
        onClick={onClick}
        className={cn(
          'refresh-label',
          {
            'refresh-label__small': size === Size.S,
            'refresh-label__medium': size === Size.M,
            'refresh-label__large': size === Size.L,
          },
          className,
        )}
      />
    </Tooltip>
  ),
);
