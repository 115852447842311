import React from 'react';
import './styles.less';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyList from 'src/assets/images/emptyPlugImage.svg';

export const ChooseDatasetStub = () => {
  const { t } = useTranslation();

  return (
    <div className="choose-dataset-stub">
      <div className="choose-dataset-stub__image">
        <EmptyList />
      </div>
      <h5 className="choose-dataset-stub__title">
        {t('Select dataset source')}
      </h5>
      <div className="choose-dataset-stub__description">
        <span>
          {t('Datasets can be created from database tables or SQL queries.')}
        </span>
        <span>
          {t('Select a database table to the left or ')}
          <Link
            data-test="create-dataset"
            className="choose-dataset-stub__link"
            to="/sqllab"
          >
            {t('create dataset from SQL query')}
          </Link>
          {t(' to open SQL Lab.')}
        </span>
        {t('From there you can save the query as a dataset.')}
      </div>
    </div>
  );
};
