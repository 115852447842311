import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPropTypes } from 'src/features/databases/types';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';

export const HostField = ({
  isRequired,
  changeMethods,
  getValidation,
  validationErrors,
  db,
  field,
}: FieldPropTypes) => {
  const { t } = useTranslation();
  return (
    <LabeledErrorBoundInput
      id={field}
      name={field}
      value={db?.parameters?.host}
      isRequired={isRequired}
      hasTooltip
      tooltipText={t(
        'This can be either an IP address (e.g. 127.0.0.1) or a domain name (e.g. mydatabase.com).',
      )}
      validationMethods={{ onBlur: getValidation }}
      errorMessage={validationErrors?.host}
      placeholder={t('e.g. 127.0.0.1')}
      label={t('Host')}
      onChange={changeMethods.onParametersChange}
    />
  );
};
