import React from 'react';
import { NextArrow, PrevArrow } from './utils';

export const settings = {
  className: 'main-page-slider',
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
