export enum TABLE_EXIST {
  FAIL = 'fail',
  REPLACE = 'replace',
  APPEND = 'append',
}

export const defaultValues = {
  header_row: 0,
  null_values: [
    '',
    '#N/A',
    'n/a',
    '-1.#QNAN',
    '#N/A N/A',
    'nan',
    'NULL',
    '1.#IND',
    '#NA',
    'None',
    '-1.#IND',
    '-NaN',
    'NaN',
    '-nan',
    '1.#QNAN',
    'null',
    'NA',
    'N/A',
    '<NA>',
  ],
};
