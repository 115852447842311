import { TranslateFunc } from 'src/types/interfaces';

export const getFieldTextMap = (t: TranslateFunc) => ({
  account: {
    helpText: t(
      'Copy the identifier of the account you are trying to connect to.',
    ),
    placeholder: t('e.g. xy12345.us-east-2.aws'),
  },
  warehouse: {
    placeholder: t('e.g. compute_wh'),
    className: 'form-group-w-50',
  },
  role: {
    placeholder: t('e.g. AccountAdmin'),
    className: 'form-group-w-50',
  },
});
