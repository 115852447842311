import { useTranslation } from 'react-i18next';
import React, {
  FC,
  useState,
  ReactNode,
  useMemo,
  useEffect,
  memo,
} from 'react';
import { SelectValue } from 'antd/lib/select';
import { Select } from 'src/components';
import { FormLabel } from 'src/components/Form';
import { DatabaseSelector } from 'src/components/DatabaseSelector';
import { DatabaseObject } from 'src/components/DatabaseSelector/interfaces';
import { RefreshLabel } from 'src/components/RefreshLabel';
import { useToasts } from 'src/components/MessageToasts/withToasts';
import { useTables } from 'src/hooks/apiResources';
import {
  getClientErrorMessage,
  getClientErrorObject,
} from 'src/utils/getClientErrorObject';
import { SelectTableOption, TableSelectorProps } from './interfaces';
import { TableOption } from './components/TableOption';
import './styles.less';

export const TableSelector: FC<TableSelectorProps> = memo(
  ({
    database,
    emptyState,
    isFormMode = false,
    getDbList,
    handleError,
    isDatabaseSelectEnabled = true,
    onDbChange,
    onSchemaChange,
    isReadOnly = false,
    onEmptyResults,
    schema,
    isSqlLabMode = true,
    tableSelectMode = 'single',
    tableValue = undefined,
    onTableSelectChange,
    customTableOptionLabelRenderer,
  }) => {
    const { t } = useTranslation();
    const { addSuccessToast } = useToasts();
    const [currentSchema, setCurrentSchema] = useState<string | undefined>(
      schema,
    );
    const [tableSelectValue, setTableSelectValue] = useState<
      SelectValue | undefined
    >(undefined);
    const {
      data,
      isFetching: loadingTables,
      refetch,
    } = useTables({
      dbId: database?.id,
      schema: currentSchema,
      onSuccess: (data, isFetched) => {
        if (isFetched) {
          addSuccessToast(t('List updated'));
        }
      },
      onError: err => {
        getClientErrorObject(err).then(clientError => {
          handleError(
            getClientErrorMessage(
              t('There was an error loading the tables'),
              clientError,
            ),
          );
        });
      },
    });

    const tableOptions = useMemo<SelectTableOption[]>(
      () =>
        data
          ? data.options.map(table => ({
              value: table.value,
              label: <TableOption table={table} />,
              text: table.value,
              ...(customTableOptionLabelRenderer && {
                customLabel: customTableOptionLabelRenderer(table),
              }),
            }))
          : [],
      [data, customTableOptionLabelRenderer],
    );

    useEffect(() => {
      // reset selections
      if (database === undefined) {
        setCurrentSchema(undefined);
        setTableSelectValue(undefined);
      }
    }, [database, tableSelectMode]);

    useEffect(() => {
      if (tableSelectMode === 'single') {
        setTableSelectValue(
          tableOptions.find(option => option.value === tableValue),
        );
      } else {
        setTableSelectValue(
          tableOptions?.filter(
            option => option && tableValue?.includes(option.value),
          ) || [],
        );
      }
    }, [tableOptions, tableValue, tableSelectMode]);

    const internalTableChange = (
      selectedOptions: SelectTableOption | SelectTableOption[] | undefined,
    ) => {
      if (currentSchema) {
        onTableSelectChange?.(
          Array.isArray(selectedOptions)
            ? selectedOptions.map(option => option?.value)
            : selectedOptions?.value,
          currentSchema,
        );
      } else {
        setTableSelectValue(selectedOptions);
      }
    };

    const internalDbChange = (db: DatabaseObject) => {
      if (onDbChange) {
        onDbChange(db);
      }
    };

    const internalSchemaChange = (schema?: string) => {
      setCurrentSchema(schema);
      if (onSchemaChange) {
        onSchemaChange(schema);
      }

      const value = tableSelectMode === 'single' ? undefined : [];
      internalTableChange(value);
    };

    const handleFilterOption = useMemo(
      () => (search: string, option: SelectTableOption) => {
        const searchValue = search.trim().toLowerCase();
        const { value } = option;
        return value.toLowerCase().includes(searchValue);
      },
      [],
    );

    const renderSelectRow = (select: ReactNode, refreshBtn: ReactNode) => (
      <div className="table-selector__section">
        <div className="table-selector__select-container">{select}</div>
        {refreshBtn}
      </div>
    );

    const renderTableSelect = () => {
      const disabled =
        (currentSchema && !isFormMode && isReadOnly) || !currentSchema;

      const header = isSqlLabMode ? (
        <FormLabel>{t('See table schema')}</FormLabel>
      ) : (
        <FormLabel>{t('Table')}</FormLabel>
      );

      const select = (
        <Select
          dataTestId="select-table"
          ariaLabel={t('Select table or type to search tables')}
          disabled={disabled}
          filterOption={handleFilterOption}
          header={header}
          labelInValue
          loading={loadingTables}
          name="select-table"
          onChange={(options: SelectTableOption | SelectTableOption[]) =>
            internalTableChange(options)
          }
          options={tableOptions}
          placeholder={t('Select table or type to search tables')}
          showSearch
          mode={tableSelectMode}
          value={tableSelectValue}
          allowClear={tableSelectMode === 'multiple'}
          allowSelectAll={false}
        />
      );

      const refreshLabel = !isReadOnly && (
        <RefreshLabel
          onClick={refetch}
          size="s"
          tooltipContent={t('Force refresh table list')}
          className="table-selector__refresh-button"
        />
      );

      return renderSelectRow(select, refreshLabel);
    };

    return (
      <div className="table-selector">
        <DatabaseSelector
          db={database}
          emptyState={emptyState}
          isFormMode={isFormMode}
          getDbList={getDbList}
          handleError={handleError}
          onDbChange={isReadOnly ? undefined : internalDbChange}
          onEmptyResults={onEmptyResults}
          onSchemaChange={isReadOnly ? undefined : internalSchemaChange}
          schema={currentSchema}
          isSqlLabMode={isSqlLabMode}
          isDatabaseSelectEnabled={isDatabaseSelectEnabled && !isReadOnly}
          isReadOnly={isReadOnly}
        />
        {isSqlLabMode && !isFormMode && (
          <div className="table-selector__divider" />
        )}
        {renderTableSelect()}
      </div>
    );
  },
);

export const TableSelectorMultiple: FC<TableSelectorProps> = memo(props => (
  <TableSelector tableSelectMode="multiple" {...props} />
));
