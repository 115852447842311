import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocumentationLink } from './utils/getDocumentationLink';
import { CurtainHeaderProps } from './interfaces';
import { DOCUMENTATION_LINK } from './constants';
import './styles.less';

export const CurtainHeader: FC<CurtainHeaderProps> = props => {
  const {
    fileList,
    db,
    dbName,
    dbModel,
    isEditNewDb,
    isEditMode,
    isUseSqlAlchemyForm,
    hasConnectedDb,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const isFileCheck = fileList && fileList.length > 0;

  const isEditHeader = (
    <header className="form-header">
      <p className="form-header__title">{db?.backend}</p>
      <p className="form-header__subtitle">{dbName}</p>
    </header>
  );

  const useSqlAlchemyFormHeader = (
    <header className="form-header">
      <p className="form-header__step-info">
        {t('Step {{stepCurr}} of {{stepLast}}', {
          stepCurr: 2,
          stepLast: 2,
        })}
      </p>
      <p className="form-header__additional-info">
        {t('Enter Primary Credentials')}
      </p>
      <p className="form-header__helper-text">
        {t('Need help? Learn how to connect your database')}{' '}
        <a href={DOCUMENTATION_LINK} target="_blank" rel="noopener noreferrer">
          {t('here')}
        </a>
        .
      </p>
    </header>
  );

  const hasConnectedDbHeader = (
    <header className="form-header">
      <p className="form-header__step-info">
        {t('Step {{stepCurr}} of {{stepLast}}', {
          stepCurr: 3,
          stepLast: 3,
        })}
      </p>
      <p className="form-header__additional-info">{t('Database connected.')}</p>
      <p className="form-header__additional-info">
        {t(`Create a dataset to begin visualizing your data as a chart or go to
          SQL Lab to query your data.`)}
      </p>
    </header>
  );

  const hasDbHeader = (
    <header className="form-header">
      <p className="form-header__step-info">
        {t('Step {{stepCurr}} of {{stepLast}}', {
          stepCurr: 2,
          stepLast: 3,
        })}
      </p>
      <p className="form-header__additional-info">
        {t('Enter the required {{dbModelName}} credentials', {
          dbModelName: dbModel.name,
        })}
      </p>
      <p className="form-header__helper-text">
        {t('Need help? Learn more about')}{' '}
        <a
          href={getDocumentationLink(db?.engine)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('connecting to {{dbModelName}}', { dbModelName: dbModel.name })}.
        </a>
      </p>
    </header>
  );

  const noDbHeader = (
    <header className="form-header">
      <p className="form-header__step-info">
        {t('Step {{stepCurr}} of {{stepLast}}', {
          stepCurr: 1,
          stepLast: 3,
        })}
      </p>
      <p className="form-header__additional-info">
        {t('Select a database to connect')}
      </p>
    </header>
  );

  const importDbHeader = (
    <header className="form-header">
      <p className="form-header__step-info">
        {t('Step {{stepCurr}} of {{stepLast}}', {
          stepCurr: 2,
          stepLast: 2,
        })}
      </p>
      <p className="form-header__additional-info">
        {t('Enter the required {{dbModelName}} credentials', {
          dbModelName: dbModel.name,
        })}
      </p>
      <p className="form-header__helper-text">
        {isFileCheck ? fileList[0].name : ''}
      </p>
    </header>
  );

  if (isLoading) {
    return <></>;
  }

  if (isFileCheck) {
    return importDbHeader;
  }

  if (isEditMode) {
    return isEditHeader;
  }

  if (isUseSqlAlchemyForm) {
    return useSqlAlchemyFormHeader;
  }

  if (hasConnectedDb && !isEditNewDb) {
    return hasConnectedDbHeader;
  }

  if (db || isEditNewDb) {
    return hasDbHeader;
  }

  return noDbHeader;
};
