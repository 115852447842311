import { getExtensionsRegistry } from '@superset-ui/core';
import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useReducer,
  Reducer,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import { setItem, LocalStorageKeys } from 'src/utils/localStorageHelpers';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Select, Upload } from 'src/components';
import Alert from 'src/components/Alert';
import { Button } from 'src/newComponents/Button';
import { InfoTooltip } from 'src/newComponents/InfoTooltip';
import withToasts from 'src/components/MessageToasts/withToasts';
import { LabeledErrorBoundInput } from 'src/components/Form/components/LabeledErrorBoundInput';
import ErrorMessageWithStackTrace from 'src/components/ErrorMessage/ErrorMessageWithStackTrace';
import ErrorAlert from 'src/components/ImportModal/ErrorAlert';
import {
  testDatabaseConnection,
  useSingleViewResource,
  useAvailableDatabases,
  useDatabaseValidation,
  useImportResource,
} from 'src/views/CRUD/hooks';
import { useCommonConf } from 'src/features/databases/state';
import { withDatasetCreationCurtain } from 'src/features/datasets/CreateDatasetCurtain/hoc/withCreateDatasetCurtain';
import { Curtain } from '../../../components/Curtain';
import { BI_PORTAL_ID } from '../../../constants';
import { FormLabel } from '../../../components/Form';
import {
  DatabaseObject,
  DatabaseForm,
  ConfigurationMethod,
  Engines,
  CustomTextType,
} from '../types';
import { ExtraOptions } from './components/ExtraOptions';
import { SSHTunnelForm } from './components/SSHTunnelForm';
import { SSHTunnelSwitch } from './components/SSHTunnelSwitch';
import { CurtainHeader } from './components/CurtainHeader';
import { DOCUMENTATION_LINK } from './components/CurtainHeader/constants';
import { SqlAlchemyTab } from './components/SqlAlchemyTab';
import {
  ActionType,
  AuthType,
  DatabaseCurtainComponentProps,
  DatabaseCurtainProps,
  DBReducerActionType,
  DBReducerPayloadType,
} from './interfaces';
import dbReducer from './reducers';
import { DEFAULT_TAB_KEY, engineSpecificAlertMapping } from './constants';
import { IconCard } from './components/IconCard';
import { DatabaseConnectionForm } from './components/DatabaseConnectionForm';
import './styles.less';

const extensionsRegistry = getExtensionsRegistry();

const DatabaseCurtain: FC<DatabaseCurtainComponentProps> = ({
  addDangerToast,
  addSuccessToast,
  onDatabaseAdd,
  isOpen,
  onClose,
  databaseId,
  dbEngine,
  setIsOpenDatasetCreationCurtain,
}) => {
  const { t } = useTranslation();
  const [db, setDB] = useReducer<
    Reducer<Partial<DatabaseObject> | null, DBReducerActionType>
  >(dbReducer, null);

  const {
    state: { loading: dbLoading, resource: dbFetched, error: dbErrors },
    fetchResource,
    createResource,
    updateResource,
    clearError,
  } = useSingleViewResource<DatabaseObject>(
    'database',
    t('database'),
    addDangerToast,
    'connection',
  );

  const [tabKey, setTabKey] = useState(DEFAULT_TAB_KEY);
  const [availableDbs, getAvailableDbs] = useAvailableDatabases();
  const [validationErrors, getValidation, setValidationErrors] =
    useDatabaseValidation();
  const [hasConnectedDb, setHasConnectedDb] = useState(false);
  const [isShowButtons, setIsShowButtons] = useState(false);
  const [dbName, setDbName] = useState('');
  const [isEditNewDb, setIsEditNewDb] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isTestInProgress, setIsTestInProgress] = useState(false);
  const [passwords, setPasswords] = useState<Record<string, string>>({});
  const [sshTunnelPasswords, setSSHTunnelPasswords] = useState<
    Record<string, string>
  >({});
  const [sshTunnelPrivateKeys, setSSHTunnelPrivateKeys] = useState<
    Record<string, string>
  >({});
  const [sshTunnelPrivateKeyPasswords, setSSHTunnelPrivateKeyPasswords] =
    useState<Record<string, string>>({});
  const [isConfirmedOverwrite, setIsConfirmedOverwrite] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isImportingCurtain, setIsImportingCurtain] = useState(false);
  const [importingErrorMessage, setImportingErrorMessage] =
    useState<string>('');
  const [passwordFields, setPasswordFields] = useState<string[]>([]);
  const [sshTunnelPasswordFields, setSSHTunnelPasswordFields] = useState<
    string[]
  >([]);
  const [sshTunnelPrivateKeyFields, setSSHTunnelPrivateKeyFields] = useState<
    string[]
  >([]);
  const [
    sshTunnelPrivateKeyPasswordFields,
    setSSHTunnelPrivateKeyPasswordFields,
  ] = useState<string[]>([]);
  const [extraExtensionComponentState, setExtraExtensionComponentState] =
    useState<object>({});

  const SSHTunnelSwitchComponent =
    extensionsRegistry.get('ssh_tunnel.form.switch') ?? SSHTunnelSwitch;

  const [isUseSSHTunneling, setIsUseSSHTunneling] = useState<
    boolean | undefined
  >(undefined);

  let dbConfigExtraExtension = extensionsRegistry.get(
    'databaseconnection.extraOption',
  );

  if (dbConfigExtraExtension) {
    // add method for db to store data
    dbConfigExtraExtension = {
      ...dbConfigExtraExtension,
      onEdit: componentState => {
        setExtraExtensionComponentState({
          ...extraExtensionComponentState,
          ...componentState,
        });
      },
    };
  }

  const conf = useCommonConf();
  const isEditMode = !!databaseId;
  const hasAlert = !!(db?.engine && engineSpecificAlertMapping[db.engine]);
  const isUseSqlAlchemyForm =
    db?.configuration_method === ConfigurationMethod.SqlalchemyUri;
  const useTabLayout = isEditMode || isUseSqlAlchemyForm;
  const isDynamic = (engine: string | undefined) =>
    availableDbs?.databases?.find(
      (DB: DatabaseObject) => DB.backend === engine || DB.engine === engine,
    )?.parameters !== undefined;
  const showDBError = validationErrors || dbErrors;
  const history = useHistory();

  const dbModel: DatabaseForm =
    availableDbs?.databases?.find(
      (available: { engine: string | undefined }) =>
        // TODO: we need a centralized engine in one place
        available.engine === (isEditMode ? db?.backend : db?.engine),
    ) || {};

  // Test Connection logic
  const testConnection = () => {
    if (!db?.sqlalchemy_uri) {
      addDangerToast(t('Please enter a SQLAlchemy URI to test'));
      return;
    }

    const connection = {
      sqlalchemy_uri: db?.sqlalchemy_uri || '',
      database_name: db?.database_name?.trim() || undefined,
      impersonate_user: db?.impersonate_user || undefined,
      extra: db?.extra,
      masked_encrypted_extra: db?.masked_encrypted_extra || '',
      server_cert: db?.server_cert || undefined,
      ssh_tunnel:
        !isEmpty(db?.ssh_tunnel) && isUseSSHTunneling
          ? {
              ...db.ssh_tunnel,
              server_port: Number(db.ssh_tunnel!.server_port),
            }
          : undefined,
    };
    setIsTestInProgress(true);
    testDatabaseConnection(
      connection,
      (errorMsg: string) => {
        setIsTestInProgress(false);
        addDangerToast(errorMsg);
      },
      (errorMsg: string) => {
        setIsTestInProgress(false);
        addSuccessToast(errorMsg);
      },
      t,
    );
  };

  const getPlaceholder = (field: string) => {
    if (field === 'database') {
      return t('e.g. world_population');
    }
    return undefined;
  };

  const removeFile = (removedFile: UploadFile) => {
    setFileList(fileList.filter(file => file.uid !== removedFile.uid));
    return false;
  };

  const onChange = useCallback(
    (
      type: DBReducerActionType['type'],
      payload: CustomTextType | DBReducerPayloadType,
    ) => {
      setDB({ type, payload } as DBReducerActionType);
    },
    [],
  );

  const handleClearValidationErrors = useCallback(() => {
    setValidationErrors(null);
  }, [setValidationErrors]);

  const handleParametersChange = useCallback(
    ({ target }: { target: HTMLInputElement }) => {
      onChange(ActionType.ParametersChange, {
        type: target.type,
        name: target.name,
        checked: target.checked,
        value: target.value,
      });
    },
    [onChange],
  );

  const handleClose = () => {
    setDB({ type: ActionType.Reset });
    setHasConnectedDb(false);
    handleClearValidationErrors(); // reset validation errors on close
    clearError();
    setIsEditNewDb(false);
    setFileList([]);
    setIsImportingCurtain(false);
    setImportingErrorMessage('');
    setPasswordFields([]);
    setSSHTunnelPasswordFields([]);
    setSSHTunnelPrivateKeyFields([]);
    setSSHTunnelPrivateKeyPasswordFields([]);
    setPasswords({});
    setSSHTunnelPasswords({});
    setSSHTunnelPrivateKeys({});
    setSSHTunnelPrivateKeyPasswords({});
    setIsConfirmedOverwrite(false);
    setIsUseSSHTunneling(undefined);
    onClose();
  };

  const redirectURL = (url: string) => {
    history.push(url);
  };

  // Database import logic
  const {
    state: {
      alreadyExists,
      passwordsNeeded,
      sshPasswordNeeded,
      sshPrivateKeyNeeded,
      sshPrivateKeyPasswordNeeded,
      loading: importLoading,
      failed: importErrored,
    },
    importResource,
  } = useImportResource('database', t('database'), msg => {
    setImportingErrorMessage(msg);
  });

  const onSave = async () => {
    let dbConfigExtraExtensionOnSaveError;

    setLoading(true);

    dbConfigExtraExtension
      ?.onSave(extraExtensionComponentState, db)
      .then(({ error }: { error: any }) => {
        if (error) {
          dbConfigExtraExtensionOnSaveError = error;
          addDangerToast(error);
        }
      });
    if (dbConfigExtraExtensionOnSaveError) {
      setLoading(false);
      return;
    }
    // Clone DB object
    const dbToUpdate = { ...(db || {}) };

    if (dbToUpdate.configuration_method === ConfigurationMethod.DynamicForm) {
      // Validate DB before saving
      if (dbToUpdate?.parameters?.catalog) {
        // need to stringify gsheets catalog to allow it to be serialized
        dbToUpdate.extra = JSON.stringify({
          ...JSON.parse(dbToUpdate.extra || '{}'),
          engine_params: {
            catalog: dbToUpdate.parameters.catalog,
          },
        });
      }

      const errors = await getValidation(dbToUpdate, true);
      if (!isEmpty(validationErrors) || errors?.length) {
        addDangerToast(
          t('Connection failed, please check your connection settings.'),
        );
        setLoading(false);
        return;
      }

      const parameters_schema = isEditMode
        ? dbToUpdate.parameters_schema?.properties
        : dbModel?.parameters.properties;
      const additionalEncryptedExtra = JSON.parse(
        dbToUpdate.masked_encrypted_extra || '{}',
      );
      const paramConfigArray = Object.keys(parameters_schema || {});

      paramConfigArray.forEach(paramConfig => {
        /*
         * Parameters that are annotated with the `x-encrypted-extra` properties should be
         * moved to `masked_encrypted_extra`, so that they are stored encrypted in the
         * backend when the database is created or edited.
         */
        if (
          parameters_schema[paramConfig]['x-encrypted-extra'] &&
          dbToUpdate.parameters?.[paramConfig]
        ) {
          if (typeof dbToUpdate.parameters?.[paramConfig] === 'object') {
            // add new encrypted extra to masked_encrypted_extra object
            additionalEncryptedExtra[paramConfig] =
              dbToUpdate.parameters?.[paramConfig];
            // The backend expects `masked_encrypted_extra` as a string for historical
            // reasons.
            dbToUpdate.parameters[paramConfig] = JSON.stringify(
              dbToUpdate.parameters[paramConfig],
            );
          } else {
            additionalEncryptedExtra[paramConfig] = JSON.parse(
              dbToUpdate.parameters?.[paramConfig] || '{}',
            );
          }
        }
      });
      // cast the new encrypted extra object into a string
      dbToUpdate.masked_encrypted_extra = JSON.stringify(
        additionalEncryptedExtra,
      );
      // this needs to be added by default to gsheets
      if (dbToUpdate.engine === Engines.GSheet) {
        dbToUpdate.impersonate_user = true;
      }
    }

    if (dbToUpdate?.parameters?.catalog) {
      // need to stringify gsheets catalog to allow it to be serialized
      dbToUpdate.extra = JSON.stringify({
        ...JSON.parse(dbToUpdate.extra || '{}'),
        engine_params: {
          catalog: dbToUpdate.parameters.catalog,
        },
      });
    }

    // strictly checking for false as an indication that the toggle got unchecked
    if (isUseSSHTunneling === false) {
      // remove ssh tunnel
      dbToUpdate.ssh_tunnel = null;
    }

    if (db?.id) {
      const result = await updateResource(
        db.id as number,
        dbToUpdate as DatabaseObject,
        dbToUpdate.configuration_method === ConfigurationMethod.DynamicForm, // onShow toast on SQLA Forms
      );
      if (result) {
        if (onDatabaseAdd) {
          onDatabaseAdd();
        }
        dbConfigExtraExtension
          ?.onSave(extraExtensionComponentState, db)
          .then(({ error }: { error: any }) => {
            if (error) {
              dbConfigExtraExtensionOnSaveError = error;
              addDangerToast(error);
            }
          });
        if (dbConfigExtraExtensionOnSaveError) {
          setLoading(false);
          return;
        }
        if (!isEditNewDb) {
          handleClose();
          addSuccessToast(t('Database settings updated'));
        }
      }
    } else if (db) {
      // Create
      const dbId = await createResource(
        dbToUpdate as DatabaseObject,
        dbToUpdate.configuration_method === ConfigurationMethod.DynamicForm, // onShow toast on SQLA Forms
      );
      if (dbId) {
        setHasConnectedDb(true);
        if (onDatabaseAdd) onDatabaseAdd();
        dbConfigExtraExtension
          ?.onSave(extraExtensionComponentState, db)
          .then(({ error }: { error: any }) => {
            if (error) {
              dbConfigExtraExtensionOnSaveError = error;
              addDangerToast(error);
            }
          });
        if (dbConfigExtraExtensionOnSaveError) {
          setLoading(false);
          return;
        }

        if (useTabLayout) {
          // tab layout only has one step
          // so it should close immediately on save
          handleClose();
          addSuccessToast(t('Database connected'));
        }
      }
    } else {
      // Import - doesn't use db state
      setIsImportingCurtain(true);

      if (!(fileList[0].originFileObj instanceof File)) {
        return;
      }

      const dbId = await importResource(
        fileList[0].originFileObj,
        passwords,
        sshTunnelPasswords,
        sshTunnelPrivateKeys,
        sshTunnelPrivateKeyPasswords,
        isConfirmedOverwrite,
      );
      if (dbId) {
        if (onDatabaseAdd) {
          onDatabaseAdd();
        }
        handleClose();
        addSuccessToast(t('Database connected'));
      }
    }

    setIsShowButtons(true);
    setIsEditNewDb(false);
    setLoading(false);
  };

  // Initialize
  const fetchDB = () => {
    if (isEditMode && databaseId) {
      if (!dbLoading) {
        fetchResource(databaseId).catch(e =>
          addDangerToast(
            t(
              'Sorry there was an error fetching database information: {{message}}',
              { message: e.message },
            ),
          ),
        );
      }
    }
  };

  const setDatabaseModel = (database_name: string) => {
    if (database_name === 'Other') {
      // Allow users to connect to DB via legacy SQLA form
      setDB({
        type: ActionType.DbSelected,
        payload: {
          database_name,
          configuration_method: ConfigurationMethod.SqlalchemyUri,
          engine: undefined,
          engine_information: {
            supports_file_upload: true,
          },
        },
      });
    } else {
      const selectedDbModel = availableDbs?.databases.filter(
        (db: DatabaseObject) => db.name === database_name,
      )[0];
      const {
        engine,
        parameters,
        engine_information,
        default_driver,
        sqlalchemy_uri_placeholder,
      } = selectedDbModel;
      const isDynamic = parameters !== undefined;
      setDB({
        type: ActionType.DbSelected,
        payload: {
          database_name,
          engine,
          configuration_method: isDynamic
            ? ConfigurationMethod.DynamicForm
            : ConfigurationMethod.SqlalchemyUri,
          engine_information,
          driver: default_driver,
          sqlalchemy_uri_placeholder,
        },
      });

      if (engine === Engines.GSheet) {
        // only create a catalog if the DB is Google Sheets
        setDB({ type: ActionType.AddTableCatalogSheet });
      }
    }
  };

  const renderAvailableSelector = () => {
    const options = [...(availableDbs?.databases || [])]
      ?.sort((a: DatabaseForm, b: DatabaseForm) => a.name.localeCompare(b.name))
      .map((database: DatabaseForm, index: number) => ({
        value: database.name,
        label: database.name,
      }));
    return (
      <div className="connect-form__available-block">
        <p className="connect-form__available-title">
          {t('Or choose from a list of other databases we support:')}
        </p>
        <Select
          className="connect-form__available-select"
          onChange={setDatabaseModel}
          placeholder={t('Choose a database')}
          showSearch
          options={[...options, { value: 'Other', label: t('Other') }]}
          header={<FormLabel>{t('Supported databases')}</FormLabel>}
        />
        <Alert
          className="connect-form__alert"
          showIcon
          type="info"
          message={t('Want to add a new database?')}
          description={
            <>
              {t(
                'Any databases that allow connections via SQL Alchemy URIs can be added. Learn about how to connect a database driver ',
              )}
              <a
                href={DOCUMENTATION_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('here')}
              </a>
              .
            </>
          }
        />
      </div>
    );
  };

  const renderPreferredSelector = () => (
    <div className="connect-form__select-card-block">
      {availableDbs?.databases
        ?.filter((db: DatabaseForm) => db.preferred)
        .map((database: DatabaseForm) => (
          <IconCard
            className="connect-form__select-card"
            onClick={() => setDatabaseModel(database.name)}
            cardText={database.name}
            key={`${database.name}`}
          />
        ))}
    </div>
  );

  const handleBackButtonOnFinish = () => {
    if (dbFetched) {
      fetchResource(dbFetched.id as number);
    }
    setIsShowButtons(false);
    setIsEditNewDb(true);
  };

  const handleBackButtonOnConnect = () => {
    if (isEditNewDb) {
      setHasConnectedDb(false);
    }
    if (isImportingCurtain) {
      setIsImportingCurtain(false);
    }
    if (importErrored) {
      setIsImportingCurtain(false);
      setImportingErrorMessage('');
      setPasswordFields([]);
      setSSHTunnelPasswordFields([]);
      setSSHTunnelPrivateKeyFields([]);
      setSSHTunnelPrivateKeyPasswordFields([]);
      setPasswords({});
      setSSHTunnelPasswords({});
      setSSHTunnelPrivateKeys({});
      setSSHTunnelPrivateKeyPasswords({});
    }
    setDB({ type: ActionType.Reset });
    setFileList([]);
  };

  const handleDisableOnImport = () => {
    if (
      importLoading ||
      (alreadyExists.length && !isConfirmedOverwrite) ||
      (passwordsNeeded.length && JSON.stringify(passwords) === '{}') ||
      (sshPasswordNeeded.length &&
        JSON.stringify(sshTunnelPasswords) === '{}') ||
      (sshPrivateKeyNeeded.length &&
        JSON.stringify(sshTunnelPrivateKeys) === '{}') ||
      (sshPrivateKeyPasswordNeeded.length &&
        JSON.stringify(sshTunnelPrivateKeyPasswords) === '{}')
    )
      return true;
    return false;
  };

  const renderFooter = () => {
    if (db) {
      // if db show back + connect
      if (!hasConnectedDb || isEditNewDb) {
        return (
          <>
            <Button
              key="back"
              onClick={handleBackButtonOnConnect}
              type="default"
              className="mr-8"
            >
              {t('Back')}
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={onSave}
              isLoading={isLoading}
            >
              {t('Connect')}
            </Button>
          </>
        );
      }

      return (
        <>
          <Button
            key="back"
            className="mr-8"
            onClick={handleBackButtonOnFinish}
            type="default"
          >
            {t('Back')}
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={onSave}
            dataTestId="confirm-button"
            isLoading={isLoading}
          >
            {t('Finish')}
          </Button>
        </>
      );
    }

    // Import doesn't use db state, so footer will not render in the if statement above
    if (isImportingCurtain) {
      return (
        <>
          <Button
            key="back"
            onClick={handleBackButtonOnConnect}
            className="mr-8"
            type="default"
          >
            {t('Back')}
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={onSave}
            isDisabled={handleDisableOnImport()}
            isLoading={isLoading}
          >
            {t('Connect')}
          </Button>
        </>
      );
    }

    return undefined;
  };

  const renderEditFooter = (db: Partial<DatabaseObject> | null) => (
    <>
      <Button key="close" onClick={handleClose} type="default" className="mr-8">
        {t('Close')}
      </Button>
      <Button
        key="submit"
        type="primary"
        onClick={onSave}
        isDisabled={db?.is_managed_externally}
        isLoading={isLoading}
        tooltip={{
          title: db?.is_managed_externally
            ? t(
                "This database is managed externally, and can't be edited in DataOps.BI",
              )
            : '',
        }}
      >
        {t('Finish')}
      </Button>
    </>
  );

  const firstUpdate = useRef(true); // Captures first render
  // Only runs when importing files don't need user input
  useEffect(() => {
    // Will not run on first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (
      !importLoading &&
      !alreadyExists.length &&
      !passwordsNeeded.length &&
      !sshPasswordNeeded.length &&
      !sshPrivateKeyNeeded.length &&
      !sshPrivateKeyPasswordNeeded.length &&
      !isLoading && // This prevents a double toast for non-related imports
      !importErrored // This prevents a success toast on error
    ) {
      handleClose();
      addSuccessToast(t('Database connected'));
    }
  }, [
    alreadyExists,
    passwordsNeeded,
    importLoading,
    importErrored,
    sshPasswordNeeded,
    sshPrivateKeyNeeded,
    sshPrivateKeyPasswordNeeded,
  ]);

  useEffect(() => {
    if (isOpen) {
      setTabKey(DEFAULT_TAB_KEY);
      setLoading(true);
      getAvailableDbs();
    }
    if (databaseId && isOpen) {
      fetchDB();
    }
  }, [isOpen, databaseId]);

  useEffect(() => {
    if (dbFetched) {
      setDB({
        type: ActionType.Fetched,
        payload: dbFetched,
      });
      // keep a copy of the name separate for display purposes
      // because it shouldn't change when the form is updated
      setDbName(dbFetched.database_name);
    }
  }, [dbFetched]);

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
    }

    if (availableDbs && dbEngine) {
      // set model if passed into props
      setDatabaseModel(dbEngine);
    }
  }, [availableDbs]);

  // This forces the curtain to scroll until the importing filename is in view
  useEffect(() => {
    if (isImportingCurtain) {
      document
        .getElementsByClassName('ant-upload-list-item-name')[0]
        .scrollIntoView();
    }
  }, [isImportingCurtain]);

  useEffect(() => {
    setPasswordFields([...passwordsNeeded]);
  }, [passwordsNeeded]);

  useEffect(() => {
    setSSHTunnelPasswordFields([...sshPasswordNeeded]);
  }, [sshPasswordNeeded]);

  useEffect(() => {
    setSSHTunnelPrivateKeyFields([...sshPrivateKeyNeeded]);
  }, [sshPrivateKeyNeeded]);

  useEffect(() => {
    setSSHTunnelPrivateKeyPasswordFields([...sshPrivateKeyPasswordNeeded]);
  }, [sshPrivateKeyPasswordNeeded]);

  useEffect(() => {
    if (db?.parameters?.ssh !== undefined) {
      setIsUseSSHTunneling(db.parameters.ssh);
    }
  }, [db?.parameters?.ssh]);

  const onDbImport = async (info: UploadChangeParam) => {
    setImportingErrorMessage('');
    setPasswordFields([]);
    setSSHTunnelPasswordFields([]);
    setSSHTunnelPrivateKeyFields([]);
    setSSHTunnelPrivateKeyPasswordFields([]);
    setPasswords({});
    setSSHTunnelPasswords({});
    setSSHTunnelPrivateKeys({});
    setSSHTunnelPrivateKeyPasswords({});
    setIsImportingCurtain(true);
    setFileList([
      {
        ...info.file,
        status: 'done',
      },
    ]);

    if (!(info.file.originFileObj instanceof File)) return;
    const dbId = await importResource(
      info.file.originFileObj,
      passwords,
      sshTunnelPasswords,
      sshTunnelPrivateKeys,
      sshTunnelPrivateKeyPasswords,
      isConfirmedOverwrite,
    );
    if (dbId) onDatabaseAdd?.();
  };

  const passwordNeededField = () => {
    if (
      !passwordFields.length &&
      !sshTunnelPasswordFields.length &&
      !sshTunnelPrivateKeyFields.length &&
      !sshTunnelPrivateKeyPasswordFields.length
    )
      return null;

    const files = [
      ...new Set([
        ...passwordFields,
        ...sshTunnelPasswordFields,
        ...sshTunnelPrivateKeyFields,
        ...sshTunnelPrivateKeyPasswordFields,
      ]),
    ];

    return files.map(database => (
      <>
        <Alert
          closable
          className="connect-form__alert"
          type="info"
          showIcon
          message="Database passwords"
          description={t(
            `The passwords for the databases below are needed in order to import them. Please note that the "Secure Extra" and "Certificate" sections of the database configuration are not present in explore files and should be added manually after the import if they are needed.`,
          )}
        />
        {passwordFields?.indexOf(database) >= 0 && (
          <LabeledErrorBoundInput
            id="password_needed"
            name="password_needed"
            isRequired
            value={passwords[database]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPasswords({ ...passwords, [database]: event.target.value })
            }
            validationMethods={{
              onBlur: () => {},
            }}
            errorMessage={validationErrors?.password_needed}
            label={t('{{name}} password', database.slice(10))}
          />
        )}
        {sshTunnelPasswordFields?.indexOf(database) >= 0 && (
          <LabeledErrorBoundInput
            id="ssh_tunnel_password_needed"
            name="ssh_tunnel_password_needed"
            isRequired
            value={sshTunnelPasswords[database]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSSHTunnelPasswords({
                ...sshTunnelPasswords,
                [database]: event.target.value,
              })
            }
            validationMethods={{
              onBlur: () => {},
            }}
            errorMessage={validationErrors?.ssh_tunnel_password_needed}
            label={t('{{name}} ssh tunnel password', database.slice(10))}
          />
        )}
        {sshTunnelPrivateKeyFields?.indexOf(database) >= 0 && (
          <LabeledErrorBoundInput
            id="ssh_tunnel_private_key_needed"
            name="ssh_tunnel_private_key_needed"
            isRequired
            value={sshTunnelPrivateKeys[database]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSSHTunnelPrivateKeys({
                ...sshTunnelPrivateKeys,
                [database]: event.target.value,
              })
            }
            validationMethods={{
              onBlur: () => {},
            }}
            errorMessage={validationErrors?.ssh_tunnel_private_key_needed}
            label={t('{{name}} ssh tunnel private key', database.slice(10))}
          />
        )}
        {sshTunnelPrivateKeyPasswordFields?.indexOf(database) >= 0 && (
          <LabeledErrorBoundInput
            id="ssh_tunnel_private_key_password_needed"
            name="ssh_tunnel_private_key_password_needed"
            isRequired
            value={sshTunnelPrivateKeyPasswords[database]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSSHTunnelPrivateKeyPasswords({
                ...sshTunnelPrivateKeyPasswords,
                [database]: event.target.value,
              })
            }
            validationMethods={{
              onBlur: () => {},
            }}
            errorMessage={
              validationErrors?.ssh_tunnel_private_key_password_needed
            }
            label={t(
              '{{name}} ssh tunnel private key password',
              database.slice(10),
            )}
          />
        )}
      </>
    ));
  };

  const importingErrorAlert = () => {
    if (!importingErrorMessage) {
      return null;
    }

    return (
      <ErrorAlert
        errorMessage={importingErrorMessage}
        showDbInstallInstructions={passwordFields.length > 0}
      />
    );
  };

  const confirmOverwrite = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = (event.currentTarget?.value as string) ?? '';
    setIsConfirmedOverwrite(targetValue.toUpperCase() === t('OVERWRITE'));
  };

  const confirmOverwriteField = () => {
    if (!alreadyExists.length) return null;

    return (
      <>
        <Alert
          closable
          className="connect-form__alert"
          type="warning"
          showIcon
          message=""
          description={t(
            'You are importing one or more databases that already exist. Overwriting might cause you to lose some of your work. Are you sure you want to overwrite?',
          )}
        />
        <LabeledErrorBoundInput
          id="confirm_overwrite"
          name="confirm_overwrite"
          isRequired
          validationMethods={{
            onBlur: () => {},
          }}
          errorMessage={validationErrors?.confirm_overwrite}
          label={t('Type "{{word}}" to confirm', { word: t('OVERWRITE') })}
          onChange={confirmOverwrite}
        />
      </>
    );
  };

  const tabChange = (key: string) => setTabKey(key);

  const renderStepTwoAlert = () =>
    db?.engine && (
      <Alert
        closable
        className="connect-form__alert"
        type="info"
        showIcon
        message={engineSpecificAlertMapping[db.engine]?.message}
        description={engineSpecificAlertMapping[db.engine]?.description}
      />
    );

  // eslint-disable-next-line consistent-return
  const errorAlert = () => {
    let alertErrors: string[] = [];
    if (!isEmpty(dbErrors)) {
      alertErrors =
        typeof dbErrors === 'object'
          ? Object.values(dbErrors)
          : typeof dbErrors === 'string'
            ? [dbErrors]
            : [];
    } else if (
      !isEmpty(validationErrors) &&
      validationErrors?.error_type === 'GENERIC_DB_ENGINE_ERROR'
    ) {
      alertErrors = [
        validationErrors?.description || validationErrors?.message,
      ];
    }
    if (alertErrors.length) {
      return (
        <ErrorMessageWithStackTrace
          className="connect-form__alert"
          title={t('Database Creation Error')}
          description={t(
            'We are unable to connect to your database. Click "See more" for database-provided information that may help troubleshoot the issue.',
          )}
          subtitle={alertErrors?.[0] || validationErrors?.description}
          copyText={validationErrors?.description}
        />
      );
    }
    return <></>;
  };

  const fetchAndSetDB = () => {
    setLoading(true);
    fetchResource(dbFetched?.id as number).then(r => {
      setItem(LocalStorageKeys.Database, r);
    });
  };

  const renderSSHTunnelForm = () => (
    <SSHTunnelForm
      db={db as DatabaseObject}
      onSSHTunnelParametersInputChange={({ target }) => {
        onChange(ActionType.ParametersSSHTunnelChange, {
          type: target.type,
          name: target.name,
          value: target.value,
        });
        handleClearValidationErrors();
      }}
      onSSHTunnelParametersInputNumberChange={(value, name) => {
        onChange(ActionType.ParametersSSHTunnelChange, {
          type: 'number',
          name,
          value: value ?? undefined,
        });
        handleClearValidationErrors();
      }}
      setSSHTunnelLoginMethod={(method: AuthType) =>
        setDB({
          type: ActionType.SetSSHTunnelLoginMethod,
          payload: { login_method: method },
        })
      }
    />
  );

  const renderButtons = () => (
    <div className="form-header--buttons-block form-header--mb-8">
      <Button
        type="default"
        onClick={() => {
          setLoading(true);
          fetchAndSetDB();
          setIsOpenDatasetCreationCurtain(true);
        }}
        className="form-header--mb-8"
      >
        {t('Create dataset')}
      </Button>
      <Button
        type="default"
        onClick={() => {
          setLoading(true);
          fetchAndSetDB();
          redirectURL(`/sqllab?db=true`);
        }}
      >
        {t('Query data in sql lab')}
      </Button>
    </div>
  );

  const renderDatabaseConnectionForm = () => (
    <>
      <DatabaseConnectionForm
        isEditMode={isEditMode}
        db={db as DatabaseObject}
        isSslForced={false}
        dbModel={dbModel}
        onAddTableCatalog={() => {
          setDB({ type: ActionType.AddTableCatalogSheet });
        }}
        onQueryChange={({ target }: { target: HTMLInputElement }) =>
          onChange(ActionType.QueryChange, {
            name: target.name,
            value: target.value,
          })
        }
        onExtraInputChange={({ target }: { target: HTMLInputElement }) =>
          onChange(ActionType.ExtraInputChange, {
            name: target.name,
            value: target.value,
          })
        }
        onRemoveTableCatalog={(idx: number) => {
          setDB({
            type: ActionType.RemoveTableCatalogSheet,
            payload: { indexToDelete: idx },
          });
        }}
        onParametersChange={handleParametersChange}
        onChange={({ target }: { target: HTMLInputElement }) =>
          onChange(ActionType.TextChange, {
            name: target.name,
            value: target.value,
          })
        }
        getValidation={() => getValidation(db)}
        validationErrors={validationErrors}
        getPlaceholder={getPlaceholder}
        clearValidationErrors={handleClearValidationErrors}
      />
      {isUseSSHTunneling && <div>{renderSSHTunnelForm()}</div>}
    </>
  );

  const renderFinishState = () => {
    if (!isEditNewDb) {
      return (
        <ExtraOptions
          extraExtension={dbConfigExtraExtension}
          db={db as DatabaseObject}
          onCheckboxChange={({ target }) =>
            onChange(ActionType.InputChange, {
              type: target.type,
              name: target.name,
              checked: target.checked,
              value: target.value,
            })
          }
          onInputChange={({ target }: { target: HTMLInputElement }) =>
            onChange(ActionType.InputChange, {
              type: target.type,
              name: target.name,
              checked: target.checked,
              value: target.value,
            })
          }
          onInputNumberChange={(value, name) => {
            onChange(ActionType.InputChange, {
              type: 'number',
              name,
              checked: undefined,
              value: value ?? undefined,
            });
          }}
          onTextChange={({ target }: { target: HTMLTextAreaElement }) =>
            onChange(ActionType.TextChange, {
              name: target.name,
              value: target.value,
            })
          }
          onEditorChange={(payload: { name: string; json: string }) =>
            onChange(ActionType.EditorChange, payload)
          }
          onExtraInputChange={({ target }: { target: HTMLInputElement }) => {
            onChange(ActionType.ExtraInputChange, {
              type: target.type,
              name: target.name,
              checked: target.checked,
              value: target.value,
            });
          }}
          onExtraCheckboxChange={({ target }) => {
            onChange(ActionType.ExtraInputChange, {
              type: target.type,
              name: target.name,
              checked: target.checked,
              value: target.value,
            });
          }}
          onExtraEditorChange={(payload: { name: string; json: string }) =>
            onChange(ActionType.ExtraEditorChange, payload)
          }
        />
      );
    }
    return renderDatabaseConnectionForm();
  };

  if (
    fileList.length > 0 &&
    (alreadyExists.length ||
      passwordFields.length ||
      sshTunnelPasswordFields.length ||
      sshTunnelPrivateKeyFields.length ||
      sshTunnelPrivateKeyPasswordFields.length)
  ) {
    return (
      <Curtain
        childrenClassName="connect-database-content"
        containerClassName="connect-database-container"
        portalId={BI_PORTAL_ID}
        onClose={handleClose}
        isOpen={isOpen}
        title={t('Connect a database')}
        footer={renderFooter()}
        isLoading={isLoading}
      >
        <div className="connect-form">
          <CurtainHeader
            isLoading={isLoading}
            isEditMode={isEditMode}
            isUseSqlAlchemyForm={isUseSqlAlchemyForm}
            hasConnectedDb={hasConnectedDb}
            db={db}
            dbName={dbName}
            dbModel={dbModel}
            fileList={fileList}
          />
          {passwordNeededField()}
          {confirmOverwriteField()}
          {importingErrorAlert()}
        </div>
      </Curtain>
    );
  }
  const footer = isEditMode ? renderEditFooter(db) : renderFooter();
  return useTabLayout ? (
    <Curtain
      childrenClassName="connect-database-content"
      containerClassName="connect-database-container"
      portalId={BI_PORTAL_ID}
      dataTestId="database-curtain"
      onClose={handleClose}
      isOpen={isOpen}
      title={isEditMode ? t('Edit database') : t('Connect a database')}
      footer={footer}
      isLoading={isLoading}
    >
      <div className="connect-form">
        <CurtainHeader
          isLoading={isLoading}
          isEditMode={isEditMode}
          isUseSqlAlchemyForm={isUseSqlAlchemyForm}
          hasConnectedDb={hasConnectedDb}
          db={db}
          dbName={dbName}
          dbModel={dbModel}
        />
        <Tabs
          defaultActiveKey={DEFAULT_TAB_KEY}
          activeKey={tabKey}
          onTabClick={tabChange}
          animated={{ inkBar: true, tabPane: true }}
          className="connect-form__tabs"
        >
          <Tabs.TabPane tab={t('Basic')} key="1">
            {isUseSqlAlchemyForm ? (
              <>
                <SqlAlchemyTab
                  db={db as DatabaseObject}
                  onInputChange={payload => {
                    onChange(ActionType.InputChange, {
                      name: payload.name,
                      value: payload.value,
                    });
                  }}
                  conf={conf}
                  testConnection={testConnection}
                  isTestInProgress={isTestInProgress}
                >
                  <SSHTunnelSwitchComponent
                    dbModel={dbModel}
                    db={db as DatabaseObject}
                    changeMethods={{
                      onParametersChange: handleParametersChange,
                    }}
                    clearValidationErrors={handleClearValidationErrors}
                  />
                  {isUseSSHTunneling && renderSSHTunnelForm()}
                </SqlAlchemyTab>
                {isDynamic(db?.backend || db?.engine) && !isEditMode && (
                  <div className="connect-form__sqla-connect-container">
                    <span
                      role="button"
                      tabIndex={0}
                      className="connect-form__sqla-connect"
                      onClick={() =>
                        setDB({
                          type: ActionType.ConfigMethodChange,
                          payload: {
                            database_name: db?.database_name,
                            configuration_method:
                              ConfigurationMethod.DynamicForm,
                            engine: db?.engine,
                          },
                        })
                      }
                    >
                      {t(
                        'Connect this database using the dynamic form instead',
                      )}
                    </span>
                    <InfoTooltip
                      title={t(
                        'Click this link to switch to an alternate form that exposes only the required fields needed to connect this database.',
                      )}
                    />
                  </div>
                )}
              </>
            ) : (
              renderDatabaseConnectionForm()
            )}
            {!isEditMode && (
              <Alert
                className="connect-form__alert"
                closable
                message={t('Additional fields may be required')}
                showIcon
                description={
                  <>
                    {t(
                      'Select databases require additional fields to be completed in the Advanced tab to successfully connect the database. Learn what requirements your databases has ',
                    )}
                    <a
                      href={DOCUMENTATION_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional-fields-alert-description"
                    >
                      {t('here')}
                    </a>
                    .
                  </>
                }
                type="info"
              />
            )}
            {showDBError && errorAlert()}
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Advanced')} key="2">
            <ExtraOptions
              extraExtension={dbConfigExtraExtension}
              onCheckboxChange={({ target }) =>
                onChange(ActionType.InputChange, {
                  type: target.type,
                  name: target.name,
                  checked: target.checked,
                  value: target.value,
                })
              }
              onExtraCheckboxChange={({ target }) => {
                onChange(ActionType.ExtraInputChange, {
                  type: target.type,
                  name: target.name,
                  checked: target.checked,
                  value: target.value,
                });
              }}
              db={db as DatabaseObject}
              onInputChange={({ target }: { target: HTMLInputElement }) =>
                onChange(ActionType.InputChange, {
                  type: target.type,
                  name: target.name,
                  checked: target.checked,
                  value: target.value,
                })
              }
              onInputNumberChange={(value, name) => {
                onChange(ActionType.InputChange, {
                  type: 'number',
                  name,
                  checked: undefined,
                  value: value ?? undefined,
                });
              }}
              onTextChange={({ target }: { target: HTMLTextAreaElement }) =>
                onChange(ActionType.TextChange, {
                  name: target.name,
                  value: target.value,
                })
              }
              onEditorChange={(payload: { name: string; json: string }) =>
                onChange(ActionType.EditorChange, payload)
              }
              onExtraInputChange={({
                target,
              }: {
                target: HTMLInputElement;
              }) => {
                onChange(ActionType.ExtraInputChange, {
                  type: target.type,
                  name: target.name,
                  checked: target.checked,
                  value: target.value,
                });
              }}
              onExtraEditorChange={(payload: {
                name: string;
                json: string;
              }) => {
                onChange(ActionType.ExtraEditorChange, payload);
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Curtain>
  ) : (
    <Curtain
      childrenClassName="connect-database-content"
      containerClassName="connect-database-container"
      portalId={BI_PORTAL_ID}
      onClose={handleClose}
      isOpen={isOpen}
      title={t('Connect a database')}
      footer={renderFooter()}
      isLoading={isLoading}
    >
      <div className="connect-form">
        {!isLoading && hasConnectedDb ? (
          <>
            <CurtainHeader
              isLoading={isLoading}
              isEditMode={isEditMode}
              isUseSqlAlchemyForm={isUseSqlAlchemyForm}
              hasConnectedDb={hasConnectedDb}
              db={db}
              dbName={dbName}
              dbModel={dbModel}
              isEditNewDb={isEditNewDb}
            />
            {isShowButtons && renderButtons()}
            {renderFinishState()}
          </>
        ) : (
          <>
            {/* Dynamic Form Step 1 */}
            {!isLoading &&
              (!db ? (
                <>
                  <CurtainHeader
                    isLoading={isLoading}
                    isEditMode={isEditMode}
                    isUseSqlAlchemyForm={isUseSqlAlchemyForm}
                    hasConnectedDb={hasConnectedDb}
                    db={db}
                    dbName={dbName}
                    dbModel={dbModel}
                  />
                  {renderPreferredSelector()}
                  {renderAvailableSelector()}
                  <Upload
                    name="databaseFile"
                    id="databaseFile"
                    data-test="database-file-input"
                    accept=".yaml,.json,.yml,.zip"
                    customRequest={() => {}}
                    onChange={onDbImport}
                    onRemove={removeFile}
                    className="connect-form__upload"
                  >
                    <span
                      data-test="import-database-btn"
                      className="connect-form__upload-text"
                    >
                      {t('Import database from file')}
                    </span>
                  </Upload>
                  {importingErrorAlert()}
                </>
              ) : (
                <>
                  <CurtainHeader
                    isLoading={isLoading}
                    isEditMode={isEditMode}
                    isUseSqlAlchemyForm={isUseSqlAlchemyForm}
                    hasConnectedDb={hasConnectedDb}
                    db={db}
                    dbName={dbName}
                    dbModel={dbModel}
                  />
                  {hasAlert && renderStepTwoAlert()}
                  {renderDatabaseConnectionForm()}
                  <div className="connect-form__sqla-connect-container">
                    {dbModel.engine !== Engines.GSheet && (
                      <>
                        <span
                          role="button"
                          tabIndex={0}
                          className="connect-form__sqla-connect"
                          onClick={() =>
                            setDB({
                              type: ActionType.ConfigMethodChange,
                              payload: {
                                engine: db.engine,
                                configuration_method:
                                  ConfigurationMethod.SqlalchemyUri,
                                database_name: db.database_name,
                              },
                            })
                          }
                        >
                          {t(
                            'Connect this database with a SQLAlchemy URI string instead',
                          )}
                        </span>
                        <InfoTooltip
                          title={t(
                            'Click this link to switch to an alternate form that allows you to input the SQLAlchemy URL for this database manually.',
                          )}
                        />
                      </>
                    )}
                  </div>
                  {/* Step 2 */}
                  {showDBError && errorAlert()}
                </>
              ))}
          </>
        )}
      </div>
    </Curtain>
  );
};

export default withDatasetCreationCurtain<DatabaseCurtainProps>(
  withToasts(DatabaseCurtain),
);
