import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Collapse, Input } from 'antd';
import { debounce } from 'lodash';
import { Curtain } from 'src/components/Curtain';
import {
  FormValuesCsvUpload,
  UploadDataCsvCurtainProps,
  DELIMITER,
} from './interfaces';
import { Button } from '../../../../newComponents/Button';
import { FormItem } from '../../../../components/Form';
import { UploadButton } from '../../../../newComponents/UploadButton';
import { AntdForm, Select } from '../../../../components';
import { useUploadToDatabases } from '../hooks/useUploadToDatabases';
import { getTableExistOptions } from '../UploadDataExcelCurtain/utils/getTableExistOptions';
import { getDelimiterOptions } from './utils/getDelimiterOptions';
import '../styles.less';
import { BI_PORTAL_ID } from '../../../../constants';
import { defaultValues } from '../UploadDataExcelCurtain/constants';

const { TextArea } = Input;

export const UploadDataCsvCurtain: FC<UploadDataCsvCurtainProps> = props => {
  const { isOpen, onHide } = props;
  const { t } = useTranslation();
  const [form] = AntdForm.useForm<FormValuesCsvUpload>();
  const delimiter = AntdForm.useWatch('delimiter', form);
  const [delimiterOther, setDelimiterOther] = useState('');
  const {
    getDatabases,
    databases,
    uploadCsv,
    isLoading,
    fileList,
    setFileList,
  } = useUploadToDatabases<FormValuesCsvUpload>({
    form,
    onHide,
  });

  useEffect(() => {
    getDatabases();
  }, [getDatabases]);

  const changeDelimiterOther = debounce(event => {
    setDelimiterOther(event.target.value);
  }, 500);

  return (
    <Curtain
      portalId={BI_PORTAL_ID}
      isOpen={isOpen}
      onClose={onHide}
      title={t('Upload CSV to databases')}
      containerClassName="upload-data-file"
      childrenClassName="upload-data-file__content"
      footer={
        <>
          <Button
            onClick={onHide}
            dataTestId="properties-modal-cancel-button"
            className="curtain--mr-8"
            type="default"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={form.submit}
            dataTestId="properties-modal-submit-button"
            type="primary"
            isLoading={isLoading}
          >
            {t('Save')}
          </Button>
        </>
      }
    >
      <AntdForm
        form={form}
        onFinish={uploadCsv(delimiterOther)}
        layout="vertical"
        initialValues={defaultValues}
      >
        <FormItem
          label={t('Table Name')}
          name="table_name"
          rules={[{ required: true, message: t('Table Name is required') }]}
          className="upload-data-file__title upload-data-file__block--with-description "
        >
          <Input
            data-test="upload-csv-table-name"
            type="text"
            placeholder={t('Table Name')}
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Name of table to be created with CSV file')}
        </p>
        <FormItem
          label={t('CSV Upload')}
          name="file"
          rules={[{ required: true, message: t('Csv file is required') }]}
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <UploadButton
            accept=".csv, text/csv"
            fileList={fileList}
            setFileList={setFileList}
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Select a Csv file to be uploaded to a database.')}
        </p>
        <FormItem
          label={t('Database')}
          name="database"
          className="upload-data-file__title upload-data-file__block"
          rules={[{ required: true, message: t('Database is required') }]}
        >
          <Select
            dataTestId="upload-csv-database"
            placeholder={t('Database')}
            options={databases}
            onChange={value => form.setFieldValue('database', value)}
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Select a database to upload the file to')}
        </p>
        <FormItem
          label={t('Schema')}
          name="schema"
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Input
            data-test="upload-csv-schema"
            type="text"
            placeholder={t('Schema')}
          />
        </FormItem>
        <p className="upload-data-file__description">
          {t('Select a schema if the database supports this')}
        </p>
        <FormItem
          label={t('Delimiter')}
          name="delimiter"
          rules={[{ required: true, message: t('Delimiter is required') }]}
          className="upload-data-file__title upload-data-file__block--with-description"
        >
          <Select
            dataTestId="upload-csv-delimiter"
            placeholder={t('Delimiter')}
            options={getDelimiterOptions(t)}
            onChange={value => form.setFieldValue('delimiter', value)}
          />
        </FormItem>
        {delimiter === DELIMITER.OTHER ? (
          <Input onChange={changeDelimiterOther} />
        ) : null}
        <p className="upload-data-file__description">
          {t('Enter a delimiter for this data')}
        </p>
        <Collapse>
          <Collapse.Panel
            showArrow={false}
            header={t('File Settings')}
            key="settings"
          >
            <FormItem
              label={t('Table Exists')}
              name="already_exists"
              rules={[
                { required: true, message: t('Table Exists is required') },
              ]}
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Select
                dataTestId="upload-csv-already-exists"
                placeholder={t('Table Exists')}
                options={getTableExistOptions(t)}
                onChange={value => form.setFieldValue('already_exists', value)}
              />
              <p className="upload-data-file__description">
                {t(
                  'If table exists do one of the following: Fail (do nothing), Replace (drop and recreate table) or Append (insert data).',
                )}
              </p>
            </FormItem>
            <FormItem
              label={t('Skip Initial Space')}
              name="skip_initial_space"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Checkbox
                data-test="upload-csv-skip-initial-space"
                onChange={event =>
                  form.setFieldValue('skip_initial_space', event.target.checked)
                }
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t('Skip spaces after delimiter')}
            </p>
            <FormItem
              label={t('Skip Blank Lines')}
              name="skip_blank_lines"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Checkbox
                data-test="upload-csv-skip-blank-lines"
                onChange={event =>
                  form.setFieldValue('skip_blank_lines', event.target.checked)
                }
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t(
                'Skip blank lines rather than interpreting them as Not A Number values',
              )}
            </p>
            <FormItem
              label={t('Columns To Be Parsed as Dates')}
              name="column_dates"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-column-dates"
                type="text"
                placeholder={t('Columns To Be Parsed as Dates')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t(
                'A comma separated list of columns that should be parsed as dates',
              )}
            </p>
            <FormItem
              label={t('Day First')}
              name="day_first"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Checkbox
                data-test="upload-csv-day-first"
                onChange={event =>
                  form.setFieldValue('day_first', event.target.checked)
                }
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t('DD/MM format dates, international and European format')}
            </p>
            <FormItem
              label={t('Decimal Character')}
              name="decimal_character"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-decimal-character"
                type="text"
                placeholder={t('Decimal Character')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t('Character to interpret as decimal point')}
            </p>
            <FormItem
              label={t('Null Values')}
              name="null_values"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <TextArea
                data-test="upload-csv-null-values"
                placeholder={t('Null Values')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t(
                'Json list of the values that should be treated as null. Examples: [""], ["None", "N/A"], ["nan", "null"]. Warning: Hive database supports only single value. Use [""] for empty string.',
              )}
            </p>
          </Collapse.Panel>
          <Collapse.Panel showArrow={false} header={t('Columns')} key="columns">
            <FormItem
              label={t('Index Column')}
              name="index_column"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-index-column"
                type="text"
                placeholder={t('Index Column')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t(
                'Column to use as the row labels of the dataframe. Leave empty if no index column',
              )}
            </p>
            <FormItem
              label={t('Dataframe Index')}
              name="dataframe_index"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Checkbox
                data-test="upload-csv-dataframe-index"
                onChange={event =>
                  form.setFieldValue('dataframe_index', event.target.checked)
                }
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t('Write dataframe index as a column.')}
            </p>
            <FormItem
              label={t('Column Label(s)')}
              name="index_label"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-index-label"
                type="text"
                placeholder={t('Column Label(s)')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t(
                'Column label for index column(s). If None is given and Dataframe Index is checked, Index Names are used',
              )}
            </p>
            <FormItem
              label={t('Columns To Read')}
              name="columns_read"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-columns-read"
                type="text"
                placeholder={t('Columns To Read')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t('Json list of the column names that should be read')}
            </p>
            <FormItem
              label={t('Column Data Types')}
              name="column_data_types"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-column-data-types"
                type="text"
                placeholder={t('Column Data Types')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t(
                'A dictionary with column names and their data types if you need to change the defaults. Example: {"user_id":"int"}. Check Python\'s Pandas library for supported data types.',
              )}
            </p>
          </Collapse.Panel>
          <Collapse.Panel showArrow={false} header={t('Rows')} key="Rows">
            <FormItem
              label={t('Header Row')}
              name="header_row"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-header-row"
                type="text"
                placeholder={t('Header Row')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t(
                'Row containing the headers to use as column names (0 is first line of data). Leave empty if there is no header row.',
              )}
            </p>
            <FormItem
              label={t('Rows to Read')}
              name="rows_to_read"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-rows-to-read"
                type="text"
                placeholder={t('Rows to Read')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t('Number of rows of file to read.')}
            </p>
            <FormItem
              label={t('Skip Rows')}
              name="skip_rows"
              className="upload-data-file__title upload-data-file__block--with-description"
            >
              <Input
                data-test="upload-csv-skip-rows"
                type="text"
                placeholder={t('Skip Rows')}
              />
            </FormItem>
            <p className="upload-data-file__description">
              {t('Number of rows to skip at start of file.')}
            </p>
          </Collapse.Panel>
        </Collapse>
      </AntdForm>
    </Curtain>
  );
};
