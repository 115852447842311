import React, { FC, useState } from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItem } from 'src/components/Form/components/FormItem';
import { Form } from 'src/components/Form/components/Form';
import { Input } from 'src/newComponents/Input';
import { InputNumber } from 'src/newComponents/InputNumber';
import { TextArea } from 'src/newComponents/TextArea';
import { InputPassword } from 'src/newComponents/InputPassword';
import { AuthType } from '../../interfaces';
import { SSHTunnelFormProps } from './interfaces';
import './styles.less';

export const SSHTunnelForm: FC<SSHTunnelFormProps> = props => {
  const {
    db,
    onSSHTunnelParametersInputChange,
    onSSHTunnelParametersInputNumberChange,
    setSSHTunnelLoginMethod,
  } = props;
  const { t } = useTranslation();
  const [usePassword, setUsePassword] = useState<AuthType>(AuthType.Password);

  return (
    <Form layout="vertical" className="ssh-tunnel-form">
      <FormItem htmlFor="server_address" required label={t('SSH Host')}>
        <Input
          id="server_address"
          name="server_address"
          placeholder={t('e.g. 127.0.0.1')}
          value={db?.ssh_tunnel?.server_address || ''}
          onChange={onSSHTunnelParametersInputChange}
          dataTestId="ssh-tunnel-server_address-input"
        />
      </FormItem>
      <FormItem htmlFor="server_port" required label={t('SSH Port')}>
        <InputNumber
          id="server_port"
          name="server_port"
          placeholder={t('22')}
          value={db?.ssh_tunnel?.server_port}
          onChange={value =>
            onSSHTunnelParametersInputNumberChange(value, 'server_port')
          }
          dataTestId="ssh-tunnel-server_port-input"
        />
      </FormItem>
      <FormItem htmlFor="username" required label={t('Username')}>
        <Input
          id="username"
          name="username"
          placeholder={t('e.g. Analytics')}
          value={db?.ssh_tunnel?.username || ''}
          onChange={onSSHTunnelParametersInputChange}
          dataTestId="ssh-tunnel-username-input"
        />
      </FormItem>
      <FormItem
        className="ssh-tunnel-form__radio-group-container"
        htmlFor="use_password"
        name="use_password"
        initialValue={usePassword}
        required
        label={t('Login with')}
      >
        <Radio.Group
          className="ssh-tunnel-form__radio-group"
          onChange={({ target: { value } }) => {
            setUsePassword(value);
            setSSHTunnelLoginMethod(value);
          }}
        >
          <Radio
            value={AuthType.Password}
            data-test="ssh-tunnel-use_password-radio"
          >
            {t('Password')}
          </Radio>
          <Radio
            value={AuthType.PrivateKey}
            data-test="ssh-tunnel-use_private_key-radio"
          >
            {t('Private Key & Password')}
          </Radio>
        </Radio.Group>
      </FormItem>
      {usePassword === AuthType.Password && (
        <FormItem htmlFor="password" required label={t('SSH Password')}>
          <InputPassword
            id="password"
            name="password"
            placeholder={t('e.g. ********')}
            value={db?.ssh_tunnel?.password || ''}
            onChange={onSSHTunnelParametersInputChange}
            dataTestId="ssh-tunnel-password-input"
          />
        </FormItem>
      )}
      {usePassword === AuthType.PrivateKey && (
        <>
          <FormItem htmlFor="private_key" required label={t('Private Key')}>
            <TextArea
              id="private_key"
              name="private_key"
              placeholder={t('Paste Private Key here')}
              value={db?.ssh_tunnel?.private_key || ''}
              onChange={onSSHTunnelParametersInputChange}
              dataTestId="ssh-tunnel-private_key-input"
              rows={4}
            />
          </FormItem>
          <FormItem
            htmlFor="private_key_password"
            required
            label={t('Private Key Password')}
          >
            <InputPassword
              id="private_key_password"
              name="private_key_password"
              placeholder={t('e.g. ********')}
              value={db?.ssh_tunnel?.private_key_password || ''}
              onChange={onSSHTunnelParametersInputChange}
              dataTestId="ssh-tunnel-private_key_password-input"
            />
          </FormItem>
        </>
      )}
    </Form>
  );
};
